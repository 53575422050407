import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function SystemConfigurationsAPI(filter) {
    return await axiosFn("get", URL.SYSTEM + makeFilterString(filter))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function GetSystemConfigurationKey(key) {
    return await axiosFn("get", `${URL.SYSTEM}/${key}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function GetDocumentsAPI(filter) {
    return await axiosFn("get", URL.DOCUMENTS + makeFilterString(filter))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
