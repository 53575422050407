import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function ChangePasswordAPI(data, action) {
  const type = localStorage.getItem("ActiveUserType");
  return await axiosFn(
    "put",
    URL.AUTH + URL.PASSWORDS + type + URL.CHANGE_PASSWORD,
    data
  )
    .then((res) => {
      const result = res.data.data;
      action && action();
      return {
        res: result,
        err: null,
      };
    })
    .catch(handleError);
}
