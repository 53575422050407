import { useState, useEffect } from "react"
import { Checkbox, Divider, FormControlLabel, FormGroup, FormControl, Box } from '@mui/material'
import { withTranslation } from "react-i18next"

function CheckBoxMolecule({ items, handleCheckBoxChange, t, hideAllOpt }) {

    const [checked, setChecked] = useState([])
    const [ids, setIds] = useState([])

    const handleChangeOne = (event, i) => {
        let array = [...checked]
        array[i] = event.target.checked
        setChecked(array)
    }

    const handleChangeAll = (e) => {
        let array = checked.map((item) => {
            item = e.target.checked
            return item
        })
        setChecked(array)
    }

    useEffect(() => {
        const array = []
        const allIds = []
        items.map((item) => {
            array.push(false)
            allIds.push(item?.id)
        })
        setChecked(array)
        setIds(allIds)
    }, [])

    return (
        <FormControl sx={{ width: '100%' }}>
            <FormGroup>
                {
                    hideAllOpt != true &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                key='All'
                                value={ids}
                                checked={checked.every(item => item === true)}
                                indeterminate={!checked.every(item => item === true) && !checked.every(item => item === false)}
                                onChange={e => {
                                    handleChangeAll(e)
                                    handleCheckBoxChange(e, 'all')
                                }}
                            />
                        }
                        label={t("Booking.all")}
                    />
                }
                <Divider />
                {items?.map((item, i) => {
                    return <Box key={i} >
                        {/* {console.log(!!checked[i], checked[i])} */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={item?.name}
                                    // checked={!!checked[i]}
                                    checked={item.checked ? item.checked : !!checked[i]}
                                    value={item?.id}
                                    name={item?.name}
                                    onChange={(event) => {
                                        handleChangeOne(event, i)
                                        handleCheckBoxChange(event)
                                    }}
                                />
                            }
                            label={item.name}
                        />
                        <Divider />
                    </Box>
                })}
            </FormGroup>
        </FormControl>)
}

export default withTranslation()(CheckBoxMolecule)