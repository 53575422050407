import { Dialog, Grid, Typography } from "@mui/material"
import { icons } from "../../../../Assets/AssetHelper"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import { withTranslation } from "react-i18next"

const AccountDeletedModal = ({ t, open, handleClose }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "8px",
                    height: 'auto',
                    width: '620px',
                    padding: '24px'
                }
            }}
        >
            <Grid container px={8} py={6} justifyContent='center'>
                <Grid container item xs={12} justifyContent='center' mb={2}>
                    <img src={icons.AccountDeleted} alt='account deleted' />
                </Grid>
                <Grid container item xs={12} justifyContent='center' mb={2}>
                    <Typography sx={{
                        fontSize: 'var(--subtitle-font)',
                        fontWeight: 'bold',
                        color: 'var(--font-color)'
                    }}
                    >
                        {t("Profile.account_deleted")}
                    </Typography>
                </Grid>
                <Grid item xs={12}  mb={4}>
                    <Typography sx={{
                        fontSize: 'var(--large-font)',
                        color: 'var(--font-color)',
                        textAlign:'center'
                    }}>
                        {t("Profile.message")}
                    </Typography>
                </Grid>
                <PrimaryButton
                    text={t("Signup.done")}
                    classBtn="orange-btn"
                    click={handleClose}
                />
            </Grid>
        </Dialog>
    )
}

export default withTranslation()(AccountDeletedModal)