import { useEffect } from "react";
import { useState } from "react";
import BookingStatus from "../../../Components/Organisms/Reservation/BookingStatus";

const BookingTemplate = ({ t, searchParams }) => {
    const [status, setStatus] = useState(null)

    useEffect(() => {
        setStatus(searchParams.get("status") === "success")
    }, []);
    
    return (
        <BookingStatus
            t={t}
            status={status}
        />
    );
}

export default BookingTemplate;