import { TextField } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./DatePicker.css";
import dayjs from "dayjs";

export default function DatePickerStatic({
  value,
  setValue,
  renderDay,
  openTo,
  disablePast,
  disableFuture,
  views,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  maxDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={value}
        onChange={(newValue) => setValue(newValue)}
        renderInput={(params) => <TextField {...params} />}
        format="DD/MM/YYYY"
        renderDay={renderDay}
        disableHighlightToday
        disablePast={disablePast}
        disableFuture={disableFuture}
        openTo={openTo}
        views={views}
        shouldDisableDate={shouldDisableDate}
        shouldDisableMonth={shouldDisableMonth}
        shouldDisableYear={shouldDisableYear}        
        maxDate={maxDate}        
      />
    </LocalizationProvider>
  );
}
