import { Dialog, Grid, Typography } from "@mui/material";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";

const ConfirmationModal = ({
  t,
  open,
  handleClose,
  type,
  action,
  item,
  cancellationPolicy,
}) => {
  const email = localStorage.getItem("ActiveEmail");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          height: "auto",
          width: { xs: "100%", md: "390px" },
          padding: "24px",
        },
      }}
    >
      <Grid container gap={2}>
        <Grid container item xs={12} justifyContent="center" mb={1}>
          <Typography
            sx={{
              fontSize: "var(--subtitle-font)",
              fontWeight: "bold",
              color: "var(--font-color)",
              textAlign: "center",
            }}
          >
            {type === "deleteAccount"
              ? t("Profile.delete_account")
              : type === "deleteCertificate"
              ? t("Profile.delete_certificate")
              : type === "logout-all"
              ? t("Profile.logout_all")
              : type === "deleteCart"
              ? t("Details.cancel")
              : type === "deleteOrder"
              ? t("Booking.cancel")
              : t("Profile.logout")}
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Typography
            sx={{
              fontSize: "var(--text-font)",
              color: "#7e8085",
              marginBottom: "14px",
              width: "300px",
              textAlign: "center",
            }}
          >
            {type === "deleteAccount"
              ? t("Profile.sure")
              : type === "deleteCertificate"
              ? t("Profile.sure_certificate")
              : type === "logout-all"
              ? t("Profile.sure_all")
              : type === "deleteCart"
              ? t("Details.sure")
              : type === "deleteOrder"
              ? cancellationPolicy
              : `${t("Profile.logout_from")} ${email}`}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={6} sx={{xs:{paddingInlineEnd: '0px'}, md:{ paddingInlineEnd: "10px" }}}>
          <PrimaryButton
            text={
              type === "deleteCart" || type === "deleteOrder"
                ? t("Details.back")
                : t("Profile.cancel")
            }
            classBtn="delete-modal-btn"
            style={{
              color: "#181918",
              border: "solid 1px #181918",
              width: "100%",
            }}
            click={handleClose}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <PrimaryButton
            text={
              type === "deleteAccount"
                ? t("Profile.delete_account")
                : type === "deleteCertificate"
                ? t("Profile.delete_certificate")
                : type === "deleteCart" || type === "deleteOrder"
                ? t("Details.cancel")
                : t("Profile.logout")
            }
            classBtn="delete-modal-btn"
            style={{
              color: "#ff5252",
              border: "solid 1px #ff5252",
              width: "100%",
            }}
            click={() => {
              action(item);
              handleClose();
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmationModal;
