import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import CenterDetailsTemplate from "../../../Templates/Main/DiveCenters/CenterDetails.Template.jsx"
import { DiveCenterDetailsAPI } from "../../../Contexts/APIs/Dive-Centers/CenterDetails"
import { ListDiveActivitiesAPI } from "../../../Contexts/APIs/Dive-Activities/ListDiveActivities"
import { ReviewsListAPI } from "../../../Contexts/APIs/Reviews/ReviewsListing"
import { toast } from "react-toastify"
import { BranchDetailsAPI } from "../../../Contexts/APIs/Branches/branches.js"

const CenterDetailsPage = ({ t, getFavorites }) => {

    const { id } = useParams()
    const [centerDetails, setCenterDetails] = useState({})
    const [diveTrips, setDiveTrips] = useState([])
    const [courses, setCourses] = useState([])
    const [liveaoards, setLiveaoards] = useState([])
    const [reviews, setReviews] = useState({})

    const getCenterData = async () => {
        const { res, err } = await BranchDetailsAPI(id)
        if (res) setCenterDetails(res?.data?.branch)
        if (err) toast.error(err)
    }

    const diveActivities = async (filter) => {
        const { res, err } = await ListDiveActivitiesAPI(filter)
        if (res) return res?.data?.activities
        if (err) toast.error(err)
    }

    const getDiveTrips = async () => {
        const res = await diveActivities({
            'activity_type': 'dive_trip',
            'branch_id': id            
        })
        setDiveTrips(res)
    }

    const getCourses = async () => {
        const res = await diveActivities({
            'activity_type': 'dive_course',
            'branch_id': id            
        })
        setCourses(res)
    }

    const getLiveaboards = async () => {
        const res = await diveActivities({
            'activity_type': 'live_aboard',
            'branch_id': id            
        })
        setLiveaoards(res)
    }

    const getReviews = async () => {
        const { res, err } = await ReviewsListAPI({},{
            "review": {
                "reviewee_id": id,
                "reviewee_type": "Branch",
                "reviewer_type":'Customer'
            }
        })
        if(res) setReviews(res?.data)
        if(err) toast.error(err)
    }

    useEffect(() => {
        getCenterData()
        getDiveTrips()
        getCourses()
        getLiveaboards()
        getReviews()
    }, []);

    return (
        <CenterDetailsTemplate
            t={t}
            centerDetails={centerDetails}
            diveTrips={diveTrips}
            courses={courses}
            reviews={reviews}
            getFavorites={getFavorites}
            liveaoards={liveaoards}
        />
    )
}

export default CenterDetailsPage