import { axiosFn, handleError } from "../index";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function ComplainsAPI(id, data) {
  return await axiosFn("post", URL.COMPLAINS + "/" + id, data)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
      };
    })
    .catch(handleError);
}
