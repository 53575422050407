import { Grid } from "@mui/material"

import { images } from '../../Assets/AssetHelper.js'
import LoginStep1 from "../../Components/Organisms/Auth/Login/LoginStep1.jsx"
import LoginStep2 from "../../Components/Organisms/Auth/Login/LoginStep2.jsx"
import { withTranslation } from "react-i18next"

const LoginTemplate = ({ t, LoginApi, step, setStep, setIsAuth, resAccounts }) => {

    return (
        <Grid container>
            <Grid item md={6} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                <img
                    src={images.LoginBg}
                    alt='Login'
                    width='100%'
                    // height='100%'
                    height='100vh'
                    style={{
                        minHeight: '100vh',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
            </Grid>
            {step === 1 ?
                <LoginStep1
                    t={t}
                    LoginApi={LoginApi}
                    setStep={setStep}
                    setIsAuth={setIsAuth}
                /> :
                <LoginStep2
                    t={t}
                    setIsAuth={setIsAuth}
                    setStep={setStep}
                    resAccounts={resAccounts}
                />
            }
        </Grid>
    )
}

export default withTranslation()(LoginTemplate)