import { Grid, Typography } from "@mui/material"
import { useNavigate, useSearchParams } from 'react-router-dom'

import { images, icons } from '../../Assets/AssetHelper.js'
import { EmailInput } from "../../Components/Organisms/Auth/ForgetPassword/EmailInput"
import { NewPasswordInput } from "../../Components/Organisms/Auth/ForgetPassword/NewPasswordInput"
import VerificationCode from "../../Components/Organisms/Auth/Signup/VerificationCode"
import { withTranslation } from "react-i18next"

const ForgetPasswordTemplate = ({ t, checkEmailExists, resetPassword, verifyOtp, verify, setOtp, countdown, setCountdown, SendOtp, setVerify }) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();    
    return (
        <Grid container>
            <Grid item md={6} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                <img
                    src={images.LoginBg}
                    alt='Login'
                    width='100%'
                    // height='100%'
                    height='100vh'
                    style={{
                        minHeight: '100vh',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container item xs={12} pb={'57px'} pt={'88px'} sx={{ paddingInlineStart: '50px' }}>
                    {(searchParams.get("email") === null || !verify) &&
                        < img
                            src={icons.BackArrow}
                            alt='BackArrow'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate(-1)
                                if (localStorage.getItem("end_date") != null) localStorage.removeItem("end_date");
                            }}
                        />
                    }
                </Grid>
                <Grid container alignContent='center'>
                    <Grid container item xs={12} justifyContent='center' mb={'48px'} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                        <img
                            src={icons.LogoLogin}
                            alt='Logo'
                        />
                    </Grid>
                    <Grid container item xs={12} justifyContent='center' mb={'39px'} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                        <img
                            src={searchParams.get("email") && !verify ? images.VerificationCode : images.ForgetPassword}
                            alt='forget password'
                        />
                    </Grid>
                    <Grid container item xs={12} justifyContent='center' mb={6} sx={{ display: { xs: 'inherit', md: 'none' } }}>
                        <img
                            src={images.Fish}
                            alt='forget password'
                        />
                    </Grid>
                    <Grid container item xs={12} justifyContent='center' mb={4}>
                        <Typography sx={{
                            fontSize: '28px',
                            fontWeight: 'bold'
                        }}>
                            { searchParams.get("email") && !verify ? t("Signup.code") : t("ForgetPassword.forget") }
                        </Typography>
                    </Grid>
                    {searchParams.get("email") === null ?
                        <EmailInput
                            t={t}
                            setSearchParams={setSearchParams}
                            checkEmailExists={checkEmailExists}
                            SendOtp={SendOtp}
                        /> :
                        !verify ?
                            <VerificationCode
                                t={t}
                                setOtp={setOtp}
                                verifyOtp={verifyOtp}
                                countdown={countdown}
                                setCountdown={setCountdown}
                                SendOtp={SendOtp}
                                searchParams={searchParams}
                            /> :
                            <NewPasswordInput
                                t={t}
                                searchParams={searchParams}
                                resetPassword={resetPassword} />
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

export default ForgetPasswordTemplate