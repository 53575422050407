import { Grid, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import PassowordCriteria from "../../../Atoms/SharedComponents/PassowordCriteria";

function UserInfo({ types, searchParamsType, t, SignupApi }) {
  const invite_code = localStorage.getItem("invitationCode") || "";
  const fcm_token = localStorage.getItem("fcm_token");
  const userAgent = window.navigator.userAgent;

  const customerInitials = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const diveClubInitials = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const customerValidation = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    lastName: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.invalid_email"))
      .required(t("validation.email")),
    password: Yup.string()
      .trim()
      .required(t("validation.password"))
      .min(8, t("validation.password_digits")) // Minimum 8 characters
      .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
      .matches(/[0-9]/, t("validation.password_numbers")),
    confirmPassword: Yup.string().test(
      t("validation.match"),
      t("validation.must_match"),
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const diveClubValidation = Yup.object().shape({
    name: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.invalid_email"))
      .required(t("validation.email")),
    password: Yup.string()
      .required(t("validation.password"))
      .min(8, t("validation.password_short")),
    confirmPassword: Yup.string().test(
      t("validation.match"),
      t("validation.must_match"),
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  return (
    <Grid item xs={12}>
      <Grid container sx={{ width: { xs: "85%", md: "400px" } }} mx="auto">
        <Grid item xs={8}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              color: "#848383",
            }}
          >
            {t("Signup.create_as")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            {types[searchParamsType.index]?.text}
          </Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="end">
          <img src={types[searchParamsType.index]?.image} alt="User Type" />
        </Grid>
      </Grid>
      <Grid container mb={2} justifyContent="center">
        <Grid item md={2.5}></Grid>
        <Grid item xs={12} md={7}>
          <Formik
            initialValues={
              searchParamsType.userType === "customer"
                ? customerInitials
                : diveClubInitials
            }
            validationSchema={
              searchParamsType.userType === "customer"
                ? customerValidation
                : diveClubValidation
            }
            onSubmit={async (values) => {
              localStorage.setItem("newAccountEmail", values.email);
              const data =
                searchParamsType.userType === "customer"
                  ? {
                      user: {
                        firstname: values.firstName,
                        lastname: values.lastName,
                        email: values.email,
                        password: values.password,
                        password_confirmation: values.confirmPassword,
                        invitation_code: invite_code,
                      },
                      device: {
                        uuid: "dummy",
                        device_type: "web",
                        fcm_token: fcm_token ? fcm_token : userAgent,
                      },
                    }
                  : {
                      user: {
                        name: values.name,
                        email: values.email,
                        password: values.password,
                        password_confirmation: values.confirmPassword,
                        invitation_code: invite_code,
                      },
                      device: {
                        uuid: "dummy",
                        device_type: "web",
                        fcm_token: fcm_token ? fcm_token : userAgent,
                      },
                    };
              await SignupApi(searchParamsType.userType, data);
            }}
          >
            {({ isSubmitting, submitForm }) => (
              <Form style={{ width: "100%" }}>
                {searchParamsType.userType === "customer" ? (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingInlineEnd: { xs: "0px", md: "4px" } }}
                    >
                      <Field
                        as={TextFieldInput}
                        name="firstName"
                        placeholder={t("Signup.first_name")}
                        label={t("Signup.first_name")}
                        className="login-input"
                        gridClass="grid-input"
                        sx={{
                          width: { xs: "90%", md: "100%" },
                          marginX: "auto",
                        }}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        style={{ color: "#d32f2f" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingInlineStart: { xs: "0px", md: "4px" } }}
                    >
                      <Field
                        as={TextFieldInput}
                        name="lastName"
                        placeholder={t("Signup.last_name")}
                        label={t("Signup.last_name")}
                        className="login-input"
                        gridClass="grid-input"
                        sx={{
                          width: { xs: "90%", md: "100%" },
                          marginX: "auto",
                        }}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        style={{ color: "#d32f2f" }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Field
                      as={TextFieldInput}
                      name="name"
                      placeholder={t("Signup.name")}
                      label={t("Signup.name")}
                      className="login-input"
                      gridClass="grid-input"
                      sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      style={{ color: "#d32f2f" }}
                    />
                  </>
                )}
                <Field
                  as={TextFieldInput}
                  type="email"
                  name="email"
                  placeholder={t("Login.email")}
                  label={t("Login.email")}
                  className="login-input"
                  gridClass="grid-input"
                  sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
                <Field
                  as={TextFieldInput}
                  type="password"
                  name="password"
                  placeholder={t("Login.password")}
                  label={t("Login.password")}
                  className="login-input"
                  gridClass="grid-input"
                  sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
                />
                <PassowordCriteria />
                <ErrorMessage
                  name="password"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
                <Field
                  as={TextFieldInput}
                  type="password"
                  name="confirmPassword"
                  placeholder={t("Signup.confirm_password")}
                  label={t("Signup.confirm_password")}
                  className="login-input"
                  gridClass="grid-input"
                  clickEnter={submitForm}
                  sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
                <Grid container justifyContent="center" mt={{ xs: 15, md: 4 }}>
                  <PrimaryButton
                    type="submit"
                    disabled={isSubmitting}
                    text={t("Signup.next")}
                    classBtn="orange"
                    // style={{ marginTop: '32px' }}
                    click={submitForm}
                    sx={{
                      fontSize: {
                        xs: "20px",
                        md: "24px",
                      },
                      borderRadius: {
                        xs: "100px",
                        md: "10px",
                      },
                      height: {
                        xs: "60px",
                        md: "72px",
                      },
                      width: {
                        xs: "90%",
                        md: "100%",
                      },
                    }}
                  />
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item md={2.5}></Grid>
      </Grid>
    </Grid>
  );
}

export default UserInfo;
