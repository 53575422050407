import { useEffect, useState } from "react";
import {
  Grid,
  Switch,
  styled,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import { icons } from "../../../../Assets/AssetHelper";
// import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import SelectBox from "../../../Atoms/Select/Select";

const Settings = ({
  t,
  getCurrencies,
  language,
  onLanguageHandle,
  currencies,
  languages,
  updateProfile,
  pushStatus,
  profileData,
}) => {
  const [index, setIndex] = useState();
  const settingsOptions = [
    // {
      //     icon: icons.Language,
      //     icon_orange: icons.LanguageOrange,
      //     text: t("Profile.language")
    // },
    {
      icon: icons.Currency,
      icon_orange: icons.CurrencyOrange,
      text: t("Profile.currency"),
      handelClick: () => getCurrencies(),
    },
  ];
  const [value, setValue] = useState();
  const [checked, setChecked] = useState();

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    localStorage.setItem("notificationsEnabled", event.target.checked);
    updateProfile({
      user: {
        enable_push: event.target.checked,
      },
    });
  };

  const handleChange = (event) => {
    const res = updateProfile({
      user: {
        currency_id: parseInt(event.target.value),
      },
    });
    if (res) setValue(event.target.value);
  };
  useEffect(() => {
    localStorage.setItem("notificationsEnabled", pushStatus);
    setChecked(pushStatus);
    setValue(profileData?.currency?.id);
  }, [pushStatus]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      checked={checked}
      onChange={handleSwitchChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          // backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));


  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <Grid item xs={12} mb={3}>
          <Typography
            sx={{
              fontSize: "var(--xlarge-font)",
              fontWeight: "800",
              color: "var(--font-color)",
            }}
          >
            {t("Profile.settings")}
          </Typography>
        </Grid>
        <Grid container item xs={12} mb={5}>
          <Grid item xs={1.5}>
            <img src={icons.Notification} alt="notification" />
          </Grid>
          <Grid item xs={8.5}>
            <Typography
              display="inline"
              sx={{
                fontSize: "var(--normal-font)",
                fontWeight: "600",
                color: "var(--font-color)",
              }}
            >
              {t("Profile.notification")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IOSSwitch />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {settingsOptions.map((item, i) => {
            return (
              <Grid
                container
                mb={5}
                key={i}
                onClick={() => {
                  item?.handelClick && item?.handelClick();
                  setIndex(i);
                }}
                sx={{ cursor: "pointer" }}
              >
                <Grid item xs={2}>
                  <img
                    src={index === i ? item.icon_orange : item.icon}
                    alt={item.text}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    sx={{
                      color:
                        index === i ? "var(--star-color)" : "var(--font-color)",
                      fontSize: "var(--normal-font)",
                      fontWeight: "600",
                    }}
                  >
                    {item.text}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <KeyboardArrowRightIcon
                    sx={{ color: index === i && "var(--primary-color)" }}
                  />
                </Grid>
              </Grid>
            );
          })}
          {/* <PrimaryButton
                        text={t("Profile.language")}
                        startIcon={<LanguageIcon/>}
                        endIcon={<KeyboardArrowRightIcon/>}
                    /> */}
        </Grid>
      </Grid>
      <Grid container item xs={0} md={0.5} justifyContent="center">
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={12} md={6.5}>
        {index === 1 /* TODO:to be changed to 0 */ ? (
          <>
            <Typography
              sx={{
                color: "var(--font-color)",
                fontSize: "var(--large-font)",
                fontWeight: "bold",
              }}
            >
              {t("Profile.language")}
            </Typography>
            <SelectBox
              name="language"
              items={languages}
              className="login-input"
              style={{ marginTop: "24px" }}
              IconComponent={(props) => <ExpandMoreOutlinedIcon {...props} />}
              value={language}
              onchange={(e) => onLanguageHandle(e.target.value)}
            />
          </>
        ) : index === 0 /* TODO:to be changed to 1 */ ? (
          <>
            <Typography
              sx={{
                color: "var(--font-color)",
                fontSize: "var(--large-font)",
                fontWeight: "bold",
                marginBottom: "22px",
              }}
            >
              {t("Profile.currency")}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={value}
              onChange={handleChange}
              name="radio-buttons-group"
            >
              {currencies.map((currency) => {
                return (
                  <FormControlLabel
                    key={currency.id}
                    value={currency.id}
                    control={<Radio />}
                    label={`${currency.name} ( ${currency.abbr} )`}
                  />
                );
              })}
            </RadioGroup>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default Settings;
