import { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./TextFieldInput.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
function TextFieldInput({
  type = "text",
  placeholder = "",
  StartAdornment = null,
  EndAdornment = null,
  multiline = false,
  rows,
  className,
  onChange,
  error,
  value,
  labelStyle,
  label,
  clickEnter,
  InputProps,
  disabled,
  justifyContent,
  gridClass,
  sx,
  ...props
}) {
  const [showPassword, setshowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const textfield = {
    position: "absolute",
    top: "-12px",
    fontSize: "13px",
    margin: "0 20px",
    background: "#fff",
    zIndex: "99",
    padding: "0 10px",
    color: "black",
  };
  return (
    <Grid
      container
      sx={{ position: "relative" }}
      justifyContent={justifyContent}
      className={gridClass}
    >
      {/* {value ? label && <span style={textfield}>{label}</span> : null} */}

      <TextField
        disabled={disabled}
        placeholder={placeholder}
        fullWidth
        multiline={multiline}
        rows={rows}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        className={className}
        sx={sx}
        error={error ? true : false}
        variant="outlined"
        // label={value ? "" : label}
        label={!value ? "" : label}
        // value={value}
        value={value ? value : ""}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") clickEnter && clickEnter(e);
        }}
        // onClick={clickEnter}
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    {StartAdornment}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {type === "password" ? (
                      // <></>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility className="password-icon" />
                        ) : (
                          <VisibilityOff className="password-icon" />
                        )}
                      </IconButton>
                    ) : (
                      EndAdornment
                    )}
                  </InputAdornment>
                ),
              }
        }
        {...props}
      />
    </Grid>
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  StartAdornment: PropTypes.element,
  EndAdornment: PropTypes.element,
  className: PropTypes.string,
};
export default TextFieldInput;
