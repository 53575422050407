import { Grid, Typography, Rating, Avatar } from "@mui/material"
import { PanoramaFishEye, Circle } from '@mui/icons-material'
import { useNavigate } from "react-router-dom"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";

const RatingResponsive = (props) => {
    const { t, diveCenter } = props;
    const navigate = useNavigate()

    return (
        <Grid container className='mb-20px pr-pl-20px'>
            <Grid item xs={12}>
                <Typography style={{ fontSize: "18px", color: "var(--font-color)", fontWeight: "800" }}>
                    {t("Details.center")}
                </Typography>
            </Grid>
            <Grid container item xs={12} mt={2}>
                <Grid item xs={3}>
                    <Avatar src={diveCenter?.logo} alt="logo" sx={{ width: '60px', height: '60px' }} variant="square" />
                </Grid>
                <Grid container item xs={5} alignItems='center' >
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: "14px", color: "var(--font-color)", fontWeight: "bold" }}>
                            {diveCenter?.name}
                        </Typography>
                    </Grid>
                    <Rating
                        readOnly
                        value={parseFloat(diveCenter?.avg_rate)}
                        sx={{ color: '#3ed181' }}
                        size='small'
                        precision={0.5}
                        icon={<Circle fontSize="inherit" />}
                        emptyIcon={<PanoramaFishEye fontSize="inherit" sx={{ color: '#3ed181' }} />}
                    />
                    <Typography component="span"
                        style={{ color: "#3ed181", lineHeight: "1", padding: "0 10px" }}>
                        {diveCenter?.reviews_count}
                    </Typography>
                </Grid>
                <Grid container item xs={4} alignItems='center'>
                    <Typography sx={{ fontSize: '12px', color: '#b2b2b2', fontweight: '500' }}>
                        {t("Details.view")}
                    </Typography>
                    <PrimaryButton
                        btnType="icon"
                        startIcon={<img src={icons.GoArrow} alt='' />}
                    // click={() => navigate(`/dive-centers/${diveCenter?.id}`)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RatingResponsive