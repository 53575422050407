import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SendOtpAPI } from "../../../Contexts/APIs/Auth/Password/SendOtp";
import ChangeAccountTypeTemplate from "../../../Templates/Auth/SuperAdmin/ChangeAccountType.Template";

const ChooseAccountTypePage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const SendOtp = async (email, type) => {
    const { res, err } = await SendOtpAPI(
      {
        user: {
          email: email,
        },
      },
      type
    );
    if (res) toast.success("The code has been sent to your email");
    if (err) toast.error(err);
  };

  const handleListItemClick = (e, index, data) => {
    setSelectedIndex(index);

    navigate(`/choose-account?type=${data?.value}`);
  };
  return (
    <ChangeAccountTypeTemplate
      selectedIndex={selectedIndex}
      handleListItemClick={handleListItemClick}
    />
  );
};

export default ChooseAccountTypePage;
