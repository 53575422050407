import { useEffect } from "react";

import CheckoutTemplate from "../../../Templates/Main/Reservation/Checkout.Template.jsx";
import { CheckoutAPI } from "../../../Contexts/APIs/Carts/Checkout";
// import BookingStatus from "../../../Components/Organisms/Reservation/BookingStatus.jsx"
import { isEmptyObject } from "../../../Contexts/APIs/Helper/Helper.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

const CheckoutPage = ({
  t,
  cart,
  getCart,
  redeem,

  deleteCart,
}) => {
  const navigate = useNavigate();

  const checkout = async (data) => {
    const { res, err } = await CheckoutAPI(data);
    if (res) {
      if (!res?.data?.payment_session?.payment_url) {
        navigate("/orders");
      } else {
        window.location.replace(res?.data?.payment_session?.payment_url);
      }
    }
    if (err) toast.error(err);
  };

  useEffect(() => {
    getCart();
  }, []);

  return (
    <CheckoutTemplate
      t={t}
      cart={cart}
      getCart={getCart}
      checkout={checkout}
      redeem={redeem}
      deleteCart={deleteCart}
    />
  );
};

export default withTranslation()(CheckoutPage);
