import { useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

import SignupTemplate from "../../Templates/Auth/Signup.Template.jsx"
import { SignUpAPI } from "../../Contexts/APIs/Auth/Signup"
import { VerifyAccountAPI } from "../../Contexts/APIs/Auth/VerifyAccount"
import { ReSendVerificationAPI } from "../../Contexts/APIs/Auth/ReSendVerification"

const SignupPage = ({ t }) => {

    const navigate = useNavigate()
    const [otp, setOtp] = useState(null)
    const [verify, setVerify] = useState(false)
    const [countdown, setCountdown] = useState(true)

    const SignupApi = async (userType, data) => {
        const { res, err } = await SignUpAPI(userType, data)
        if (res) {
            setVerify(true)
            localStorage.setItem('ActiveToken', res.extra.access_token)
        }
        if (err) toast.error(err)
    }

    const verifyOtp = async () => {
        const { res, err } = await VerifyAccountAPI({ "verification_code": otp })
        if (res) {
            let array = []
            const account = {
                token: res.extra.access_token,
                userId: res.user.id,
                avatar: res.user.avatar,
                user_type: res.user.user_type,
                email: res.user.email,
                firstname: res.user.firstname,
                lastname: res.user.lastname
            }
            array.push(account)
            if (localStorage.getItem('Accounts') === null) {
                localStorage.setItem('Accounts', JSON.stringify(array))
            } else {
                const accounts = JSON.parse(localStorage.getItem('Accounts'))
                accounts.push(...array)
                localStorage.setItem('Accounts', JSON.stringify(accounts))
            }
            localStorage.setItem('ActiveToken', res.extra.access_token)
            localStorage.setItem("ActiveUserId", res?.user?.id)
            localStorage.setItem("ActiveAvatar", res?.user?.avatar)
            localStorage.setItem("ActiveUserType", res?.user?.user_type)
            localStorage.setItem("ActiveEmail", res?.user?.email)
            if (!localStorage.getItem("fcm_token")) localStorage.setItem("fcm_token", window.navigator.userAgent)
            // localStorage.setItem('ISVERIFIED', false)
            // localStorage.setItem("FirebaseTOKEN", result?.data?.extra?.firebase_token)
            // let exp = result?.expires_in + result?.created_at
            // localStorage.setItem("EXP", exp)
            // localStorage.setItem("name", result?.data?.user?.name)
            // localStorage.setItem("readPopup", true)
            localStorage.setItem('ISVERIFIED', true)
            navigate('../', { replace: true })
            localStorage.removeItem("newAccountEmail");
            window.location.reload()
        }
        if (err) toast.error(err)
    }

    const resendVerification = async () => {
        const { res, err } = await ReSendVerificationAPI()
        if (res) toast.success("The new code has been sent to your email")
        if (err) toast.error(err)
    }

    return (
        <SignupTemplate
            t={t}
            SignupApi={SignupApi}
            setOtp={setOtp}
            verify={verify}
            verifyOtp={verifyOtp}
            countdown={countdown}
            setCountdown={setCountdown}
            resendVerification={resendVerification}
            setVerify={setVerify}
        />
    )
}

export default SignupPage