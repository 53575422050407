import { Grid, Typography, Rating, Avatar } from "@mui/material"
import { PanoramaFishEye, Circle } from '@mui/icons-material'
import { useNavigate } from "react-router-dom"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";
import "./DetailsPanels.css";

const RatingPanel = (props) => {
    const { diveCenter ,diveDetails} = props;
    const navigate = useNavigate()

    return (
        <Grid container className="mb-20px panels-container">

            <Grid item md={1}>
                <Avatar src={diveCenter?.logo} alt="logo" sx={{ width: '60px', height: '60px' }} />
            </Grid>
            <Grid container item md={8} sx={{ paddingInlineStart: '21px' }}>
                <Grid item md={12}>
                    <Typography style={{ fontSize: "22px", color: "var(--font-color)", fontWeight: "bold" }}>
                        {diveDetails?.branch?.name}
                    </Typography>
                </Grid>
                <Grid container item md={12} alignItems='center'>
                    <Rating
                        readOnly
                        value={parseFloat(diveDetails?.branch?.avg_rate)}
                        sx={{ color: '#3ed181' }}
                        size='small'
                        precision={0.5}
                        icon={<Circle fontSize="inherit" />}
                        emptyIcon={<PanoramaFishEye fontSize="inherit" sx={{ color: '#3ed181' }} />}
                    />
                    <Typography component="span"
                        style={{ color: "#3ed181", lineHeight: "1", padding: "0 10px" }}>
                        {diveDetails?.branch?.reviews_count}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item md={2} alignContent='center'>
                <Typography sx={{ fontSize: '14px', color: '#848383' }}>
                    {props.t("Details.about")}
                </Typography>
                
            </Grid>
            <Grid container item md={1} >
                <PrimaryButton
                    btnType="icon"
                    startIcon={<img src={icons.GoArrow} alt='' />}
                    click={() => navigate(`/dive-centers/${diveDetails?.branch_id}`)}
                />
            </Grid>
        </Grid>
    )
}

export default RatingPanel