import { combineReducers } from "redux";
import modalReducer from "./modules/modals/Reducer";
import { authReducer } from "./modules/Auth/Reducer";
import { tabReducer } from "./modules/Tabs/Reducer";

const rootReducer = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  tab: tabReducer,
});

export default rootReducer;
