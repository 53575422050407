import { Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import CarouselMolecule from "../../Molecules/Carousels/CarouselMolecule";
import { withTranslation } from "react-i18next";

function CarouselOrganism({
  t,
  type,
  items,
  heading,
  description,
  width,
  getFavorites,
  activity_type,
  action,
  navigateParam,
  customCarouselClass,
  imageOnly,
  autoPlay,
  autoPlaySpeed,
  infinite,
  customRightArrow,
  customLeftArrow,
  displaySeeAllBtn = true,
  fontSize = "38px",
  itemsToShow,
  maxHeight,
  enableWidth,
  customStyle,
  enableHeight,
  borderRadius,
  mb,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <Grid container width={width} mx="auto" mb={{ xs: 5, md: mb || 10 }}>
      <Grid item xs={10}>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: fontSize },
            fontWeight: "bold",
            margin: "0 10px",
            color: "#272726",
          }}
        >
          {heading}
        </Typography>
      </Grid>
      {(path === "/" ||
        path.includes("/dive-centers/") ||
        path.includes("/dive-experts/")) && (
        <>
          {description && (
            <Grid item xs={10}>
              <Typography
                sx={{
                  margin: "0 10px",
                  fontSize: { xs: "12px", md: "24px" },
                  fontWeight: "500",
                  color: "#6e7a8a",
                }}
              >
                {description}
              </Typography>
            </Grid>
          )}
          {displaySeeAllBtn && (
            <Grid container item xs={2} justifyContent="end">
              {type !== "image" && (
                <Typography
                  sx={{
                    fontSize: { xs: "12px", md: "24px" },
                    margin: "0 10px",
                    cursor: "pointer",
                  }}
                  color="primary"
                  onClick={() => {
                    activity_type === "dive-centers"
                      ? navigate(`/${activity_type}`)
                      : navigate(`/search/${activity_type}`, navigateParam);
                  }}
                >
                  {t("HomePage.see_all")}
                </Typography>
              )}
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} mt={{ xs: 0, md: 4 }} className={customCarouselClass}>
        <CarouselMolecule
          action={action}
          t={t}
          type={type}
          items={items}
          getFavorites={getFavorites}
          isBranchDetails={true}
          imageOnly={imageOnly}
          autoPlay={autoPlay}
          autoPlaySpeed={autoPlaySpeed}
          infinite={infinite}
          customRightArrow={customRightArrow}
          customLeftArrow={customLeftArrow}
          enableWidth={enableWidth}
          maxHeight={maxHeight}
          itemsToShow={itemsToShow}
          customStyle={customStyle}
          enableHeight={enableHeight}
          borderRadius={borderRadius}
        />
      </Grid>
    </Grid>
  );
}

export default withTranslation()(CarouselOrganism);
