import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, Rating, Typography, Skeleton, Stack } from "@mui/material";
import { PanoramaFishEye, Circle } from "@mui/icons-material";

import { icons } from "../../../Assets/AssetHelper";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { AddFavoriteAPI } from "../../../Contexts/APIs/Favorites/AddFavorite";
import { DeleteFavoriteAPI } from "../../../Contexts/APIs/Favorites/DeleteFavorite";
import { toast } from "react-toastify";

const SearchCard = ({ t, card, getFavorites }) => {
  const Navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(
    card?.is_favourite_by_current_user
  );
  const [searchParams] = useSearchParams();

  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: card?.id,
            favourite_type: "DiveActivity",
          },
        });
        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: card?.id,
            favourite_type: "DiveActivity",
          },
        });
        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };

  return (
    <Grid container mb={5}>
      <Grid item xs={5} sx={{ position: "relative" }}>
        {card?.media?.[0]?.url !== "url" &&
          card?.activity_type !== "live_aboard" ? (
          <img
            src={card?.media?.[0]?.url}
            style={{
              borderRadius: "10px",
              display: "block",
              height: "300px",
              maxHeight: "300px",
              width: "100%",
            }}
            alt="search result"
          />
        ) : card?.media?.[0]?.url !== "url" &&
          card?.activity_type === "live_aboard" ? (
          <img
            src={card?.media?.[0]?.url}
            style={{
              borderRadius: "10px",
              display: "block",
              height: "329.75px",
              maxHeight: "329.75px",
              width: "100%",
            }}
            alt="search result"
          />
        ) : (
          <Skeleton
            variant="rectangular"
            style={{ borderRadius: "10px" }}
            width="100%"
            height="100%"
            animation={false}
          />
        )}
        <img
          src={isFavorite ? icons.FavouriteYes : icons.FavouriteNo}
          alt="favourite"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={handleFavoriteChange}
        />
      </Grid>
      <Grid
        item
        xs={7}
        sx={{
          paddingInlineStart: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 20 }} gutterBottom>
              {card?.branch?.name}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Rating
              readOnly
              value={parseFloat(card?.avg_rate)}
              sx={{ color: "#3ed181" }}
              size="small"
              precision={0.5}
              icon={<Circle fontSize="inherit" />}
              emptyIcon={
                <PanoramaFishEye fontSize="inherit" sx={{ color: "#3ed181" }} />
              }
            />
            <Typography
              color="#3ed181"
              sx={{ paddingInlineStart: "8px", fontSize: 18 }}
              display="inline"
            >
              {card?.reviews_count}
            </Typography>
          </Grid>
        </Grid>
        <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
          {card?.name}
        </Typography>
        <Grid container mb={2} mt={2}>
          <Grid container item xs={6} alignContent="center">
            <img src={icons.LocationOrangeIcon} alt="location" />
            <Typography
              sx={{ fontSize: 18, marginInlineStart: "10px" }}
              color="#212934"
            >
              {card?.diving_location?.area?.name}
              {" , "}
              {card?.diving_location?.country?.name}
            </Typography>
          </Grid>
          <Grid container item xs={6} alignContent="center">
            <img src={icons.ClockIcon} alt="duration" />
            <Typography
              sx={{ fontSize: 18, marginInlineStart: "10px" }}
              color="#212934"
            >
              {card?.duration_days} {t("Search.days")}
              {" , "}
              {card?.no_of_dives} {t("Search.dives")}
            </Typography>
          </Grid>
        </Grid>
        {card?.activity_type === "live_aboard" && (
          <Grid container mb={2} mt={2}>
            <Grid container item xs={1}>
              <img src={icons.FromTo} alt="from to" />
            </Grid>
            <Grid item xs={11}>
              <Typography
                display="inline"
                sx={{
                  fontSize: 18,
                }}
              >
                {t("HomePage.from")}{" "}
              </Typography>
              <Typography
                display="inline"
                sx={{
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {card?.start_point}{" "}
              </Typography>
              <Typography
                display="inline"
                sx={{
                  fontSize: 18,
                }}
              >
                {t("HomePage.end")}{" "}
              </Typography>
              <Typography
                display="inline"
                sx={{
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {card?.end_point}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Stack alignItems="center" flexDirection="row">
          <Grid container>
            <Typography
              sx={{
                fontSize: 28,
                fontWeight: "500",
                textTransform: "uppercase",
                marginRight: "5px",
              }}
              display="inline"
            >
              {card?.currency?.lookup_key}
            </Typography>
            <Typography
              sx={{ fontSize: 28, fontWeight: "500", paddingInlineEnd: "50px" }}
              display="inline"
            >
              {card?.price_after_discount}
            </Typography>
            {card?.price_after_discount !== card?.price && (
              <>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "500",
                    textTransform: "uppercase",
                    color: "#6e7a8a",
                    pt: 1.5,
                  }}
                  display="inline"
                >
                  {`${card?.currency?.lookup_key} `}
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "500", color: "#6e7a8a" }}
                    component="del"
                  >
                    {card?.price}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "500",
                    color: "#f0b70d",
                    ml: 4,
                    pt: 1.5,
                  }}
                >
                  {t("Search.hotOffer")} {card?.discount_percentage}%{" "}
                  {t("Search.off")}
                </Typography>
              </>
            )}
          </Grid>
        </Stack>
        {card?.next_available_dates.length > 0 && (
          <Typography sx={{ fontSize: 18, fontWeight: "500" }} color="#3ed181">
            {t("Search.available")}
            {card?.next_available_dates?.[0]}
          </Typography>
        )}
        <Grid
          item
          xs={12}
          mt={2}
          sx={{
            height: "fit-content",
            maxHeight: "70px !important",
            marginTop: "0px",
          }}
        >
          <PrimaryButton
            text={t("Details.view")}
            classBtn="white-btn"
            style={{ width: "100%" }}
            click={() =>
              Navigate(
                "/details-view/" +
                  card?.id +
                  `?page_number=${
                    searchParams.get("page_number") || 1
                  }&activity_type=${card?.activity_type}`
              )
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchCard;
