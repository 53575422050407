import { Box, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { icons } from "../../../../Assets/AssetHelper.js";

export default function CarouselImage({
  item,
  imageOnly,
  action = true,
  maxHeight,
  enableWidth = true,
  customStyle,
  enableHeight = true,
  borderRadius,
}) {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        margin: "0 10px",
        cursor: action && "pointer",
        // maxHeight: maxHeight,
        // maxWidth: maxWidth && maxWidth,
      }}
      onClick={() =>
        action && navigate("/search/dive_trip", { state: { city: item } })
      }
    >
      {item?.logo ? (
        <img
          src={item?.logo}
          draggable={false}
          alt={item.text}
          style={{
            width: enableWidth && "100%",
            height: enableHeight && "400px",
            maxHeight: maxHeight,
            position: "relative",
            borderRadius: borderRadius || "10px",
            objectFit: "cover",
            py: "10px",
            ...customStyle,
          }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={400}
          sx={{ position: "relative", bgcolor: "grey", borderRadius: "10px" }}
          animation={false}
        />
      )}
      {imageOnly != true && (
        <Typography
          sx={{ fontSize: "28px", fontWeight: "bold" }}
          color="white"
          style={{
            position: "absolute",
            bottom: "50px",
            left: "20px",
          }}
        >
          {item?.name}
        </Typography>
      )}
      {imageOnly != true && (
        <>
          <img
            src={icons.Location}
            alt="Location"
            style={{
              position: "absolute",
              bottom: "30px",
              left: "20px",
            }}
          />
          <Typography
            sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "1.33" }}
            color="white"
            style={{
              position: "absolute",
              bottom: "22px",
              left: "50px",
            }}
          >
            {item?.city?.country?.name}
          </Typography>
        </>
      )}
    </Box>
  );
}
