import React, { useRef } from "react";
import "./FileInput.css";

function FileInput({ 
    btnStyle,
    icon,
    iconStyle,
    uploadText,
    textStyle,
    change,
    accept
 }) {
    const hiddenFileInput = useRef(null);
    return (
        <div className={btnStyle}>
            {icon && (
                <img 
                    onClick={() => hiddenFileInput?.current?.click()}
                    className={iconStyle+" file-input"} 
                    src={icon} 
                    alt='upload file'
                />
            )}
            {uploadText && (
                <span className={textStyle}>
                    {uploadText}
                </span>
            )}
            <input 
                ref={hiddenFileInput}
                hidden
                type="file"
                onChange={change}
                className="file-input"
                accept={accept}
            />
        </div>
    );
}

export default FileInput;