import React, { useState, useEffect } from "react";
import {
  Grid,
  LinearProgress,
  linearProgressClasses,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { PanoramaFishEye, Circle } from "@mui/icons-material";
import dayjs from "dayjs";
import { withTranslation } from "react-i18next";

const ReviewPanel = ({ details, reviews, t }) => {
  const [total, setTotal] = useState();
  const [star5, setStar5] = useState();
  const [star4, setStar4] = useState();
  const [star3, setStar3] = useState();
  const [star2, setStar2] = useState();
  const [star1, setStar1] = useState();
  // console.log(reviews?.reviews_details?.total_reviews);
  const rating = [
    {
      star: 5,
      rate: star5,
    },
    {
      star: 4,
      rate: star4,
    },
    {
      star: 3,
      rate: star3,
    },
    {
      star: 2,
      rate: star2,
    },
    {
      star: 1,
      rate: star1,
    },
  ];

  useEffect(() => {
    if (JSON.stringify(reviews) !== "{}") {
      setTotal(reviews?.reviews_details?.total_reviews);
      setStar5(reviews?.reviews_details["5"]);
      setStar4(reviews?.reviews_details["4"]);
      setStar3(reviews?.reviews_details["3"]);
      setStar2(reviews?.reviews_details["2"]);
      setStar1(reviews?.reviews_details["1"]);
    }
  }, [reviews]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 4.1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `#e1e7ec`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4.1,
      backgroundColor: "#3ed181",
    },
  }));

  return (
    <Grid container item xs={12} justifyContent="space-between">
      {/* title */}
      <Grid item xs={12} mb={1.5} p={{ xs: 1, md: 0 }}>
        <Typography
          sx={{
            color: "var(--font-color)",
            fontSize: "32px",
            fontWeight: "bold",
          }}
        >
          {t("General.review_rating")}
        </Typography>
      </Grid>

      {/* data with background  */}
      <Grid
        container
        item
        xs={12}
        md={3}
        sx={{
          width: "100%",
          height: "140px",
          backgroundColor: "#f8f8f8",
          gap: 1,
        }}
        alignContent="center"
      >
        <Grid container item xs={12} justifyContent="center">
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: "36.2px",
              fontWeight: "bold",
            }}
          >
            {Math.ceil(details?.avg_rate)}/5
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Rating
            readOnly
            value={parseFloat(details?.avg_rate)}
            sx={{ color: "#3ed181" }}
            size="small"
            precision={0.5}
            icon={<Circle fontSize="inherit" />}
            emptyIcon={
              <PanoramaFishEye fontSize="inherit" sx={{ color: "#3ed181" }} />
            }
          />
        </Grid>

        <Grid container item xs={12} justifyContent="center">
          <Typography
            color="#3ed181"
            sx={{ paddingInlineStart: "8px", fontSize: 18 }}
            display="inline"
          >
            {reviews?.reviews_details?.total_reviews}
          </Typography>
        </Grid>
      </Grid>

      {/* data with charts  */}
      <Grid container item xs={12} md={3} height="200px" p={{ xs: 1, md: 0 }}>
        {rating.map((obj, i) => {
          return (
            <React.Fragment key={i}>
              <Grid container item xs={1} alignContent="start">
                <Typography
                  display="inline"
                  sx={{ fontSize: "16px", color: "#424242" }}
                >
                  {obj.star}
                </Typography>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: "2%" }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={total !== 0 ? (obj.rate / total) * 100 : 0}
                />
              </Grid>
              <Grid container item xs={1} justifyContent="end">
                <Typography
                  display="inline"
                  sx={{ fontSize: "16px", color: "#424242" }}
                >
                  ({obj.rate})
                </Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      {/* reviews */}
      <Grid item xs={12} md={4} gap={4}>
        {reviews?.reviews?.length > 0 ? (
          reviews?.reviews?.map((review, r) => {
            return (
              <Grid
                container
                item
                xs={12}
                mb={2}
                justifyContent="space-between"
                p={{ xs: 1, md: 0 }}
                key={r}
              >
                <Grid container item xs={7} md={9}>
                  <Grid item xs={6}>
                    <Typography fontSize="22px" color="#424242">
                      {review?.reviewer_name}
                    </Typography>
                    <Rating
                      readOnly
                      value={parseFloat(review?.rating)}
                      sx={{ color: "#3ed181" }}
                      size="small"
                      precision={0.5}
                      icon={<Circle fontSize="inherit" />}
                      emptyIcon={
                        <PanoramaFishEye
                          fontSize="inherit"
                          sx={{ color: "#3ed181" }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{review?.date}</Typography>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Typography fontSize="18px" color="#2b2b2b">
                      {review?.comment}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={3}
                  md={3}
                  fontSize="14px"
                  color="#424242"
                >
                  {dayjs(review?.created_at).format("DD MMM YYYY")}
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid container justifyContent="center">
            <Typography>{t("General.no_reviews")}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ReviewPanel);
