import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import DatePickerStatic from "../../../../Atoms/Input/DatePicker/DatePicker.js";
import TextFieldInput from "../../../../Atoms/Input/TextField/TextFieldInput.jsx";

const AddToCartModal = ({
  t,
  open,
  handleClose,
  date,
  setDate,
  openTo,
  views,
  renderDay,
  disablePast,
  disableFuture,
  setSeats,
  seats,
  seatsError,
  remainingSeats,
  submitToCart,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  maxDate
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "auto",
          width: "442px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
          ".MuiCalendarPicker-viewTransitionContainer": {
            border: "solid 1px #ececec",
            borderRadius: "16px",
            boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fcfcfc",
            // width:'370px'
          },
          ".css-15pf3ye-MuiButtonBase-root-MuiPickersDay-root": {
            borderRadius: "5px !important",
          },
          ".Mui-selected": {
            background: "#f6b119 !important",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {t("Search.date")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DatePickerStatic
          value={date}
          setValue={setDate}
          openTo={openTo}
          renderDay={renderDay}
          disablePast={disablePast}
          disableFuture={disableFuture}
          views={views}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          maxDate={maxDate}
        />
      </DialogContent>
      <Divider />
      <Grid
        className="book-calender-input"
        container
        item
        xs={11}
        style={{ margin: "auto", height: "40px", marginTop: "20px" }}
      >
        <Grid container item xs={7} alignContent="center">
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: "18px",
              fontWeight: "bold",
              marginInlineStart: "16px",
            }}
          >
            {remainingSeats} {t("Details.seats")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            ".MuiOutlinedInput-root": {
              height: "100%",
            },
          }}
        >
          <TextFieldInput
            EndAdornment={
              <AddIcon
                style={{ cursor: "pointer" }}
                onClick={() => setSeats((seat) => seat + 1)}
              />
            }
            StartAdornment={
              <RemoveIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  seats - 1 < 0 ? setSeats(0) : setSeats((seat) => seat - 1)
                }
              />
            }
            className="seats-input"
            gridClass="grid-seats"
            value={seats}
            onChange={(e) =>
              e.target.value < 0 ? setSeats(0) : setSeats(e.target.value)
            }
          />
        </Grid>
        {seatsError !== null && (
          <Grid item xs={12} mt={1}>
            <Typography
              sx={{
                color: "red",
                fontSize: "18px",
                fontWeight: "bold",
                marginInlineStart: "16px",
              }}
            >
              {seatsError}
            </Typography>
          </Grid>
        )}
      </Grid>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginTop: "25px",
          marginBottom: "22px",
        }}
      >
        <PrimaryButton
          btnType="submit"
          text={t("Profile.add")}
          classBtn="date-btn"
          click={submitToCart}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddToCartModal;
