import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  handleMenuScrollToBottom,
  removeDuplicateObjects,
} from "../../../Contexts/APIs/Helper/Helper";
// import { handleMenuScrollToBottom } from "../../../Contexts/APIs/Helper/Helper";

const SearchSelect = ({
  className,
  value,
  onchange,
  title,
  items,
  onclick = () => {},
  name,
  label,
  id,
  style,
  IconComponent,
  t,
  getList,
  moreFilterData,
  defaultValues,
  count,
  setItems,
  profileData,
  disabled
}) => {
  // const [isSearching, setIsSearching] = useState(false);
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);
  // Function to handle search input change
  const handleInputChange = (value) => {
    // setSearchTrem(value)
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }
    const timeout = setTimeout(() => {
      // setIsSearching(!!value); // Set searching state based on input
      getList({ keyword: value, page_number: 1, ...moreFilterData });
    }, 700);
    setSearchFilterTimeout(timeout);
  };

  // Prepare items, ensure no duplicates, and add default value when not searching
  const prepareItems = () => {
    // Remove duplicates based on 'id'
    let finalItems = removeDuplicateObjects(items, "id");
  
    // If defaultValues does not match the default value's name, remove the default value from the list
    // if (defaultValues) {
    //   finalItems = finalItems.filter((city) => {
    //     const isDefaultValue = city.id === defaultValues.id;
    //     // If the search term matches the defaultValues name, don't remove it
    //     const isMatch = defaultValues?.name
    //       ?.toLowerCase()
    //       .includes(searchTerm?.toLowerCase());
    //     return !(isDefaultValue && !isMatch);
    //   });
    // }
  
    // Return the final list without duplicates and with the correct city
    return finalItems;
  };
  const finalItems = prepareItems();

  return (
    <FormControl style={style} fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        onOpen={() => {
          setItems && setItems([]);
          getList && getList(moreFilterData);
        }}
        className={className}
        name={name}
        value={value}
        // onClose={(_) => {
        //   setIsSearching(false);
        // }}
        disabled={disabled}
        // MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        onChange={onchange}
        IconComponent={IconComponent}
        MenuProps={{
          PaperProps: {
            onScroll: (event) => {
              handleMenuScrollToBottom({ event, items, getList, count ,moreFilterData});
            },
            sx: {
              height:
                items?.length >= 10
                  ? "300px"
                  : items?.length === 0
                  ? "70px"
                  : "auto", // Set your desired height here
            },
          },
        }}
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder={t("General.type_search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              handleInputChange(event?.target?.value);
            }}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuItem value="" disabled style={{ direction: "ltr" }}>
          {title}
        </MenuItem>
        {finalItems?.map((item) => (
          <MenuItem
            value={item?.id}
            key={item?.id}
            onClick={() => onclick(item?.id)}
            style={{ direction: "ltr" }}
          >
            {item?.icon ? (
              <img
                style={{ margin: "0 10px" }}
                src={item.icon}
                alt="item icon"
              />
            ) : (
              ""
            )}{" "}
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withTranslation()(SearchSelect);
