import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function VerifyAccountAPI(data) {
    return await axiosFn("post", URL.AUTH + URL.VERIFY_ACCOUNT, data)
        .then((res) => {
            return {
                res: res.data.data,
                err: null
            }
        })
        .catch(handleError)
}
