import { Avatar, Grid, Typography } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

const AccountCard = ({ t, profileData, handleClick, type, setType, setOpenConfirm, handleAccountChange, currentAccountClass, isSwitchAccountPage }) => {

    const id = parseInt(localStorage.getItem('ActiveUserId'))
    let accounts = JSON.parse(localStorage.getItem("Accounts"));
    return (
        <Grid container
            sx={{
                width: (type === 'menuItem' && !isSwitchAccountPage) ? '350px' : '100%',
                height: type === 'menuItem' && '80px',
                // bgcolor: profileData?.userId === id && '#fdefd1',
                borderRadius: profileData?.userId === id && '36px'
            }}
            className={ (profileData?.userId === id && currentAccountClass) ? currentAccountClass : '' }
        >
            <Grid container item xs={3} justifyContent='center' alignContent='center'
                onClick={() => { type === 'menuItem' && handleAccountChange(profileData) }}
            >
                <Avatar
                    src={profileData?.avatar}
                    alt="avatar"
                    sx={{ width: '64px', height: '64px' }}
                />
            </Grid>
            <Grid container item xs={7} alignContent='center'
                onClick={() => { type === 'menuItem' && handleAccountChange(profileData) }}
            >
                <Grid container item xs={12} alignContent='center'>
                    <Typography
                        sx={{ fontSize: 'var(--text-font)', fontWeight: 'bold', color: 'var(--font-color)' }} display='inline'
                    >
                        {profileData?.firstname}&nbsp;
                    </Typography>
                    <Typography
                        sx={{ fontSize: 'var(--text-font)', fontWeight: 'bold', color: 'var(--font-color)' }}
                        display='inline'
                    >
                        {profileData?.lastname}
                    </Typography>
                </Grid>
                <Grid container item xs={12} alignContent='center'>
                    <Typography
                        sx={{ fontSize: 'var(--mini-font)', fontWeight: '500', color: 'var(--font-color)' }}
                    >
                        {profileData?.email}
                    </Typography>
                </Grid>
                <Typography
                    sx={{ fontSize: 'var(--mini-font)', fontWeight: '500', color: 'var(--star-color)' }}
                >
                    {profileData?.user_type === 'customer' ? t("Profile.customer_account") : t("Profile.dive_account")}
                </Typography>
            </Grid>
            <Grid container item xs={2} alignContent='center'>
                {type === 'menuItem' ?
                    (profileData?.userId === id && <Typography
                        sx={{ fontSize: 'var(--small-font)', fontWeight: '600', color: '#f3b229' }}
                        onClick={() => {
                            setType('')
                            setOpenConfirm(true)
                        }}
                    >
                        {t("Profile.logout")}
                    </Typography>) :
                    <PrimaryButton
                        btnType="icon"
                        startIcon={<MoreHorizIcon />}
                        click={handleClick}
                    />}
            </Grid>
        </Grid>
    )
}

export default AccountCard