import { Avatar, Grid, Rating, Stack, Typography } from "@mui/material";
import { PanoramaFishEye, Circle } from "@mui/icons-material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";

const ActionsPanel = (props) => {
  const {
    name,
    isFavorite,
    rating,
    number,
    avatar,
    city,
    type,
    diveDetails,
    showInMapClicked,
    handleFavoriteChange,
    handleOpen,
    showReservationComplaint,
    displayLocation = true,
  } = props;

  // showInMapClicked removed to template
  // favorite logic removed to template

  return (
    <Grid container className="mb-30px" px={{ xs: 3, md: 0 }}>
      {type === "expert" && (
        <Grid item lg={1.5} md={3}>
          <Avatar
            src={avatar}
            alt="avatar"
            sx={{ width: "142px", height: "142px" }}
          />
        </Grid>
      )}
      <Grid
        container
        item
        lg={type === "expert" ? 6.5 : 9}
        md={type === "expert" ? 6 : 8}
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography
            style={{
              color: "var(--font-color)",
              fontSize: "38px",
              fontWeight: "bolder",
            }}
            component="h1"
          >
            {name}
          </Typography>
        </Grid>
        {type === "expert" && (
          <Grid item xs={4} mb={1}>
            <Stack alignItems="center" flexDirection="row">
              <img
                src={icons.LocationBlackIcon}
                alt="location"
                style={{ marginInlineEnd: "10px" }}
              />
              <Typography
                display="inline"
                sx={{ fontSize: 18 }}
                color="#212934"
              >
                {city}
              </Typography>
            </Stack>
          </Grid>
        )}
        {type !== "details" && (
          <Grid container alignItems="center">
            <Rating
              readOnly
              value={parseFloat(rating)}
              sx={{ color: "#3ed181" }}
              size="small"
              precision={0.5}
              icon={<Circle fontSize="inherit" />}
              emptyIcon={
                <PanoramaFishEye fontSize="inherit" sx={{ color: "#3ed181" }} />
              }
            />
            <Typography
              color="#3ed181"
              sx={{ paddingInlineStart: "8px", fontSize: 18 }}
              display="inline"
            >
              {number}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item lg={3} md={4} container alignContent="end" justifyContent='end' >
        {/* <Grid item sm={3} alignItems="center">
          {diveDetails?.is_booked_by_current_user && (
            <PrimaryButton
              btnType="icon"
              startIcon={
                <img
                  src={icons.ReservationComplain}
                  alt="Reservation-Complain"
                />
              }
              click={showReservationComplaint}
            />
          )}
        </Grid> */}
        <Grid item sm={3}>
          {type !== "centers" && displayLocation && (
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={icons.LocationBtn} alt="location" />}
              click={showInMapClicked}
            />
          )}
        </Grid>
        <Grid item sm={3}>
          {/* {type !== "centers" && ( */}
          <PrimaryButton
            btnType="icon"
            startIcon={<img src={icons.ShareIcon} alt="share" />}
            click={handleOpen}
          />
          {/* )} */}
        </Grid>
        <Grid item sm={3}>
          <PrimaryButton
            btnType="icon"
            startIcon={
              <img
                src={isFavorite ? icons.LikeYes : icons.LikeIcon}
                alt="favorite"
              />
            }
            click={handleFavoriteChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionsPanel;
