import { Box, Dialog, Divider, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import SelectBox from "../../../Atoms/Select/Select";
import { useEffect, useState } from "react";
import FileInput from "../../../Atoms/Input/FileInput/FileInput";
import Loader from "../../../Atoms/Loader/Loader";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../../Contexts/APIs/Uploads/Upload";
import { icons } from "../../../../Assets/AssetHelper";
import SearchSelect from "../../../Atoms/Select/SearchSelect";
import { toast } from "react-toastify";

const AddEditCertificates = ({
  t,
  open,
  // handleClose,
  setOpen,
  organizations = [],
  getOrganizationLevels,
  createCertificate,
  editCertificate,
  modalType,
  item,
  organizationLevels,
  getOrganizations,
}) => {
  const [organization, setOrganization] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [level, setLevel] = useState([]);
  const [certificateURL, setCertificateURL] = useState();
  const [openLoader, setOpenLoader] = useState(false);

  const handleClose = () => {
    setOrganization("");
    setLevel("");
    setCertificateURL("");
    setOrganizationId(null);
    setOpen(false);
  };

  const uploadCertificate = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const res = await getPresignedLinkAPI([{ extension: "png" }]);
      const presignedUrl = res?.res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      // console.log(upload, res?.res?.data[0].file_url);
      if (upload) setCertificateURL(res?.res?.data[0].file_url);
      setOpenLoader(false);
    }
  };
  const addCertificate = async () => {
    const data = {
      certificate: {
        image: certificateURL,
        // "organization_id": organization,
        organization_certificate_id: level,
      },
    };
    if (certificateURL === "") {
      toast.error(t("Profile.cert_image_req"));
    } else if (level === "") {
      toast.error(t("Profile.cert_level_req"));
    } else {
      await createCertificate(data);
      handleClose();
    }
  };

  const updateCertificate = async (id) => {
    const data = {
      certificates_attributes: [
        {
          id: id,
          image: certificateURL,
          // organization_id: organization,
          organization_certificate_id: level,
        },
      ],
    };
    if (certificateURL === "") {
      toast.error(t("Profile.cert_image_req"));
    } else if (level === "") {
      toast.error(t("Profile.cert_level_req"));
    } else {
      await editCertificate(id, data);
      handleClose();
    }
  };

  useEffect(() => {
    setCertificateURL(modalType === "edit" ? item?.image : "");
    setOrganization(modalType === "edit" ? item?.organization?.id : "");
    if (modalType === "edit")
      getOrganizationLevels({
        organization_id: item?.organization?.id,
        page_size: 10,
      });
    setOrganizationId(modalType === "edit" ? item?.organization?.id : null);
    setLevel(modalType === "edit" ? item?.organization_certificate?.id : "");
  }, [modalType, open, item]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          height: "auto",
          width: "400px",
          padding: "18px",
        },
      }}
    >
      <Loader open={openLoader} />
      <Grid container mb={1}>
        <Grid item xs={11}>
          <Typography
            sx={{
              fontSize: "var(--medium-font)",
              fontWeight: "bold",
              color: "var(--font-color)",
            }}
          >
            {modalType === "add"
              ? t("Profile.add_certificate")
              : t("Profile.edit_certificate")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: "30px" }} />
      <Grid container>
        {/* {console.log(organizations, "organizations")} */}
        <SearchSelect
          getList={getOrganizations}
          defaultValues={item?.organization}
          items={[...organizations, item?.organization]}
          className="login-input"
          value={organization}
          onchange={(e) => setOrganization(e.target.value)}
          label={t("Profile.select")}
          IconComponent={(props) => <ExpandMoreOutlinedIcon {...props} />}
          onclick={(id) => {
            getOrganizationLevels({
              organization_id: id,
              page_size: 10,
            });
            setOrganizationId(id);
            id !== item?.organization?.id && setLevel("");
          }}
        />
        <SearchSelect
          getList={getOrganizationLevels}
          defaultValues={item?.organization_certificate}
          moreFilterData={{ organization_id: organizationId }}
          items={
            organizationId
              ? [...organizationLevels, item?.organization_certificate]
              : []
          }
          className="login-input"
          style={{ marginTop: "16px" }}
          value={level}
          onchange={(e) => setLevel(e.target.value)}
          label={t("Profile.level")}
          IconComponent={(props) => <ExpandMoreOutlinedIcon {...props} />}
        />
        {modalType === "edit" &&
          (certificateURL !== "" ? (
            <Box
              sx={{
                marginTop: "17px",
                position: "relative",
                width: "96px",
                height: "96px",
              }}
            >
              <img
                src={certificateURL}
                alt="certificate"
                style={{
                  display: "block",
                  width: "inherit",
                  height: "inherit",
                }}
              />
              <img
                src={icons.Delete}
                alt="delete"
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  cursor: "pointer",
                }}
                onClick={() => setCertificateURL("")}
              />
            </Box>
          ) : (
            <Grid container sx={{ marginTop: "17px" }}>
              <Grid item xs={2} sx={{ position: "relative" }}>
                <FileInput
                  icon={icons.Upload}
                  change={(e) => uploadCertificate(e)}
                  accept="image/*,application/pdf"
                />
                <Typography
                  sx={{ position: "absolute", top: "30%", left: "40%" }}
                >
                  +
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={10}
                alignContent="center"
                sx={{ paddingInlineStart: "19px" }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#848383",
                  }}
                >
                  {t("Profile.upload")}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography sx={{ color: "black" }}>
                  {window.fileSize}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {modalType === "add" &&
          (certificateURL === "" ? (
            <Grid container sx={{ marginTop: "17px" }}>
              <Grid item xs={2} sx={{ position: "relative" }}>
                <FileInput
                  icon={icons.Upload}
                  change={(e) => uploadCertificate(e)}
                  accept="image/*,application/pdf"
                />
                <Typography
                  sx={{ position: "absolute", top: "30%", left: "40%" }}
                >
                  +
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={10}
                alignContent="center"
                sx={{ paddingInlineStart: "19px" }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#848383",
                  }}
                >
                  {t("Profile.upload")}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography sx={{ color: "black" }}>
                  {window.fileSize}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ marginTop: "17px", width: "96px", height: "96px" }}>
              <img
                src={certificateURL}
                alt="certificate"
                style={{
                  display: "block",
                  width: "inherit",
                  height: "inherit",
                }}
              />
            </Box>
          ))}
      </Grid>
      <Grid container my={3.5}>
        <PrimaryButton
          text={modalType === "add" ? t("Profile.add") : t("Profile.edit")}
          classBtn="orange-btn"
          click={() =>
            modalType === "edit"
              ? updateCertificate(item?.id)
              : addCertificate()
          }
        />
      </Grid>
    </Dialog>
  );
};

export default AddEditCertificates;
