import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import { FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { withTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
const CountrySelect = ({ t, value, onChange, selectClass, label, labels, ...rest }) => {
    // fix `tReady` prop issue
    delete rest.tReady;

    // Helper function to match the beginning of the country label
    const containsText = (text, searchText) =>
      text?.toLowerCase()?.startsWith(searchText?.toLowerCase());
  
    const allOptions = getCountries();
    const [searchText, setSearchText] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    // UseMemo to memoize displayedOptions and avoid unnecessary re-renders
    const displayedOptions = useMemo(() => {
      return allOptions.filter((option) =>
        containsText(labels[option], searchText)
      );
    }, [searchText, labels, allOptions]);
    return(
    <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            {...rest}
            value={value}
            open={isMenuOpen}
            fullWidth
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => {
               setSearchText("");
               setIsMenuOpen(false);
             }}
             onChange={(e) => {
               onChange(e);
               setIsMenuOpen(false); // Close after selecting a country
             }}
            className={selectClass}
            renderValue={() => value}
            MenuProps={{
                autoFocus: false, // Prevent auto-focus behavior when opening the menu
                disableAutoFocusItem: true, // Prevent focusing on the first item when typing
            }}
        >
            <ListSubheader>
                <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder={t("General.type_search")}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                    e.stopPropagation();
                    }
                }}
                />
            </ListSubheader>
            {/* {getCountries().map((country) => ( */}
            {displayedOptions.map((country) => (
                <MenuItem key={country} value={getCountryCallingCode(country)}>
                    {labels[country]} +{getCountryCallingCode(country)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)}

export default withTranslation()(CountrySelect)