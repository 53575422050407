import { Grid, Typography } from "@mui/material"

function UserType(props) {
    return (
    <>
        <Grid container item xs={12} justifyContent='center'>
            <Typography sx={{
                fontSize: '24px',
                fontWeight: '500'
            }}>
                {props.t("Signup.create")}
            </Typography>
        </Grid>
        {props.types.map((type, i) => <Grid container item xs={12} key={i} mt={2} justifyContent='center'>
            <Grid container sx={{
                width: '400px',
                height: '130px',
                border: '1px solid #f6b119',
                borderRadius: '10px',
                boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
                cursor: 'pointer'
            }} onClick={() => props.changeUserType(type.name,i)}>
                <Grid container item xs={8} alignContent='center'>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                        marginInlineStart: '30px'
                    }}>
                        {type.text}
                    </Typography>
                </Grid>
                <Grid container item xs={4}>
                    <img src={type.image} alt='User Type' />
                </Grid>
            </Grid>
        </Grid>)}
    </>)
}

export default UserType