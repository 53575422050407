import React from "react";
import NotificationsListing from "../../../Components/Organisms/Notifications/NotificationsListing";

const NotificationsTemplates = ({ notificationsList, handleClose, setNotificationsList, getList, notifCount }) => {
  return (
    <NotificationsListing
      notificationsList={notificationsList}
      handleClose={handleClose}
      setNotificationsList={setNotificationsList}
      getList={getList}
      notifCount={notifCount}
    />
  );
};

export default NotificationsTemplates;
