import { InputAdornment, Typography, Grid, Box } from "@mui/material";

import { icons } from "../../../Assets/AssetHelper.js";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import AutocompleteInput from "../../Atoms/Input/Autocomplete/Autocomplete";

function SearchBar({
  items,
  text,
  setValue,
  value,
  inputValue,
  setInputValue,
  className,
  justifyContent,
  startIcon,
  endIcon,
  sx,
}) {
  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      if (
        element.mainTitle
          .replace(",", " ")
          .toLowerCase()
          .includes(state.inputValue.toLowerCase())
      )
        newOptions.push(element);
    });
    return newOptions;
  };

  return (
    <AutocompleteInput
      id="search"
      options={items}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.mainTitle}
      value={value || null}
      onChange={(event, newValue) => {
        // console.log(newValue, "newValue");
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        // console.log(newInputValue);
        setInputValue(newInputValue);
        if (newInputValue === "") setValue(null);
      }}
      renderOption={(props, option, { selected }) => (
        <Grid {...props} key={option.id} container>
          <Grid item xs={className === "search-input" ? 2 : 4}>
            <img
              src={
                option.type === "city"
                  ? icons.SearchLocation
                  : option.type === "dive_center"
                  ? icons.SearchDiveCenter
                  : option.type === "freelancer"
                  ? icons.SearchDiveExpert
                  : icons.SearchLocation
              }
              style={{ marginInlineEnd: "12px" }}
              alt="search result"
            />
          </Grid>
          <Grid item xs={className === "search-input" ? 10 : 8}>
            <Typography
              sx={{ fontSize: "19px", fontWeight: "500", color: "#272726" }}
            >
              {option.mainTitle}
            </Typography>
            <Typography sx={{ fontSize: "17px", color: "#aaaaaa" }}>
              {option.subTitle}
            </Typography>
          </Grid>
        </Grid>
      )}
      renderInput={(params, options) => (
        <TextFieldInput
          {...params}
          type="search"
          placeholder={text}
          className={className}
          clickEnter={async (e) => {
            await setValue(items?.[0]);
            await setInputValue(items?.[0]?.mainTitle || "");
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ),
          }}
          justifyContent={justifyContent}
          sx={sx}
        />
      )}
    />
  );
}

export default SearchBar;
