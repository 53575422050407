import { useState } from "react"
import { Grid, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx"
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput"
import CheckBoxMolecule from "../CheckBox/CheckBoxMolecule"
import { useLocation } from "react-router-dom"

function FilterItem({ t, type, text, items, from, setFrom, to, setTo, getActivityData, handleCheckBoxChange, hideAllOpt, showItems }) {
    const routeLocation = useLocation();
    /* condition to set with true where 
        come from specific page and navigate state object has searchHotOffers with true and 
        showItems true to control(open) in specific menu
    */    
    const [show, setShow] = useState(routeLocation?.state?.searchHotOffers && showItems ? showItems : false);
    
    return (<Grid container mt={4}>
        <Grid item xs={12}>
            <PrimaryButton
                text={text}
                endIcon={show ?
                    <ArrowDropUpIcon sx={{ color: 'primary.main' }} /> :
                    <ArrowDropDownIcon sx={{ color: 'primary.main' }} />
                }
                classBtn='filter-btn'
                click={() => setShow(!show)}
            />
        </Grid>
        {show &&
            (type !== 'price' ?
                <Grid item xs={12}>
                    <CheckBoxMolecule
                        items={items}
                        handleCheckBoxChange={handleCheckBoxChange}
                        hideAllOpt={hideAllOpt}
                    />
                </Grid> :
                <Grid container item xs={12} sx={{ height: '42px' }}>  {/* Price Filter */}
                    <Grid item xs={5}>
                        <TextFieldInput
                            className='price-filter'
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                    </Grid>
                    <Grid container item xs={1} justifyContent='center' alignContent='center'>
                        <Typography sx={{ fontSize: '14px', fontWeight: '300', color: '#545454' }} display='inline'>
                            {t("Search.to")}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextFieldInput
                            className='price-filter'
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                    </Grid>
                    <Grid container item xs={1} justifyContent='center' alignContent='center'>
                        <Typography
                            sx={{ fontSize: '14px', color: 'primary.main', cursor: 'pointer' }}
                            display='inline'
                            onClick={() => getActivityData()}
                        >
                            {t("Search.go")}
                        </Typography>
                    </Grid>
                </Grid>)
        }
    </Grid>)
}

export default FilterItem