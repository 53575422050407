import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import { FaqAPI } from "../../../../Contexts/APIs/Lookups/Faq";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Loader from "../../../Atoms/Loader/Loader";

const Faqs = ({ t }) => {
  const [expanded, setExpanded] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [faqPage, setFaqPage] = useState(1);
  const [faqCount, setFaqCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePageChange = (event, value) => {
    setFaqPage(value);
  };
  /* Faqs */
  const getFaqs = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await FaqAPI({ for: "customer", ...filterObj });
    if (res) {
      setFaqs(res?.data?.faqs);
      setFaqCount(res?.extra?.total_count);
      setFaqPage(res?.extra?.page_number);
      setOpenLoader(false);
    }
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  useEffect(() => {
    getFaqs({ page_number: faqPage });
  }, [faqPage]);
  return (
    <>
      <Loader open={openLoader} />
      <Grid container sx={{ p: { xs: 0, md: 3 } }}>
        <Grid item xs={12} mb={3}>
          <Typography
            sx={{
              fontSize: "var(--xlarge-font)",
              fontWeight: "800",
              color: "var(--font-color)",
            }}
          >
            {t("Profile.faq")}
          </Typography>
        </Grid>
        <Stack gap={2} width={"100%"} minHeight={"70vh"}>
          {faqs.length > 0 &&
            faqs.map((faq, i) => {
              return !faq.is_hidden ? (
                <Accordion
                  expanded={expanded === `panel1${i}`}
                  onChange={handleChange(`panel1${i}`)}
                  sx={{ width: "100%" }}
                  key={i}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          backgroundColor: "#f6b119",
                          borderRadius: "50%",
                          color: "white",
                        }}
                      />
                    }
                    aria-controls={`panel${i}bh-content`}
                    id={`panel${i}bh-header`}
                  >
                    <Typography
                      component="p"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#181918",
                      }}
                    >
                      {faq?.question}
                      <br />
                    </Typography>
                    {/* <Typography
                             component='p'
                             sx={{ fontSize: '16px', color: '#6f6f6f' }}
                         >
                             {faq?.answer}
                         </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#6f6f6f",
                        lineHeight: 1.5,
                      }}
                    >
                      {faq?.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ) : null;
            })}
        </Stack>

        {faqCount > 10 && (
          <Grid container item xs={12} justifyContent="center" mt={2}>
            <CustomPagination
              count={Math.ceil(faqCount / 10)}
              size="large"
              shape="rounded"
              page={faqPage}
              handleChange={handlePageChange}
              className="pagination"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withTranslation()(Faqs);
