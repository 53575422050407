import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import en from "./en/translate.json";
import de from "./de/translate.json";
import nl from "./nl/translate.json";
import fr from "./fr/translate.json";
import it from "./it/translate.json";
import ru from "./ru/translate.json";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      de: de,
      fr: fr,
      nl: nl,
      it: it,
      ru: ru,
    },
    lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
