export const BASE_URL = process.env.REACT_APP_API_URL;
export const AUTH = "auth/";
export const LOGIN = "login";
export const REGISTER = "/register";
export const BRNCHES = "/branches";
export const CREATE_AUTH_TOKEN = 'tokens/'

export const LOGOUT = "logout";
export const PASSWORDS = "passwords/";
export const CHANGE_PASSWORD = "/change_password";
export const VERIFY_ACCOUNT = "verification/verify_account";
export const RE_SEND_VERIFY = "verification/send_verification_info";
export const CHECK_EXISTS = "passwords/customer/check_exists";
export const RESET_PASSWORD = "passwords/customer/reset_password";
export const SEND_OTP = "send_reset_password_info";
export const VERIFY_OTP = "passwords/customer/verify_otp";
export const COUNTRY = "lookups/countries";
export const CITY = "lookups/cities";
export const LOCATION = "lookups/locations";
export const CURRENCY = "lookups/currencies";
export const CONFIRMATION_OPTION = "lookups/confirmation_options";
export const LANGUAGE = "lookups/languages";
export const DIVE_EXPERIENCE = "lookups/dive_experiences";
export const DIVE_CENTERS_TOPRATED = "dive_centers";
export const ACTIVITIES = "activities";
export const SEARCH = "home_page/search";
export const PROFILE = "profile/";
export const SHOW = "/show";
export const DELETE_PROFILE = "profile/delete";
export const UPDATE_PROFILE = "profile/update";
export const INVITATION_MAILER = "/invitation_mailer";
export const PRESIGNED_URL = "generate_presigned_url";
export const ORGANIZATIONS = "organizations";
export const ORGANIZATION_LEVELS = "organization_certificates";
export const CERTIFICATE = "certificates/";
export const CART = "carts/current";
export const ADD_EDIT_CART = "/add_item";
export const DELETE = "/items/";
export const CHECKOUT = "/checkout";
export const REDEEM = "/redeem_points";
export const DIVE_EXPERTS_TOPRATED = "dive_experts";
export const ORDERS = "orders";
export const REVIEWS = "reviews";
export const SYSTEM = "lookups/system_configurations";
export const CART_ITEM = "/cart_item/";
export const FAVORITES = "favourites";
export const UPDATE_PAYMENT = "/update_payment";
export const AREA = "lookups/areas";
export const SOCIAL = "lookups/social_media";
export const INVITATION_LINK = "fetch_invitation_link";
export const DOCUMENTS = "lookups/get_documents";
export const NOTIFICATION_LIST = "notifications";
export const NOTIFICATION_COUNT = "notifications/count";
export const REMAINING = "/get_remaining_seats_on_date";
export const POPULAR = "/popular_destinations";
export const LEVELS = "lookups/customer_levels";
export const USERS = "lookups/users";
export const BULK_LOGOUT = "auth/bulk_logout";
export const FAQ = "lookups/faqs";
export const COMPLAINS = "complaints/reservation_complaint";
export const PAY = "/pay_order";
export const POINTS_ACTIONS = "lookups/point_actions";
export const POINTS_CONFIG = "lookups/point_configs";
export const AUTHENTICITY = "auth/verify_token_authenticity";
export const FCM = "auth/set_device_fcm";
export const get_supported_languages = "lookups/get_supported_languages";
export const SPONSORS = "/lookups/sponsors";
export const CONTACTS = "/lookups/contacts";

export const GENERAL_COMPLAINT = "complaints/general_complaint/";
export const APP_COMPLAINT = "complaints/app_complaint/";
