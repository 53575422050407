import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
// import { useEffect, useState } from "react";

import { icons } from "../../../../Assets/AssetHelper";

export default function BoatPanel({ t, diveDetails }) {
    const boat = [
        {
            icon: icons.Dimensions,
            name: t("Details.length"),
            data: `${diveDetails?.boat?.length}m/${diveDetails?.boat?.width}m`
        },
        {
            icon: icons.Build,
            name: t("Details.build"),
            data: dayjs(diveDetails?.boat?.year_built).format("YYYY")
        },
        // {
        //     icon: icons.Wifi,
        //     name: t("Details.wifi"),
        //     data: diveDetails?.boat?.wifi_status
        // },
        {
            icon: icons.Cabins,
            name: t("Details.cabins"),
            data: diveDetails?.boat?.total_cabins
        },
        // {
        //     icon: icons.Equipment,
        //     name: t("Details.rental"),
        //     data: diveDetails?.boat?.rental_equipment_available ? t("Details.yes") : t("Details.no")
        // },
        // {
        //     icon: icons.Nitrox,
        //     name: t("Details.nitrox"),
        //     data: diveDetails?.boat?.nitrox_available ? t("Details.yes") : t("Details.no")
        // }
    ]
    // const [extra, setExtra] = useState();

    // useEffect(() => {
    //     if (diveDetails?.activity_type === "live_aboard") {
    //         const array = []
    //         diveDetails?.branch?.branch_features?.forEach(item => {
    //             if (item.name === 'Air condition' || item.name === 'Coffee & Restaurant' || item.name === 'Parking') array.push({
    //                 icon: item.icon,
    //                 name: item.name,
    //                 enabled: item.enabled
    //             })
    //         })
    //         setExtra(array)
    //     }
    // }, [diveDetails]);

    const branchFeatures = diveDetails?.branch?.branch_features?.filter( item => item.is_deleted != true );    
    return (
        <Grid container sx={{ border: { xs: 'none', md: 'solid 1px #ececec' }, paddingY: '20px' }} mb={2.5} >
            {diveDetails?.activity_type === "live_aboard" && boat.map((item, i) => {
                return (
                    <Grid container item xs={6} md={2} alignContent='center' key={i} mb={{ xs: 2.5, md: 0 }}>
                        <Grid container item xs={4} md={12} justifyContent='center' mb={1.5}>
                            <img src={item.icon} alt={item.name} style={{
                                height: '37px'
                            }} />
                        </Grid>
                        <Grid container item xs={8} md={12} justifyContent='center'>
                            <Grid container justifyContent='center'>
                                <Typography sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    color: '#2b2b2b'
                                }}>
                                    {item.name}
                                </Typography>
                            </Grid>
                            <Grid container sx={{
                                display: {
                                    xs: 'inherit',
                                    md: 'none'
                                }
                            }} justifyContent='center'>
                                <Typography sx={{
                                    fontSize: '14px',
                                    fontWeight: '800',
                                    color: '#2b2b2b'
                                }}>
                                    {item.data}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justifyContent='center' sx={{ display: { xs: 'none', md: 'inherit' } }}>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '800',
                                color: '#2b2b2b'
                            }}>
                                {item.data}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            })}
            {/* {extra?.length > 0 &&
                extra?.map(item => {
                    return (
                        <Grid container item xs={2} alignContent='center'>
                            <Grid container item xs={12} justifyContent='center' mb={1.5}>
                                <img src={item.icon} alt={item.name} style={{
                                    height: '37px'
                                }} />
                            </Grid>
                            <Grid container item xs={12} justifyContent='center'>
                                <Typography sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    color: '#2b2b2b'
                                }}>
                                    {item.name}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent='center'>
                                <Typography sx={{
                                    fontSize: '14px',
                                    fontWeight: '800',
                                    color: '#2b2b2b'
                                }}>
                                    {item.enabled ? t("Details.yes") : t("Details.no")}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })
            } */}
            {branchFeatures?.length > 0 &&
                branchFeatures?.map(item => {
                    return (
                        <Grid container item xs={6} md={2} alignContent='center' key={item?.id} mb={{ xs: 2.5, md: 0 }}>
                            <Grid container item xs={4} md={12} justifyContent='center' mb={1.5}>
                                <img src={item.icon} alt={item.name} style={{
                                    height: '37px'
                                }} />
                            </Grid>
                            <Grid container item xs={8} md={12} >
                                <Grid container justifyContent='center'>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: '#2b2b2b'
                                    }}>
                                        {item.name}
                                    </Typography>
                                </Grid>
                                <Grid container sx={{
                                    display: {
                                        xs: 'inherit',
                                        md: 'none'
                                    }
                                }} justifyContent='center'>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        fontWeight: '800',
                                        color: '#2b2b2b'
                                    }}>
                                        {item.enabled ? t("Details.yes") : t("Details.no")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} justifyContent='center' sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                <Typography sx={{
                                    fontSize: '14px',
                                    fontWeight: '800',
                                    color: '#2b2b2b'
                                }}>
                                    {item.enabled ? t("Details.yes") : t("Details.no")}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}
