import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import { MenuItem, MenuList } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages";

const Language = () => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [selectedLang, setSelectedLang] = useState({});
  // This is a temporary array until translation files are ready
  const tempLanguages = [
    { id: "en", name: "English" },
    { id: "de", name: "German" },
    { id: "fr", name: "French" },
  ];
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  useEffect(() => {
    getLanguages();
  }, []);

  const handelSelectLang = (data) => {
    setSelectedLang(data);
    localStorage.setItem("lang", data?.id);
    window.location.reload();
  };
  useEffect(() => {
    const selectedData = supportedLanguages?.find(
      (data) => data?.id == localStorage.getItem("lang")
    );
    setSelectedLang(selectedData);
  }, [localStorage.getItem("lang"), supportedLanguages]);

  return (
    <div>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          padding: "1px 6px",
          borderPadius: "4px",
          backgroundColor: "#424141",
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
          cursor:'pointer'
        }}
      >
        {selectedLang?.name}
      </Box>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
              <MenuList>
                {tempLanguages?.map((data, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => handelSelectLang(data)}
                    >
                      {data?.name}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default Language;
