import { Button, Grid, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";

import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import BookPanel from "../../../Components/Organisms/Details/DetailsPanels/BookPanel";
import EditForm from "../../../Components/Organisms/Reservation/EditForm";
import LocationPanel from "../../../Components/Organisms/Details/DetailsPanels/LocationPanel";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { withTranslation } from "react-i18next";

const EditDiveTripTemplate = ({
  t,
  card,
  addEditCart,
  mapKey,
  cart,
  diveDetails,
  getCart,
  getRemainingSeats,
  remainingSeats,
  status,
  statusDisplay,
  payment,
  isFromBooking,
  orderId,
}) => {
  const [quantity, setQuantity] = useState(card?.quantity);

  const [center, setCenter] = useState({ lat: 27.2579, lng: 33.8116 });
  const [userPosition, setUserPosition] = useState({ lat: "", lng: "" });
  const [destination, setDestination] = useState({ lat: "", lng: "" });
  const [address, setAddress] = useState("");
  const [arrayOfParticipants, setArrayOfParticipants] = useState([]);
  const formRef = useRef();
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyInfo = () => {
    setIsCopied((prev) => !prev);
  };
  useEffect(() => {
    if (arrayOfParticipants?.length > 0) {
      const firstParticipant = arrayOfParticipants[0]; // Get the first participant
      const newParticipants = arrayOfParticipants?.map((participant, index) => {
        if (isCopied) {
          // If isCopied is true, copy values from the first participant
          return {
            ...participant,
            name: firstParticipant?.name,
            country_code: firstParticipant?.country_code,
            phone_number: firstParticipant?.phone_number,
            email: firstParticipant?.email,
          };
        } else {
          // If isCopied is false, clear all fields except the first participant
          if (index === 0) {
            // Keep the first participant's data intact
            return participant;
          } else {
            // Clear the fields for all other participants
            return {
              ...participant,
              name: "",
              country_code: "",
              phone_number: "",
              email: "",
            };
          }
        }
      });
      setArrayOfParticipants(newParticipants); // Update state with the new array
    }
  }, [isCopied]);
  
  const initialValues = {
    reservation_participants: arrayOfParticipants,
  };

  function onSubmit(fields) {
    // const reservationParticipants = fields.reservation_participants?.map(
    const reservationParticipants = arrayOfParticipants?.map((val) => {
      // console.log(val, "submit");
      // console.log(formRef.current?.errors, "errors");

      return {
        ...val,
        last_dive_date: val?.last_dive_date
          ? dayjs(val?.last_dive_date).format("YYYY-MM-DD")
          : null,
      };
    });
    if (card?.purchasable?.dive_activity?.pickup_option === "hotel_pickup") {
      if (address !== "") {
        addEditCart({
          cart_item: {
            id: card?.id,
            dive_activity_id: card?.purchasable?.dive_activity_id,
            start_date: card?.purchasable?.start_date,
            quantity: quantity,
          },
          pickup_location: {
            lat: center.lat,
            long: center.lng,
          },
          reservation_participants: reservationParticipants,
        });
      } else {
        addEditCart({
          cart_item: {
            id: card?.id,
            dive_activity_id: card?.purchasable?.dive_activity_id,
            start_date: card?.purchasable?.start_date,
            quantity: quantity,
          },
          // pickup_location: {
          //   lat: center.lat,
          //   long: center.lng,
          // },
          reservation_participants: reservationParticipants,
        });
      }
    } else {
      addEditCart({
        cart_item: {
          id: card?.id,
          dive_activity_id: card?.purchasable?.dive_activity_id,
          start_date: card?.purchasable?.start_date,
          quantity: quantity,
        },
        reservation_participants: reservationParticipants,
      });
    }
  }

  function onChangeParticipants(seats) {
    // update dynamic form
    const participants = arrayOfParticipants;
    const numberOfParticipants = seats || 0;
    const previousNumber = parseInt(participants?.length || "0");
    if (previousNumber < numberOfParticipants) {
      for (let i = previousNumber; i < numberOfParticipants; i++) {
        participants?.push({
          name: "",
          country_code: "",
          phone_number: "",
          email: "",
          last_dive_date: "",
          no_of_dives: "",
          certificates_list: "",
        });
      }
    } else {
      for (let i = previousNumber; i >= numberOfParticipants; i--) {
        participants?.splice(i, 1);
      }
    }
    setArrayOfParticipants(participants);
    setQuantity(seats);
  }

  useEffect(() => {
    setTimeout(() => {
      if (card?.reservation_participants?.length === 0) {
        let array = [];
        for (let i = 0; i < card?.quantity; i++) {
          array.push({
            name: "",
            country_code: "",
            phone_number: "",
            email: "",
            last_dive_date: "",
            no_of_dives: "",
            certificates_list: "",
          });
        }
        setArrayOfParticipants(array);
      } else {
        let array = [];
        for (let i = 0; i < card?.quantity; i++) {
          array.push({
            id: card?.reservation_participants[i]?.id,
            name: card?.reservation_participants[i]?.name,
            country_code: parseInt(
              card?.reservation_participants[i]?.country_code
            ),
            phone_number: card?.reservation_participants[i]?.phone_number,
            email: card?.reservation_participants[i]?.email,
            last_dive_date: card?.reservation_participants[i]?.last_dive_date
              ? card?.reservation_participants[i]?.last_dive_date
              : null,
            no_of_dives: card?.reservation_participants[i]?.no_of_dives,
            certificates_list:
              card?.reservation_participants[i]?.certificates_list,
          });
        }

        setArrayOfParticipants(array);
      }
    }, 1000);
    if (card?.purchasable?.dive_activity?.pickup_option !== "hotel_pickup") {
      setDestination({
        lat: card?.pickup_location?.lat,
        lng: card?.pickup_location?.long,
      });
    }
    if (card?.pickup_location !== null)
      setAddress(card?.pickup_location?.full_address);
    if (card?.pickup_location == null) setAddress("");
    setQuantity(card?.quantity);
  }, [card]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  return (
    <Grid
      container
      mt={5}
      mb={8}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
        px: { xs: 2 },
        gap: 4,
      }}
    >
      <Grid item xs={12} mb={5}>
        <Typography
          sx={{
            color: "var(--font-color)",
            fontSize: "var(--home-header-font)",
            fontWeight: "bold",
          }}
        >
          {t("Edit_Trip.edit")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <LocationPanel
          t={t}
          location={card?.pickup_location}
          pickupOption={card?.purchasable?.dive_activity?.pickup_option}
          center={center}
          setCenter={setCenter}
          mapKey={mapKey}
          userPosition={userPosition}
          destination={destination}
          address={address}
          setAddress={setAddress}
        />
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {({
            values,
            submitForm,
            setFieldValue,
            setValues,
            errors,
            touched,
          }) => (
            <Form
              onChange={() => {
                // setTimeout(() => {
                //   console.log(arrayOfParticipants);
                // }, 500);
              }}
            >
              {/* {console.log(errors,'errors')} */}
              <FieldArray name="reservation_participants">
                {() =>
                  // values?.reservation_participants?.map((participant, i) => {
                  arrayOfParticipants?.map((participant, i) => {
                    return (
                      <EditForm
                        participant={participant}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        arrayOfParticipants={arrayOfParticipants}
                        key={i}
                        i={i}
                        t={t}
                        setArrayOfParticipants={setArrayOfParticipants}
                        handleCheckChange={handleCopyInfo}
                        checked={isCopied}
                      />
                    );
                  })
                }
              </FieldArray>
              <Grid container>
                <PrimaryButton
                  text={t("Signup.done")}
                  type="submit"
                  classBtn="orange-btn"
                  click={submitForm}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12} md={4}>
        <BookPanel
          t={t}
          card={card}
          onChangeParticipants={onChangeParticipants}
          cart={cart}
          quantity={quantity}
          diveDetails={diveDetails}
          addEditCart={addEditCart}
          getCart={getCart}
          // deleteOrder={deleteOrder}
          orderId={orderId}
          isFromBooking={isFromBooking}
          status={status}
          statusDisplay={statusDisplay}
          getRemainingSeats={getRemainingSeats}
          remainingSeats={remainingSeats}
          payment={payment}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(EditDiveTripTemplate);
