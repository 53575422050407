import { Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import ViewProfile from "../../../Components/Organisms/Profile/Tabs/ViewProfile/ViewProfile.jsx";
import PrivacyPolicy from "../../../Components/Organisms/Profile/Tabs/PrivacyPolicy.jsx";
import TermsAndConditions from "../../../Components/Organisms/Profile/Tabs/TermsAndConditions";
import Covid from "../../../Components/Organisms/Profile/Tabs/Covid";
import Settings from "../../../Components/Organisms/Profile/Tabs/Settings";
import InviteYourFriends from "../../../Components/Organisms/Profile/Tabs/InviteYourFriends.jsx";
import DeleteAccount from "../../../Components/Organisms/Profile/Tabs/ViewProfile/DeleteAccount.jsx";
import EditProfile from "../../../Components/Organisms/Profile/Tabs/ViewProfile/EditProfile.jsx";
import SidePanel from "../../../Components/Organisms/Profile/ActionSidePanel/SidePanel.jsx";
import Points from "../../../Components/Organisms/Profile/Tabs/Points.jsx";
import Faqs from "../../../Components/Organisms/Profile/Tabs/Faqs.jsx";
import Support from "../../../Components/Organisms/Profile/Tabs/Support.jsx";
import Report from "../../../Components/Organisms/Profile/Tabs/Report.jsx";

const ProfileTemplate = ({
  t,
  profileData,
  changePassword,
  Logout,
  deleteProfile,
  updateProfile,
  getProfileData,
  countries,
  cities,
  organizations,
  getOrganizationLevels,
  createCertificate,
  deleteCertificate,
  editCertificate,
  language,
  onLanguageHandle,
  currencies,
  languages,
  organizationLevels,
  setIsAuth,
  getCities,
  areas,
  getAreas,
  social,
  levels,
  userLanguages,
  BulkLogout,
  faqs,
  faqPage,
  setFaqPage,
  faqCount,
  pointsActions,
  pointsConfig,
  pointsRate,
  getCountries,
  getCurrencies,
  getLanguages,
  getOrganizations,
  getSocialMedia,
  getLevels,
  getPointsActions,
  getPointsConfig,
  citiesCount,
  areasCount,
  countriesCount,
  setCities,
  setAreas,
  setCountries,
  setUserLanguages,
  languagesCount
}) => {
  const location = useLocation();
  let path = location.pathname;
  return (
    <Grid container width="80%" mx="auto" mt={3} mb={5}>
      <Grid
        item
        xs={3.5}
        sx={{
          border: "solid 1px #ececec",
          borderRadius: "8px",
          height: "740px",
          display: { xs: "none", md: "block" },
        }}
      >
        <SidePanel
          t={t}
          profileData={profileData}
          Logout={Logout}
          path={path}
          setIsAuth={setIsAuth}
          BulkLogout={BulkLogout}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8.5}
        sx={{ paddingInlineStart: { xs: 0, md: "24px" } }}
      >
        <Routes>
          <Route
            index
            element={
              <ViewProfile
                t={t}
                profileData={profileData}
                changePassword={changePassword}
                levels={levels}
                getLevels={getLevels}
              />
            }
          />
          <Route
            path="view-profile"
            element={
              <ViewProfile
                t={t}
                profileData={profileData}
                changePassword={changePassword}
                levels={levels}
                getLevels={getLevels}
              />
            }
          />
          <Route
            path="delete-account"
            element={<DeleteAccount t={t} deleteProfile={deleteProfile} />}
          />
          <Route
            path="edit-profile"
            element={
              <EditProfile
                t={t}
                profileData={profileData}
                updateProfile={updateProfile}
                getProfileData={getProfileData}
                countries={countries}
                cities={cities}
                organizations={organizations}
                getOrganizationLevels={getOrganizationLevels}
                createCertificate={createCertificate}
                deleteCertificate={deleteCertificate}
                editCertificate={editCertificate}
                organizationLevels={organizationLevels}
                userLanguages={userLanguages}
                getCities={getCities}
                areas={areas}
                getAreas={getAreas}
                social={social}
                getCountries={getCountries}
                getCurrencies={getCurrencies}
                getLanguages={getLanguages}
                getOrganizations={getOrganizations}
                getSocialMedia={getSocialMedia}
                getLevels={getLevels}
                citiesCount={citiesCount}
                countriesCount={countriesCount}
                areasCount={areasCount}
                setCities={setCities}
                setCountries={setCountries}
                setAreas={setAreas}
                languagesCount={languagesCount}
                setUserLanguages={setUserLanguages}
              />
            }
          />

          <Route
            path="points"
            element={
              <Points
                t={t}
                profileData={profileData}
                pointsActions={pointsActions}
                pointsConfig={pointsConfig}
                pointsRate={pointsRate}
                getPointsActions={getPointsActions}
                getPointsConfig={getPointsConfig}
              />
            }
          />
          <Route path="invite-friends" element={<InviteYourFriends t={t} />} />
          <Route
            path="settings"
            element={
              <Settings
                t={t}
                language={language}
                onLanguageHandle={onLanguageHandle}
                currencies={currencies}
                getCurrencies={getCurrencies}
                languages={languages}
                updateProfile={updateProfile}
                pushStatus={profileData?.enable_push}
                profileData={profileData}
              />
            }
          />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          {/* <Route path='help'
                        element={<Help t={t} />}
                    /> */}
          <Route path="covid" element={<Covid t={t} />} />
          <Route
            path="faq"
            element={
              <Faqs
                t={t}
                faqs={faqs}
                faqPage={faqPage}
                setFaqPage={setFaqPage}
                faqCount={faqCount}
              />
            }
          />
          <Route
            path="support"
            element={<Support t={t} userEmail={profileData.email} />}
          />
          <Route
            path="report"
            element={<Report t={t} userEmail={profileData.email} />}
          />
          {/* <Route
            path="complains"
            element={<Complain t={t} profileData={profileData} />}
          /> */}
        </Routes>
      </Grid>
    </Grid>
  );
};

export default ProfileTemplate;
