import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function AddEditCartItemAPI(data) {
    return await axiosFn("put", URL.CART + URL.ADD_EDIT_CART, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
