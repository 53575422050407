import { Grid, Modal, Typography } from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { ComplainsAPI } from "../../../../Contexts/APIs/Lookups/Complains";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import { icons } from "../../../../Assets/AssetHelper";

const ComplainstModal = ({ t, open, handleClose, diveDetails }) => {
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState(null);

  const handelSendComplain = () => {
    if (message?.length >= 100) {
      const { res } = ComplainsAPI(diveDetails?.id, {
        complaint: {
          sender_email: email,
          description: message,
        },
      });
      if (res) {
        handleClose();
      }
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100%", md: 600 },
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: { xs: 1, md: 4 },
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        container
        sx={style}
        justifyContent="space-evenly"
        alignContent="center"
      >
        <Grid container item xs={12} alignItems="center">
          <Typography fontSize="20px" fontWeight="700">
            {t("Details.reservation_complain")}
          </Typography>
          <TextFieldInput
            placeholder={t("Details.email_address")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="support-input"
            sx={{ mt: 2, bgcolor: "#F9F9F9" }}
          />
          <TextFieldInput
            placeholder={t("Details.write_briefly_description")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="support-input"
            sx={{
              mt: 2,
              bgcolor: "#F9F9F9 !important",
              color: "#848383 !important",
              fontWeight: "700 !important",
            }}
            multiline
            rows={4}
          />

          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignContent="center"
            sx={{ mt: 2 }}
          >
            <PrimaryButton
              sx={{ width: "50% !important", borderRadius: "20px !important" }}
              text={t("Details.submit")}
              classBtn="book-btn"
              click={handelSendComplain}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withTranslation()(ComplainstModal);
