import { useEffect, useState } from "react";

import CartTemplate from "../../../Templates/Main/Reservation/Cart.Template.jsx";
import { withTranslation } from "react-i18next";
import Loader from "../../../Components/Atoms/Loader/Loader.js";


const CartPage = ({
  t,
  cart,
  getCart,
  redeem,
  getFavorites,

  favorites,
  deleteCart,
  openLoader,
  setOpenLoader
}) => {

  useEffect(() => {
    getCart();      
  }, []);

  return (
    <>
      <Loader open={openLoader} />
      <CartTemplate
        t={t}
        cart={cart}
        deleteCart={deleteCart}
        redeem={redeem}        
        getFavorites={getFavorites}
        getCart={getCart}
        favorites={favorites}
      />
    </>
  );
};

export default withTranslation()(CartPage);
