import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import en from "react-phone-number-input/locale/en.json";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import CountrySelect from "../../Atoms/Input/CountrySelect/CountrySelect";
import DateModal from "../Modals/DateModal";
import { useEffect, useRef, useState } from "react";
import BasicDatePicker from "../../Atoms/Input/DatePicker copy/DatePicker";

function EditForm({
  i,
  t,
  values,
  setFieldValue,
  arrayOfParticipants,
  errors,
  touched,
  participant,
  setArrayOfParticipants,
  checked,
  handleCheckChange,
}) {
  const [open, setOpen] = useState(false);
  const [newParticipant, setNewParticipant] = useState(participant);
  const countryCodeRef = useRef();

  useEffect(() => {
    setNewParticipant(participant);
  }, [arrayOfParticipants]);
  const hasValues =
    participant?.name ||
    participant?.country_code ||
    participant?.phone_number ||
    participant?.email;

  return (
    <Grid
      container
      mb={3}
      sx={{
        padding: "18px",
        borderRadius: "8px",
        border: "solid 0.5px #979797",
        gap: 1,
      }}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "var(--large-font)",
            fontWeight: "bold",
            width: "fit-content",
          }}
        >
          {t("Edit_Trip.info")} {i + 1}
        </Typography>
        {i == 0 && arrayOfParticipants?.length > 1 && (
          <FormControlLabel
            checked={checked}
            onChange={handleCheckChange}
            control={<Checkbox />}
            disabled={!hasValues}
            label={
              <Typography
                sx={{
                  color: "rgba(39, 39, 38, 0.8)",
                  fontSize: "var(--text-font)",
                  fontWeight: "bold",
                }}
              >
                {t("General.copy_info")}
              </Typography>
            }
            sx={{
              marginRight: "5px",
              width: "fit-content",
            }}
          />
        )}
      </Grid>
      <Grid container item xs={12} md={5.3}>
        <Field /* Name */
          as={TextFieldInput}
          name={`reservation_participants.${i}.name`}
          placeholder={t("Edit_Trip.name")}
          label={t("Edit_Trip.name")}
          className="login-input"
          gridClass="grid-input"
          value={newParticipant.name}
          onChange={(e) => {
            const newParticipantSwap = {
              ...newParticipant,
              name: e.target.value,
            };
            setNewParticipant({ ...newParticipant, name: e.target.value });
            arrayOfParticipants?.splice(i, 1, newParticipantSwap);
          }}
        />
        {newParticipant.name?.length == 0 &&
          touched?.reservation_participants &&
          touched?.reservation_participants[i]?.name &&
          errors?.reservation_participants &&
          errors?.reservation_participants[i]?.name && (
            <ErrorMessage
              name={`reservation_participants.${i}.name`}
              component="span"
              style={{ color: "#d32f2f" }}
            />
          )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={5.5}
        pl={{ xs: 0, md: 4 }}
        mt={{ xs: 3, md: 0 }}
        alignItems="end"
        gap={1}
      >
        <Grid
          item
          xs={3}
          ref={countryCodeRef}
          data-code={newParticipant.country_code}
        >
          <Field /* countryCode */
            as={CountrySelect}
            name={`reservation_participants.${i}.country_code`}
            labels={en}
            selectClass="login-input"
            label={t("Edit_Trip.code")}
            value={newParticipant.country_code}
            onChange={(e) => {
              const newParticipantSwap = {
                ...newParticipant,
                country_code: e.target.value,
              };
              setNewParticipant({
                ...newParticipant,
                country_code: e.target.value,
              });
              arrayOfParticipants.splice(i, 1, newParticipantSwap);
              countryCodeRef.current.setAttribute("data-code", e.target.value);
            }}
          />
          {errors?.reservation_participants &&
            errors?.reservation_participants[i]?.country_code &&
            (!countryCodeRef.current?.getAttribute("data-code") ||
              countryCodeRef.current?.getAttribute("data-code").value
                ?.length === 0) &&
            countryCodeRef.current?.querySelector(
              `input[name="reservation_participants.${i}.country_code"]`
            ).value?.length === 0 && (
              <ErrorMessage
                name={`reservation_participants.${i}.country_code`}
                component="span"
                style={{ color: "#d32f2f" }}
              />
            )}
        </Grid>
        <Grid item xs={8.5}>
          <Field /* Phone */
            as={TextFieldInput}
            name={`reservation_participants.${i}.phone_number`}
            className="login-input"
            placeholder={t("Profile.mobile")}
            label={t("Profile.mobile")}
            value={newParticipant.phone_number}
            onChange={(e) => {
              const newParticipantSwap = {
                ...newParticipant,
                phone_number: e.target.value,
              };
              setNewParticipant({
                ...newParticipant,
                phone_number: e.target.value,
              });
              arrayOfParticipants.splice(i, 1, newParticipantSwap);
            }}
          />
          {newParticipant.phone_number?.length == 0 &&
            errors?.reservation_participants &&
            errors?.reservation_participants[i]?.phone_number && (
              <ErrorMessage
                name={`reservation_participants.${i}.phone_number`}
                component="span"
                style={{ color: "#d32f2f" }}
              />
            )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={5.5}
        sx={{
          paddingInlineEnd: "12px",
        }}
      >
        <Field /* Email */
          as={TextFieldInput}
          type="email"
          name={`reservation_participants.${i}.email`}
          placeholder={t("Login.email")}
          label={t("Login.email")}
          className="login-input"
          gridClass="grid-input"
          value={newParticipant.email}
          onChange={(e) => {
            const newParticipantSwap = {
              ...newParticipant,
              email: e.target.value,
            };
            setNewParticipant({ ...newParticipant, email: e.target.value });
            arrayOfParticipants.splice(i, 1, newParticipantSwap);
          }}
        />
        {newParticipant.email?.length == 0 &&
          touched?.reservation_participants &&
          touched?.reservation_participants[i]?.email &&
          errors?.reservation_participants &&
          errors?.reservation_participants[i]?.email && (
            <ErrorMessage
              name={`reservation_participants.${i}.email`}
              component="span"
              style={{ color: "#d32f2f" }}
            />
          )}
      </Grid>
      <Grid item xs={12} md={5.5}>
        <Field /* No. of Dives */
          as={TextFieldInput}
          type="number"
          name={`reservation_participants.${i}.no_of_dives`}
          placeholder={t("Edit_Trip.dive_number_optional")}
          label={t("Edit_Trip.dive_number_optional")}
          className="login-input"
          gridClass="grid-input"
          value={newParticipant.no_of_dives}
          onChange={(e) => {
            const newParticipantSwap = {
              ...newParticipant,
              no_of_dives: e.target.value,
            };
            setNewParticipant({
              ...newParticipant,
              no_of_dives: e.target.value,
            });
            arrayOfParticipants.splice(i, 1, newParticipantSwap);
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={5.5}
        sx={{
          paddingInlineEnd: "12px",
        }}
      >
        <Stack bgcolor="#f9f9f9" mt="24px" borderRadius="8px">
          <BasicDatePicker
            disableFuture={true}
            value={newParticipant.last_dive_date}
            className=" basic-date-picker edit-form"
            errorText={t("General.date_field_required")}
            handelChange={(newValue) => {
              setFieldValue(
                `reservation_participants.${i}.last_dive_date`,
                newValue
              );
              setNewParticipant({
                ...newParticipant,
                last_dive_date: newValue,
              });
              const array = arrayOfParticipants;
              array[i] = { ...array[i], last_dive_date: newValue };
              setArrayOfParticipants(array);
            }}
            setValue={() =>
              setFieldValue(
                `reservation_participants.${i}.last_dive_date`,
                null
              )
            }
            placeholder={t("Edit_Trip.dive_date_optional")}
            onClear={(_) => {
              const clearLastDate = newParticipant;
              clearLastDate.last_dive_date = null;

              setNewParticipant(() => ({ ...clearLastDate }));
              setFieldValue(
                `reservation_participants.${i}.last_dive_date`,
                null
              );

              setNewParticipant({
                ...clearLastDate,
              });

              const array = arrayOfParticipants;
              array[i] = clearLastDate;
              setArrayOfParticipants(array);
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={5.5}>
        <Field /* Certificates */
          as={TextFieldInput}
          name={`reservation_participants.${i}.certificates_list`}
          placeholder={t("Edit_Trip.certificates_optional")}
          className="login-input"
          gridClass="grid-input"
          label={t("Edit_Trip.certificates_optional")}
          value={newParticipant.certificates_list}
          onChange={(e) => {
            const newParticipantSwap = {
              ...newParticipant,
              certificates_list: e.target.value,
            };
            setNewParticipant({
              ...newParticipant,
              certificates_list: e.target.value,
            });
            arrayOfParticipants.splice(i, 1, newParticipantSwap);
          }}
        />
      </Grid>
      {open && (
        <DateModal
          t={t}
          open={open}
          handleClose={() => setOpen(false)}
          handelConfirm={() => setOpen(false)}
          date={values?.reservation_participants?.[i]?.last_dive_date}
          setDate={(value) =>
            setFieldValue(`reservation_participants.${i}.last_dive_date`, value)
          }
          disableFuture={true}
          openTo="day"
        />
      )}
    </Grid>
  );
}

export default EditForm;
