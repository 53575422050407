import axios from "axios"
import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getPresignedLinkAPI(files) {
    return await axiosFn("post", URL.PRESIGNED_URL, { "files": files })
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function uploadFileAPI(presigned_url, file) {
    const config = {
        header: {
            "Content-Type": file.type,
        }
    }
    return await axios.put(presigned_url, file, config)
        .then(res => {
            if (res) {
                return res
            }
        }).catch(handleError)
}
