import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import "./Select.css";

function SelectBox({
  items,
  title,
  value,
  handelOpen,
  id,
  name,
  error,
  helperText,
  onchange,
  label,
  style,
  disabled,
  className,
  defaultValue,
  IconComponent,
  onclick = () => {},
}) {

  return (
    <FormControl
      disabled={disabled}
      fullWidth
      style={style}
      error={error ? true : false}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        onOpen={handelOpen && handelOpen}
        id={id}
        name={name}
        className={className}
        displayEmpty
        defaultValue={defaultValue}
        inputProps={{ "aria-label": "Without label" }}
        value={value}
        onChange={onchange}
        label={label}
        IconComponent={IconComponent}
      >
        <MenuItem value="" disabled style={{ direction: "ltr" }}>
          {title}
        </MenuItem>
        {items?.map((item) => (
          <MenuItem
            value={item.value}
            key={item.id}
            onClick={() => onclick(item.id)}
            style={{ direction: "ltr" }}
          >
            {item.icon ? (
              <img
                style={{ margin: "0 10px" }}
                src={item.icon}
                alt="item icon"
              />
            ) : (
              ""
            )}{" "}
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
    </FormControl>
  );
}

export default SelectBox;
