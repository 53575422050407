import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function SignUpAPI(userType, data) {
    return await axiosFn("post", URL.AUTH + userType + URL.REGISTER, data)
        .then((res) => {
            return {
                res: res.data.data,
                err: null,
            };
        })
        .catch(handleError);
}
