import Types from "./Types";

const INIT_STATE = {
  alertModal: {
    isOpen: false,
    icon: null,
    message: null,
  },
};

export default function modalReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.TOGGLE_ALERT_MODAL: {
      const { isOpen, icon, message } = payload;
      return {
        ...state,
        alertModal: {
          isOpen,
          icon,
          message,
        },
      };
    }

    default: {
      return state;
    }
  }
}
