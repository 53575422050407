import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function FaqAPI(filter) {
    return await axiosFn("get", URL.FAQ + makeFilterString(filter))
        .then((res) => {
            return {
                res: res?.data,
                err: null
            }
        })
        .catch(handleError)
}
