import { useState } from "react";
import { Divider, Grid, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { icons } from "../../../../Assets/AssetHelper.js";
import AccountCard from "../../../Molecules/Profile/Settings/AccountCrad.jsx";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import ConfirmationModal from "../../Modals/Profile/ConfirmationModal.jsx";
import { withTranslation } from "react-i18next";
import { Firebase, signOutFirebase } from "../../../../Contexts/Firebase/Firebase.js";
import { async } from "q";
import Cookies from "js-cookie";

const SidePanel = ({ t, profileData, Logout, path, setIsAuth, BulkLogout }) => {
  let accounts = JSON.parse(localStorage.getItem("Accounts"));
  const navigate = useNavigate();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [type, setType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openHelp = Boolean(helpAnchorEl);

  const sideOptions = [
    {
      icon: icons.Profile,
      icon_orange: icons.ProfileOrange,
      text: t("Profile.view_profile"),
      handlePage: () => navigate("/profile/view-profile"),
      page: "/profile/view-profile",
    },
    {
      icon: icons.Points,
      icon_orange: icons.PointsOrange,
      text: t("Profile.points"),
      handlePage: () => navigate("/profile/points"),
      page: "/profile/points",
    },
    {
      icon: icons.Invite,
      icon_orange: icons.InviteOrange,
      text: t("Profile.invite_friends"),
      handlePage: () => navigate("/profile/invite-friends"),
      page: "/profile/invite-friends",
    },
    {
      icon: icons.Settings,
      icon_orange: icons.SettingsOrange,
      text: t("Profile.settings"),
      handlePage: () => navigate("/profile/settings"),
      page: "/profile/settings",
    },
    {
      icon: icons.Terms,
      icon_orange: icons.ProfileOrange,
      text: t("Profile.terms"),
      handlePage: () => navigate("/profile/terms"),
      page: "/profile/terms",
    },
    {
      icon: icons.Privacy,
      icon_orange: icons.PrivacyOrange,
      text: t("Profile.privacy"),
      handlePage: () => navigate("/profile/privacy"),
      page: "/profile/privacy",
    },
    {
      icon: icons.Help,
      icon_orange: icons.ProfileOrange,
      text: t("Profile.help"),
      handlePage: (e) => handleHelpClick(e),
      page: "/profile/help",
    },
    {
      icon: icons.Covid,
      icon_orange: icons.ProfileOrange,
      text: t("Profile.covid"),
      handlePage: () => navigate("/profile/covid"),
      page: "/profile/covid",
    },
  ];
  const menuOptions = [
    // {
    //   name: t("Profile.complains"),
    //   icon: icons.Complains,
    //   page: "/profile/complains",
    // },
    {
      name: t("Profile.support"),
      icon: icons.ProfileSupport,
      page: "/profile/support",
    },

    {
      name: t("Profile.report"),
      icon: icons.Report,
      page: "/profile/report",
    },
    {
      name: t("Profile.faq"),
      icon: icons.Faq,
      page: "/profile/faq",
    },
  ];

  /* navigate('/profile/help') */
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleHelpClick = (event) => setHelpAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);
  const handleHelpClose = () => setHelpAnchorEl(null);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleAccountChange = async (account) => {
    await Firebase.SignoutFirebase();
    localStorage.setItem("firebase_node_path", account.firebase_node_path);
    localStorage.setItem("firebase_auth_token", account.firebase_auth_token);
    localStorage.setItem("ActiveToken", account.token);
    localStorage.setItem("ActiveUserId", account.userId);
    localStorage.setItem("ActiveAvatar", account.avatar);
    localStorage.setItem("ActiveUserType", account.user_type);
    localStorage.setItem("ActiveEmail", account.email);
    localStorage.setItem("ISVERIFIED", account.is_verified);
    window.location.reload();
  };

  const logout = async () => {
    const id = parseInt(localStorage.getItem("ActiveUserId"));
    const newAccounts = accounts.filter((account) => account.userId !== id);
    await Firebase.SignoutFirebase();

    Logout();
    if (newAccounts.length > 0) {
      localStorage.setItem("Accounts", JSON.stringify(newAccounts));
      handleAccountChange(newAccounts[0]);
    } else {
      Cookies.remove("lastFirebaseRequestEpoch");
      localStorage.removeItem("firebase_auth_token");
      localStorage.clear();
      localStorage.setItem("USER_TYPE", "guest");
      localStorage.setItem("lang", "en");
      setIsAuth(false);
      navigate("/");
    }
  };

  const LogoutAll = async () => {
    const arr = [];
    accounts.forEach((account) => {
      arr.push(account.token);
    });
    BulkLogout({
      tokens: arr,
    });
    // accounts.forEach(account => {
    //     localStorage.setItem("ActiveToken", account.token)
    //     Logout()
    // })
    await Firebase.SignoutFirebase();
    localStorage.clear();
    localStorage.removeItem("firebase_auth_token");
    Cookies.remove("lastFirebaseRequestEpoch");
    localStorage.setItem("USER_TYPE", "guest");
    localStorage.setItem("lang", "en");
    setIsAuth(false);
    navigate("/");
  };

  return (
    <>
      <Grid sx={{ paddingInlineStart: "20px", paddingTop: "40px" }}>
        {sideOptions.map((item, i) => {
          return (
            <Grid
              container
              mb={5}
              key={i}
              id={item.page === "/profile/help" ? "basic-button" : ""}
              aria-controls={item.page === "/profile/help" && openHelp ? "basic-menu" : undefined}
              aria-haspopup={item.page === "/profile/help" ? "true" : ""}
              aria-expanded={item.page === "/profile/help" && openHelp ? "true" : undefined}
              onClick={item.handlePage}
              sx={{ cursor: "pointer" }}
            >
              <Grid item xs={2}>
                <img src={path === item.page ? item.icon_orange : item.icon} alt={item.text} />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    color: path === item.page ? "var(--star-color)" : "var(--font-color)",
                    fontSize: "var(--normal-font)",
                  }}
                >
                  {item.text}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Menu
          id="basic-menu"
          anchorEl={helpAnchorEl}
          open={openHelp}
          onClose={handleHelpClose}
          onClick={handleHelpClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
         
        >
          <Grid container sx={{ padding: "20px", maxWidth: "300px" }}>
            {menuOptions.map((option, i) => {
              return (
                <Grid
                  container
                  key={i}
                  onClick={() => navigate(option.page)}
                  sx={{ cursor: "pointer" }}
                  mb={i !== menuOptions.length - 1 ? 3 : 0}
                >
                  <Grid item xs={2}>
                    <img src={option.icon} style={{ width: "24px", height: "24px" }} alt="notifications" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#272726",
                        fontWeight: "bold",
                      }}
                    >
                      {option.name}
                    </Typography>
                    {option.page === "/profile/support" && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#f6b119",
                          fontWeight: "500",
                        }}
                      >
                        {t("Profile.send_complain")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Menu>
      </Grid>
      <Divider />
      <Grid my={2.5}>
        <AccountCard t={t} profileData={profileData} handleClick={handleClick} handleAccountChange={() => {}} />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          style: {
            width: 350,
          },
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 4px 8px rgba(0,0,0,0.15))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 64,
              height: 64,
              ml: -1.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: 0,
              right: 150,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {accounts?.map((account) => {
          return (
            <MenuItem key={account.userId}>
              <AccountCard
                t={t}
                profileData={account}
                type="menuItem"
                setType={setType}
                setOpenConfirm={setOpenConfirm}
                handleAccountChange={handleAccountChange}
              />
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem
          onClick={() => {
            navigate("/login");
          }}
        >
          <ListItemIcon>
            <img src={icons.AddAccount} alt="add account" />
          </ListItemIcon>
          <Typography
            sx={{
              color: "var(--text-color)",
              fontSize: "var(--normal-font)",
              fontWeight: "600",
            }}
          >
            {t("Profile.add_account")}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem>
          <PrimaryButton
            text={t("Profile.logout_all")}
            classBtn="points-btn"
            click={() => {
              setType("logout-all");
              setOpenConfirm(true);
            }}
          />
        </MenuItem>
      </Menu>
      <ConfirmationModal
        t={t}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        action={type === "logout-all" ? LogoutAll : logout}
        type={type}
      />
    </>
  );
};

export default withTranslation()(SidePanel);
