import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { icons } from "../../../../../Assets/AssetHelper";

const ChangePasswordSuccess = ({ t }) => {
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" justifyContent="center" my={12} gap={2}>
      <Box as="img" src={icons.ChangePasswordSuccess} />
      <Typography fontWeight="700" textAlign="center" fontSize="28px">
        {t("Profile.password_change_success")}
      </Typography>

      <PrimaryButton
        type="submit"
        text={t("Profile.done")}
        classBtn="profile-btn"
        style={{
          marginTop: "32px",
          backgroundColor: "#f6b119",
          color: "white",
        }}
        sx={{ width: { xs: "100%", md: "20%" } }}
        click={() => navigate("/profile/view-profile")}
      />
    </Stack>
  );
};

export default withTranslation()(ChangePasswordSuccess);
