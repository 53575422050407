import { Grid, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import FilterItem from "../../Molecules/Search/FilterItem.jsx";
import SearchBar from "../../Molecules/Search/SearchBar";
import { icons } from "../../../Assets/AssetHelper.js";
import BasicDatePicker from "../../Atoms/Input/DatePicker copy/DatePicker";
import MultiSelect from "../../Atoms/Select/MultiSelect";
import SearchableSelect from "../../Atoms/Select/SearchableSelect.jsx";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function SearchFilterSection({
  t,
  date,
  setDate,
  setOpen,
  filters,
  value,
  setValue,
  locationArray,
  locationValue,
  setLocationValue,
  locationInputValue,
  setLocationInputValue,
  from,
  setFrom,
  to,
  setTo,
  getActivityData,
  languages,
  onchange,
  languagesSelected,
  getLanguages,
  handleGetHotOffersCheck,
  isHotOffer,
  languagesCount,
  setLanguagesSelected,
  getDiveLocations,
  locationsCount,
}) {
  // console.log(locationInputValue, "locationInputValue");
  return (
    <>
      {/* General Search */}
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "19px",
        }}
      >
        {t("Search.search")}
      </Typography>
      <TextFieldInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="search"
        StartAdornment={
          <SearchIcon
            htmlColor="#f6b119"
            sx={{ width: "30px", height: "30px", marginTop: "10px" }}
          />
        }
        className="filter-search"
        placeholder={t("Search.activity_name")}
      />
      {/* Location Search */}
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "19px",
          paddingTop: "37px",
        }}
      >
        {t("Search.location")}
      </Typography>
      {/* <SearchBar
        className={"search-filter-input"}
        value={locationValue}
        setValue={setLocationValue}
        inputValue={locationInputValue}
        setInputValue={setLocationInputValue}
        items={locationArray}
        text={t("Search.location")}
        // getSearchKeywords={getSearchKeywords}
        // className='location-search'
        startIcon={
          <img
            src={icons.LocationOrange}
            style={{
              color: "#f6b119",
            }}
            alt="search by location"
          />
        }
        endIcon={
          <SearchIcon
            htmlColor="white"
            sx={{
              backgroundColor: "#f6b119",
              width: "44px",
              height: "40px",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          />
        }
        sx={{
          ".MuiInputBase-formControl": {
            paddingInlineEnd: "0px",
          },
          ".MuiOutlinedInput-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        }}
      /> */}
      <SearchableSelect
        name="location"
        options={locationArray}
        getList={getDiveLocations}
        handelMenuOpen={(_) => getDiveLocations({ page_number: 1 })}
        count={locationsCount}
        label={t("Search.location")}
        className="search-filter-input"
        onChange={(selectedOptions) => {
          const selectedValues = [
            {
              value: selectedOptions?.value,
              label: selectedOptions?.label,
            },
          ];
          setLocationInputValue(selectedValues[0]);
          setLocationValue(selectedOptions?.value);
        }}
        value={locationInputValue}
        onClear={() => {
          setLocationValue(null);
          setLocationInputValue(null);
        }}
        customOptions={(option) => {
          return (
            <Grid key={option?.id} container gap={1.5} alignItems={'center'}>
              {option?.label && (
                <Grid item xs={"search-input" ? 2 : 4}>
                  <img
                    src={
                      option.type === "city"
                        ? icons.SearchLocation
                        : option.type === "dive_center"
                        ? icons.SearchDiveCenter
                        : option.type === "freelancer"
                        ? icons.SearchDiveExpert
                        : icons.SearchLocation
                    }
                    style={{ marginInlineEnd: "12px", maxWidth: "100%" }}
                    alt="search result"
                  />
                </Grid>
              )}
              <Grid item xs={"search-input" ? 8.5 : 6.5}>
                <Typography
                  sx={{ fontSize: "19px", fontWeight: "500", color: "#272726" }}
                >
                  {option?.label}
                </Typography>
                <Typography sx={{ fontSize: "17px", color: "#aaaaaa" }}>
                  {option?.countryName}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
        dropdownIndicator={
          <SearchIcon
            htmlColor="white"
            sx={{
              backgroundColor: "#f6b119",
              width: "44px",
              height: "40px",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          />
        }
        StartIcon={
          <img
            src={icons.LocationOrange}
            style={{
              marginLeft: "10px"
            }}
            alt="search by location"
          />
        }
      />

      {/* Date Filter */}
      <BasicDatePicker
        placeholder={t("General.select_date")}
        disablePast={true}
        value={date}
        className="border-r-5px basic-date-picker mt-10px mb-20px search-filter-date"
        errorText={t("General.date_field_required")}
        handelChange={(newValue) => setDate(newValue)}
        setValue={() => setDate(null)}
      />
      {/* Multi Select Spoken Language */}
      {/* {console.log(languagesSelected, "languagesSelected")} */}
      <SearchableSelect
        name="Language"
        options={languages}
        getList={getLanguages}
        handelMenuOpen={(_) => getLanguages({ page_number: 1 })}
        count={languagesCount}
        label={t("Search.languages")}
        className="searchable-select"
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions?.map((option) => ({
            value: option?.value,
            label: option?.label,
          }));
          setLanguagesSelected(selectedValues);
        }}
        value={languagesSelected}
        onClear={() => {
          // setLangValue("");
          setLanguagesSelected(null);
        }}
        isMulti={true}
        closeMenuOnSelect={false}
        dropdownIndicator={<ArrowDropDownIcon fontSize="medium" sx={{ mr:"10px" }} />}
      />

      {/* Filter Items */}
      {filters.map((filter, i) => {
        return (
          <FilterItem
            key={i}
            items={filter.items}
            text={filter.text}
            handleCheckBoxChange={filter.handleCheckBoxChange}
          />
        );
      })}
      {/* Price filter */}
      <FilterItem
        t={t}
        type="price"
        text={t("Search.price")}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        getActivityData={getActivityData}
      />

      {/* Hot offers filter */}
      <FilterItem
        items={[{ id: 1, name: t("Search.hotOffers"), checked: isHotOffer }]}
        text={t("Search.special_options")}
        handleCheckBoxChange={handleGetHotOffersCheck}
        hideAllOpt={true}
        showItems={true}
      />
    </>
  );
}

export default SearchFilterSection;
