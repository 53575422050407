import { Autocomplete } from "@mui/material";

const AutocompleteInput = ({
  id,
  options,
  filterOptions,
  getOptionLabel,
  value,
  onChange,
  inputValue,
  onInputChange,
  renderOption,
  renderInput,
  handelClick,
}) => {
  return (
    <Autocomplete
      freeSolo
      fullWidth
      disableClearable
      id={id}
      options={options} // array
      filterOptions={filterOptions} // function filterOptions={(options) => options}  stop filtering
      getOptionLabel={getOptionLabel} // function
      value={value} // value selected by the user
      onChange={onChange} // function
      inputValue={inputValue} // value displayed in the textbox
      onInputChange={onInputChange} // function
      renderOption={renderOption} // function
      renderInput={renderInput} // function
      onClick={handelClick}
    />
  );
};

export default AutocompleteInput;
