import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import EditDiveTripTemplate from "../../../Templates/Main/Reservation/EditDiveTrip.Template.jsx";
import { AddEditCartItemAPI } from "../../../Contexts/APIs/Carts/AddEditCartItem";
import { getActivityDetailsAPI } from "../../../Contexts/APIs/Dive-Activities/ActivityDetails.js";
import dayjs from "dayjs";
import { RemainingSeatsAPI } from "../../../Contexts/APIs/Dive-Activities/RemainingSeats.js";
import Loader from "../../../Components/Atoms/Loader/Loader.js";

const EditDiveTripPage = ({ t, mapKey, getCart, cart, openLoader }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [card, setCard] = useState({});

  const [diveDetails, setDiveDetails] = useState({});
  const [cityId, setCityId] = useState();
  const [status, setStatus] = useState();
  const [isFromBooking, setIsFromBooking] = useState(false);
  const [statusDisplay, setStatusDisplay] = useState();
  const [payment, setPayment] = useState();
  const [orderId, setOrderId] = useState();
  const [remainingSeats, setRemainingSeats] = useState();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const addEditCart = async (data) => {
    const { res, err } = await AddEditCartItemAPI(data);
    if (res) navigate("/cart");
    if (err) toast.error(err);
  };
  const getActivityDetails = async (id) => {
    const { res, err } = await getActivityDetailsAPI(id);
    if (res) {
      setDiveDetails(res?.data?.activity);
      setCityId(res?.data?.activity?.diving_location?.city?.id);
    }
    if (err) toast.error(err);
  };
  useEffect(() => {
    const cardData = cart?.cart_items?.find(
      (data) => data?.id == location?.state?.cardId
    );
    setCard(cardData);
  }, [cart]);

  const getRemainingSeats = async (id, date, setOpen) => {
    const { res, err } = await RemainingSeatsAPI(id, {
      start_date: dayjs(date).format("DD/MM/YYYY"),
    });
    if (res) {
      setRemainingSeats(res?.data?.remaining_seats);
      setOpen && setOpen(false);
    }
    if (err) toast.error(err);
  };
  useEffect(() => {
    if (card?.purchasable?.dive_activity_id)
      getActivityDetails(card?.purchasable?.dive_activity_id);
  }, [card?.purchasable?.dive_activity_id]);

  useEffect(() => {
    if (card?.purchasable?.dive_activity_id)
      getActivityDetails(card?.purchasable?.dive_activity_id);
  }, [card?.purchasable?.dive_activity_id]);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {
    isEmpty(cart) && getCart();
  }, [cart]);
  return (
    <>
      <Loader open={openLoader} />
      <EditDiveTripTemplate
        t={t}
        card={card}
        addEditCart={addEditCart}
        mapKey={mapKey}
        cart={cart}
        getCart={getCart}
        diveDetails={diveDetails}
        getRemainingSeats={getRemainingSeats}
        remainingSeats={remainingSeats}
        status={status}
        statusDisplay={statusDisplay}
        payment={payment}
        isFromBooking={isFromBooking}
        orderId={orderId}
      />
    </>
  );
};

export default EditDiveTripPage;
