import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CheckExistsAPI } from "../../Contexts/APIs/Auth/Password/CheckExists";
import { ResetPasswordAPI } from "../../Contexts/APIs/Auth/Password/ResetPassword";
import { SendOtpAPI } from "../../Contexts/APIs/Auth/Password/SendOtp";
import { verifyOtpAPI } from "../../Contexts/APIs/Auth/Password/VerifyOtp";
import ForgetPasswordTemplate from "../../Templates/Auth/ForgetPassword.Template.jsx";

const ForgetPasswordPage = ({ t }) => {
  const [otp, setOtp] = useState(null);
  const [verify, setVerify] = useState(false);
  const [countdown, setCountdown] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const checkEmailExists = async (email) => {
    const { res, err } = await CheckExistsAPI(email);
    if (res) return res;
    if (err) toast.error(err);
  };

  const SendOtp = async (email, type) => {
    const { res, err } = await SendOtpAPI(
      {
        user: {
          email: email,
        },
      },
      type
    );
    if (res) toast.success("The code has been sent to your email");
    if (err) toast.error(err);
  };

  const verifyOtp = async (email, type) => {
    const { res, err } = await verifyOtpAPI(
      {
        verification_code: otp,
        user: {
          email: email,
        },
      },
      type
    );
    if (res) {
      localStorage.setItem("VERIFY", res.token);
      setVerify(true);
    }
    if (err) toast.error(err);
  };

  const resetPassword = async (data, type) => {
    const { res, err } = await ResetPasswordAPI(data, type);
    if (res) {
      toast.success(res.message);
      localStorage.removeItem("VERIFY");
      // localStorage.setItem("ActiveToken", res.extra.access_token)
      navigate("/login");
    }
    if (err) toast.error(err);
  };

  return (
    <ForgetPasswordTemplate
      t={t}
      checkEmailExists={checkEmailExists}
      resetPassword={resetPassword}
      verifyOtp={verifyOtp}
      verify={verify}
      setOtp={setOtp}
      countdown={countdown}
      setCountdown={setCountdown}
      SendOtp={SendOtp}
      setVerify={setVerify}
    />
  );
};

export default ForgetPasswordPage;
