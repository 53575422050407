import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import TextFieldInput from "../../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import ConfirmationModal from "../../../Modals/Profile/ConfirmationModal";

const DeleteAccount = ({ t, deleteProfile }) => {
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography
          sx={{
            fontSize: "var(--xlarge-font)",
            fontWeight: "800",
            color: "var(--font-color)",
            marginBottom: "14px",
          }}
        >
          {t("Profile.delete_account")}
        </Typography>
        <Typography
          display="inline"
          sx={{
            fontSize: "var(--medium-font)",
            fontWeight: "500",
            color: "#cb2127",
          }}
        >
          {t("Profile.attension")}
        </Typography>
        <Typography
          display="inline"
          sx={{
            fontSize: "var(--medium-font)",
            fontWeight: "500",
            color: "var(--font-color)",
          }}
        >
          {t("Profile.attension_text")}
        </Typography>
        <Typography
          sx={{
            fontSize: "var(--medium-font)",
            fontWeight: "900",
            color: "#181918",
            marginTop: "26px",
          }}
        >
          {t("Profile.please")}
        </Typography>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(t("validation.password"))
              .min(8, t("validation.password_short")),
          })}
          onSubmit={(values) => {
            setPassword(values.password);
            setOpen(true);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Field
                as={TextFieldInput}
                type="password"
                name="password"
                placeholder={t("Login.password")}
                label={t("Login.password")}
                className="login-input"
                gridClass="grid-input"
              />
              <ErrorMessage name="password" component="div" style={{ color: '#d32f2f' }}/>
              <PrimaryButton
                type="submit"
                // disabled={isSubmitting}
                text={t("Profile.delete_account")}
                classBtn="delete-red-btn"
                style={{ marginTop: "24px" }}
                click={submitForm}
              />
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <ConfirmationModal
        t={t}
        open={open}
        handleClose={handleClose}
        item={password}
        action={() =>
          deleteProfile({
            password: password,
          })
        }
        type="deleteAccount"
      />
    </Grid>
  );
};

export default DeleteAccount;
