import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { HashLink } from "react-router-hash-link";

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  const hashRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   pathname == "/" && hashRef.current.click();
  // }, [pathname]);
  return (
    <Box
      style={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {/* <HashLink
        ref={hashRef}
        // example of custom scroll function using the scroll prop
        scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
        to={"/#top"}
        style={{ textDecoration: "none" }}
      ></HashLink> */}
      {props.children}
    </Box>
  );
};

export default ScrollToTop;
