import { Grid, Typography } from "@mui/material"
import { useState } from "react"
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import DirectionModal from "../../Modals/Reservations/DirectionModal"
import LocationModal from "../../Modals/Reservations/LocationModal"

function LocationPanel({ t, location, center, setCenter, mapKey, pickupOption, userPosition, destination, address, setAddress }) {

    const [open, setOpen] = useState(false)
    const [openDirection, setOpenDirection] = useState(false)
    const handleClose = () => setOpen(false)
    const handleDirectionClose = () => setOpenDirection(false)
    // console.log(center)
    return (
        <Grid container mb={3} sx={{
            padding: '18px',
            borderRadius: '8px',
            border: 'solid 0.5px #979797',
            gap:1
        }}>
            <Grid item xs={12}>
                <Typography sx={{
                    color: "black",
                    fontSize: "var(--large-font)",
                    fontWeight: "bold"
                }}>
                    {t("Edit_Trip.pickup")}
                </Typography>
            </Grid>
            <Grid container item xs={12} md={8} alignContent='center'>
                <Typography sx={{
                    color: "var(--font-color)",
                    fontSize: "var(--text-font)"
                }}>
                    {pickupOption === 'hotel_pickup' ? address : location?.full_address}
                </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <PrimaryButton
                    text={pickupOption === 'hotel_pickup' ?
                        t("Edit_Trip.change") :
                        t("Edit_Trip.directions")}
                    classBtn='points-btn'
                    style={{height:'100%'}}
                    click={() => pickupOption === 'hotel_pickup' ? setOpen(true) : setOpenDirection(true)}
                />
            </Grid>
            <LocationModal
                t={t}
                open={open}
                handleClose={handleClose}
                mapKey={mapKey}
                center={center}
                setCenter={setCenter}
                setAddress={setAddress}
            />
            <DirectionModal
                t={t}
                open={openDirection}
                handleClose={handleDirectionClose}
                mapKey={mapKey}
                userPosition={userPosition}
                destination={destination}
            />
        </Grid>
    )
}

export default LocationPanel