import { Divider, ListItemAvatar, ListItemButton, ListItemText,Avatar,Typography, Stack, Grid } from '@mui/material'
import React from 'react'
import { icons, images } from '../../Assets/AssetHelper'
import { withTranslation } from 'react-i18next'

const AccountTypeTemplate = ({t, selectedIndex , handleListItemClick}) => {
    const types = [
    {   title:t("Signup.customer"),
        value:'customer',
        avatar:icons?.CustomerIcon
    },
        {title:t("Signup.dive_club"),
        value:'dive_club',
        avatar:icons.DiveClubIcon
    }
    ]
  return (
    <Grid container>
    <Grid item md={6} sx={{ display: { xs: 'none', md: 'inherit' } }}>
        <img
            src={images.LoginBg}
            alt='Login'
            width='100%'
            height='100%'
        />
    </Grid>
    <Stack width={{xs:'100%' , md:'50%'}} alignItems='center' justifyContent='center' gap={2} my={6} height='350px'>

        <Typography color='#272726' fontSize='24px' fontWeight='bold'>{t("Signup.choose_account_type")}</Typography>
{
     types?.map((account, i) => {
        return (
            <React.Fragment key={i}>
                <ListItemButton
                sx={{
                    border:'1px solid #f6b119',
                    borderRadius:'10px',
                    width:{xs:'90%' , md:'70%'},
                    height:'100px'
                }}
                    alignItems="flex-start"
                    selected={selectedIndex === i}
                    onClick={(event) => handleListItemClick(event, i , account)}
                >
              
                    <ListItemText
                        primary={
                            <Typography sx={{
                                fontSize: '18px',
                                fontWeight: 'bold'
                            }}
                            >
                                {`${account.title}`}
                            </Typography>
                        }
           />

<ListItemAvatar>
                        <Avatar
                            alt="Remy Sharp"
                            src={account?.avatar}
                            sx={{ width: '100px', height: '100px', marginInlineEnd: '14px' , borderRadius:0 }}
                        />
                    </ListItemAvatar>
                </ListItemButton>
              
            </React.Fragment>
        )
    })
}
    </Stack>
    </Grid>

  )
}

export default withTranslation()(AccountTypeTemplate)