import { Grid } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Firebase,
  SignInFirebase,
} from "../../../../Contexts/Firebase/Firebase";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import PassowordCriteria from "../../../Atoms/SharedComponents/PassowordCriteria.jsx";

export function NewPasswordInput({ t, searchParams, resetPassword }) {
  const fcm_token = localStorage.getItem("fcm_token");
  const userAgent = window.navigator.userAgent;
  return (
    <Grid container item xs={12} justifyContent="center" mb={2}>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
          .trim()
          .required(t("validation.password"))
          .min(8, t("validation.password_digits")) // Minimum 8 characters
          .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
          .matches(/[0-9]/, t("validation.password_numbers")),
          confirmPassword: Yup.string().test(
            t("validation.match"),
            t("validation.must_match"),
            function (value) {
              return this.parent.password === value;
            }
          ),
        })}
        onSubmit={async (values) => {
          const email = window.location.href.split("email=")?.[1]?.split("&");

          const data = {
            user: {
              email: email[0],
              password: values.password,
              password_confirmation: values.confirmPassword,
            },
            device: {
              uuid: "dummy",
              device_type: "web",
              fcm_token: fcm_token ? fcm_token : userAgent,
            },
          };
          await resetPassword(data, searchParams.get("user_type"));
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              as={TextFieldInput}
              type="password"
              name="password"
              placeholder={t("Login.password")}
              label={t("Login.password")}
              className="login-input"
              gridClass="grid-input"
              sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
            />
            <PassowordCriteria />
            <ErrorMessage
              name="password"
              component="div"
              style={{ color: "#d32f2f" }}
            />
            <Field
              as={TextFieldInput}
              type="password"
              name="confirmPassword"
              placeholder={t("Signup.confirm_password")}
              label={t("Signup.confirm_password")}
              className="login-input"
              gridClass="grid-input"
              clickEnter={submitForm}
              sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              style={{ color: "#d32f2f" }}
            />
            <Grid container justifyContent="center" mt={{ xs: 10, md: 4 }}>
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                text={t("ForgetPassword.change_password")}
                classBtn="orange"
                // style={{
                //     marginTop: '39px'
                // }}
                click={submitForm}
                sx={{
                  fontSize: {
                    xs: "20px",
                    md: "24px",
                  },
                  borderRadius: {
                    xs: "100px",
                    md: "10px",
                  },
                  height: {
                    xs: "60px",
                    md: "72px",
                  },
                  width: {
                    xs: "340px",
                    md: "400px",
                  },
                }}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
