import {
  Avatar,
  Chip,
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";

import { icons } from "../../../../../Assets/AssetHelper";
// import SidePanelDrawer from "../../../../Organisms/Profile/ActionSidePanel/SidePanelDrawer";
import { useEffect, useState } from "react";

function PointsCard({ t, profileData, type, navbarMobile }) {
  const [progress, setProgress] = useState(0);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: navbarMobile ? 6 : 8,
    borderRadius: 3.6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        type === "modal"
          ? `rgba(239, 200, 92, 0.38)`
          : `rgba(255, 255, 255, 0.38)`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3.6,
      backgroundColor: type === "modal" ? "#ebbf55" : "white",
    },
  }));
  const badgelist = {
    Bronze: {
      icon: icons.BronzeIcon,
      color: "#bf914d",
      title: t("Profile.bronze_member"),
    },
    Silver: {
      icon: icons.SilverIcon,
      color: "#b5b5b5",
      title: t("Profile.silver_member"),
    },
    Platinum: {
      icon: icons.PlatinumIcon,
      color: "#c4deec",
      title: t("Profile.platinum_member"),
    },
    Gold: {
      icon: icons.GoldIcon,
      color: "#ebbf55",
      title: t("Profile.gold_member"),
    },
    modal: {
      icon: icons.GoldWhite,
      color: "white",
      title: t("Profile.bronze_member"),
    },
  };

  useEffect(() => {
    profileData
      ? setProgress(
          profileData?.level_advancement_tracker?.progress_till_next_level
            ?.percentage
        )
      : setProgress(0);
  }, [profileData]);

  return (
    <Grid container pr={1}>
      <Grid container item xs={2} alignContent="center">
        <Avatar
          src={profileData?.avatar}
          alt="avatar"
          sx={{
            width: { xs: "56px", md: "64px" },
            height: { xs: "56px", md: "64px" },
            border: "2px solid #fff",
          }}
        />
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          paddingInlineStart: navbarMobile ? "20px" : "10px",
        }}
      >
        <Grid container mb={0.5}>
          <Grid item xs={6} md={7}>
            <Typography
              sx={{
                fontSize: `${navbarMobile ? "16px" : "var(--large-font)"}`,
                fontWeight: "bold",
                color: type === "modal" ? "var(--font-color)" : "white",
                textTransform: "capitalize",
              }}
            >
              {profileData?.firstname} {profileData?.lastname}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={5}
            justifyContent="end"
            alignContent="center"
          >
            <Chip
              icon={<img src={badgelist[type]?.icon} alt="points" />}
              label={badgelist[type]?.title}
              sx={{
                bgcolor: type === "modal" ? "#ebbf55" : "white",
                color: badgelist[type]?.color,
                padding: "4px",
                fontSize: { xs: "10px", md: "12px" },
                height: "18px",
              }}
              size="small"
            />
          </Grid>
          {/* <Grid item xs={2} sx={{ display: { xs: "flex", md: "none" } }}>
            <SidePanelDrawer />
          </Grid> */}
        </Grid>
        {/*<BorderLinearProgress
          variant="determinate"
          // value={(profileData?.points?.total_points / profileData?.points?.level_points) * 100}
          // value={
          //   profileData?.level_advancement_tracker?.progress_till_next_level
          //     ?.percentage
          // }
        />*/}
        {progress > 0 ? (
          <BorderLinearProgress variant="determinate" value={progress} />
        ) : (
          <BorderLinearProgress variant="determinate" value={0} />
        )}
        {/* {console.log(profileData?.level_advancement_tracker?.progress_till_next_level?.percentage)} */}
        <Grid container mt={0.5}>
          <Grid container item xs={8} alignContent="center">
            <Typography
              sx={{
                fontSize: `${navbarMobile ? "10px" : "var(--small-font)"}`,
                fontWeight: "bold",
                color: type === "modal" ? "var(--font-color)" : "white",
              }}
            >
              {type == "Platinum"
                ? t("Profile.platinum_message")
                : ` 
                 ${
                  profileData?.level_advancement_tracker
                    ?.reservations_left_till_next_level
                }
                ${t("HomePage.points")} 
                
                  ${
                    profileData?.level_advancement_tracker?.next_level?.name
                  } ${t("HomePage.member")}`}{" "}
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="end">
            <Typography
              display="inline"
              component={"span"}
              sx={{
                fontSize: `${navbarMobile ? "18px" : "var(--large-font)"}`,
                fontWeight: "800",
                color: type === "modal" ? "var(--font-color)" : "white",
              }}
            >
              {profileData?.level_advancement_tracker?.no_of_reservations}&nbsp;
            </Typography>
            <Typography
              display="inline"
              alignSelf={"center"}
              component={"span"}
              sx={{
                fontSize: `${navbarMobile ? "18px" : "var(--small-font)"}`,
                fontWeight: "800",
                color: type === "modal" ? "var(--font-color)" : "white",
              }}
            >
              {`/ `}
              {
                profileData?.level_advancement_tracker?.next_level
                  ?.reservations_required
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PointsCard;
