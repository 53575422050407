import { Box, Skeleton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { icons } from '../../../../Assets/AssetHelper.js'

export default function CarouselResponsive({ item }) {

    const navigate = useNavigate()

    return (
        <Box
            // style={{ margin: '0 10px', cursor: 'pointer' }}
            // onClick={() => navigate('/search/dive_trip', { state: { city: item } })}
        >
            {item?.url ?
                <img src={item?.url} draggable={false} alt={item.text} style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    // borderRadius: '10px',
                    objectFit: 'cover'
                }} /> :
                <Skeleton
                    variant="rectangular"
                    width='100%'
                    height={400}
                    sx={{ position: 'relative', bgcolor: 'grey', borderRadius: '10px' }}
                    animation={false}
                />
            }
            {/* <Typography sx={{ fontSize: '28px', fontWeight: 'bold' }} color='white' style={{
                position: 'absolute',
                bottom: '50px',
                left: '20px'
            }}>
                {item?.name}
            </Typography>
            <img src={icons.Location} alt="Location" style={{
                position: 'absolute',
                bottom: '30px',
                left: '20px'
            }} />
            <Typography sx={{ fontSize: '24px', fontWeight: '500', lineHeight: '1.33' }} color='white' style={{
                position: 'absolute',
                bottom: '22px',
                left: '50px'
            }}>
                {item?.city?.country?.name}
            </Typography> */}
        </Box>
    )
}