import { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";

import DetailsViewTemplate from "../../../Templates/Main/Details/DetailsView.Template.jsx";
import { getActivityDetailsAPI } from "../../../Contexts/APIs/Dive-Activities/ActivityDetails.js";
import { ListDiveActivitiesAPI } from "../../../Contexts/APIs/Dive-Activities/ListDiveActivities.js";
import { RemainingSeatsAPI } from "../../../Contexts/APIs/Dive-Activities/RemainingSeats.js";
import { AddEditCartItemAPI } from "../../../Contexts/APIs/Carts/AddEditCartItem";
import { DeleteOrderAPI } from "../../../Contexts/APIs/Orders/DeleteOrder.js";
import dayjs from "dayjs";
import { PayOrderAPI } from "../../../Contexts/APIs/Orders/PayOrder.js";
import { isEmptyObject } from "../../../Contexts/APIs/Helper/Helper.js";
import { ReviewCreateAPI } from "../../../Contexts/APIs/Reviews/CreateReview.js";
import { OrderDetailsAPI } from "../../../Contexts/APIs/Orders/Orders.js";
import { Grid, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper.js";

const DetailsView = ({ t, getFavorites, mapKey, cart }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [diveDetails, setDiveDetails] = useState({});
  const [cityId, setCityId] = useState();
  const [isFromBooking, setIsFromBooking] = useState(false);
  const [activitiesList, setActivitiesList] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [orderId, setOrderId] = useState();
  const [quantity, setQuantity] = useState();
  const [qrCode, setQrCode] = useState();
  const [status, setStatus] = useState();
  const [statusDisplay, setStatusDisplay] = useState();
  const [remainingSeats, setRemainingSeats] = useState();
  const [payment, setPayment] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [paymentLookupkey, setPaymentLookupkey] = useState();
  const [orderItemId, setOrderItemId] = useState();
  const [cancellationPolicyMsg, setCancellationPolicyMsg] = useState();
  const pageNumber = searchParams.get("page_number");
  const [isInvalidParameters, setIsInvalidParameter] = useState(false);
  const [center, setCenter] = useState({
    lat: 27.2579,
    lng: 33.8116,
  });

  useEffect(() => {
    const handleBackButton = (event) => {
      navigate(
        `/search/${searchParams.get("activity_type")}?page_number=${pageNumber}`
      );
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const getActivityDetails = async () => {
    if (location?.state?.origin === "orders") {
      const { res, err } = await OrderDetailsAPI(id);
      if (res) {
        setDiveDetails({
          ...res?.data?.order?.order_item?.purchasable?.dive_activity,
        });
        setCityId(res?.data?.order?.order_item?.pickup_location?.city_id);

        setOrderData(res?.data?.order);
      }
      if (err) toast.error(err);
    } else {
      const { res, err, statusCode } = await getActivityDetailsAPI(id);
      if (res) {
        setDiveDetails(res?.data?.activity);
        setCityId(res?.data?.activity?.diving_location?.city_id);
      }
      if (statusCode === 417) {
        setIsInvalidParameter(true)
        return
      }
      if (err) toast.error(err);
    }
  };
  const listDiveActivities = async () => {
    const filter_obj = {
      city_id: cityId,
      area_id: diveDetails?.diving_location?.area_id,
    };

    const { res, err } = await ListDiveActivitiesAPI(filter_obj);
    if (res) {
      setActivitiesList(res?.data?.activities);
    }
    if (err) toast.error(err);
  };

  const addEditCart = async (data) => {
    const { res, err } = await AddEditCartItemAPI(data);
    if (res) navigate("/cart");
    if (err) toast.error(err);
  };

  const deleteOrder = async (id) => {
    const { res, err } = await DeleteOrderAPI(id, {
      payment_transaction: {
        status: "cancelled",
      },
    });
    if (res) navigate("/orders");
    if (err) toast.error(err);
  };

  const getRemainingSeats = async (id, date, setOpen) => {
    const { res, err } = await RemainingSeatsAPI(id, {
      start_date: dayjs(date).format("DD/MM/YYYY"),
    });
    if (res) {
      setRemainingSeats(res?.data?.remaining_seats);
      setOpen && setOpen(false);
    }
    if (err) toast.error(err);
  };

  const payOrder = async (id) => {
    const { res, err } = await PayOrderAPI(id);
    if (res) {
      if (isEmptyObject(res?.data?.payment_session)) {
        navigate("/orders");
      } else {
        window.location.replace(res?.data?.payment_session?.payment_url);
      }
    }
    if (err) toast.error(err);
  };

  const createReview = async (orderId, itemId, data) => {
    // console.log('createReview')
    const { res, err } = await ReviewCreateAPI(orderId, itemId, data);
    if (res) {
      return res;
    }
    if (err) toast.error(err);
  };

  useEffect(() => {
    if (location?.state?.origin === "orders") {
      setIsFromBooking(true);
      setOrderId(orderData?.id);
      setQuantity(orderData?.quantity);
      setQrCode(orderData?.qr_code);
      setStatus(orderData?.status);
      setStatusDisplay(orderData?.status_display);
      setCenter({
        lat: location?.state?.lat,
        lng: location?.state?.long,
      });
      setPayment(orderData?.payment_method?.name);
      setStartDate(location?.state?.startDate);
      setEndDate(location?.state?.endDate);
      setStartTime(location?.state?.startTime);
      setPaymentLookupkey(location?.state?.paymentLookupkey);
      setOrderItemId(location?.state?.orderItemId);
      setCancellationPolicyMsg(location?.state?.cancellationPolicyMsg);
    }
  }, [orderData]);

  useEffect(() => {
    getActivityDetails();
  }, [isFromBooking]);

  useEffect(() => {
    if (cityId) listDiveActivities();
  }, [cityId]);

  return (
    <>
      {isInvalidParameters ? (
        <Grid container alignContent="center">
          <Grid container item xs={12} justifyContent="center" mt={10} mb={6}>
            <img src={icons.Empty} alt="empty" />
          </Grid>
          <Grid container item xs={12} justifyContent="center" mb={5}>
            <Typography
              sx={{
                fontSize: "21.6px",
                fontWeight: "500",
                color: "#aaa",
              }}
            >
              {t("General.no_item")} {id}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <DetailsViewTemplate
          t={t}
          diveDetails={diveDetails}
          activitiesList={activitiesList}
          addEditCart={addEditCart}
          getFavorites={getFavorites}
          mapKey={mapKey}
          deleteOrder={deleteOrder}
          isFromBooking={isFromBooking}
          orderId={orderId}
          quantity={quantity}
          qrCode={qrCode}
          status={status}
          statusDisplay={statusDisplay}
          center={center}
          setCenter={setCenter}
          getRemainingSeats={getRemainingSeats}
          remainingSeats={remainingSeats}
          payment={payment}
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          paymentLookupkey={paymentLookupkey}
          orderItemId={orderItemId}
          cancellationPolicyMsg={cancellationPolicyMsg}
          payOrder={payOrder}
          createReview={createReview}
          orderData={orderData}
          cart={cart}
        />
      )}
    </>
  );
};

export default DetailsView;
