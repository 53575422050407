import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Avatar,
  Badge,
  Menu,
  MenuItem,
  Button,
  Stack,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Drawer,
  ListItemText,
  Box,
  Select,
} from "@mui/material";
import {
  NavLink,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { toast } from "react-toastify";
import { images, icons } from "../../../Assets/AssetHelper.js";
import {
  NotificationsListAPI,
  NotificationsCountAPI,
} from "../../../Contexts/APIs/Notifications/Noftifications.js";
import "./Layouts.css";
import PointsCard from "../../Molecules/Profile/ViewProfile/Cards/Pointscard.jsx";
import moment from "moment";
import NotificationsListing from "../Notifications/NotificationsListing.jsx";
import AccountActions from "./Components/AccountActions.jsx";
import {
  Firebase,
  signOutFirebase,
} from "../../../Contexts/Firebase/Firebase.js";
import Cookies from "js-cookie";
import Language from "./Language.jsx";

function ListItemMolecule(props) {
  return (
    <ListItem disablePadding onClick={props.item.action}>
      <ListItemButton>
        <ListItemIcon>{<img src={props.item.icon} />}</ListItemIcon>
        <ListItemText primary={props.item.text} className="ListItemText" />
        {props.item.count !== 0 && (
          <Badge
            badgeContent={props.item.count}
            color="primary"
            overlap="circular"
            sx={{ mr: 3 }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
}

const Navbar = ({
  t,
  cart,
  orders,
  favorites,
  ordersCount,
  cartCount,
  languages,
  Logout,
  setIsAuth,
  profileData,
  notificationsCount,
  favoritesCount,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number");
  const activityType = searchParams.get("activity_type");
  const groupOne = [
    {
      text: t("Navbar.dive_trips"),
      icon: icons.DiveTripResponsive,
      action: () => navigate("/search/dive_trip"),
    },
    {
      text: t("Navbar.courses"),
      icon: icons.CoursesResponsive,
      action: () => navigate("/search/dive_course"),
    },
    {
      text: t("Navbar.liveboards"),
      icon: icons.LiveaboardResponsive,
      action: () => navigate("/search/live_aboard"),
    },
    {
      text: t("Navbar.dive_center"),
      icon: icons.DiveCenterResponsive,
      action: () => navigate("/dive-centers"),
    },
    {
      text: t("Navbar.dive_expert"),
      icon: icons.DiveExpertResponsive,
      action: () => navigate("/dive-experts"),
    },
    {
      text: t("Navbar.blogs"),
      icon: icons.blogs,
      action: () => {
        window.open("https://blog.scubers.com/", "_blank");
      },
    },
  ];

  const groupTwo = [
    {
      text: t("Navbar.favourites"),
      icon: icons.FavoritesResponsive,
      count: favoritesCount,
      action: () => navigate("/favorites"),
    },
    {
      text: t("Booking.booking"),
      icon: icons.Complains,
      count: ordersCount,
      action: () => navigate("/orders"),
    },
    {
      text: t("Cart.cart"),
      icon: icons.CartResponsive,
      count: cartCount,
      action: () => navigate("/cart"),
    },
    {
      text: t("Navbar.notifications"),
      icon: icons.Notification,
      count: notificationsCount,
      action: () => navigate("/notifications"),
    },
    // {
    //   text: t("Profile.view_profile"),
    //   icon: icons.Profile,
    //   action: () => navigate("/profile/view-profile"),
    // },
    // {
    //   text: t("Profile.invite_friends"),
    //   icon: icons.Invite,
    //   action: () => navigate("/profile/invite-friends"),
    // },
  ];

  const groupThree = [
    {
      text: t("Profile.points"),
      icon: icons.Points,
      action: () => navigate("/profile/points"),
    },
    {
      text: t("Profile.view_profile"),
      icon: icons.Profile,
      action: () => navigate("/profile/view-profile"),
    },
    {
      text: t("Profile.invite_friends"),
      icon: icons.Invite,
      action: () => navigate("/profile/invite-friends"),
    },
  ];

  const groupFour = [
    {
      text: t("Profile.terms"),
      icon: icons.Terms,
      action: () => navigate("/profile/terms"),
    },
    {
      text: t("Profile.privacy"),
      icon: icons.Privacy,
      action: () => navigate("/profile/privacy"),
    },
    {
      text: t("Profile.help"),
      icon: icons.Help,
    },
    {
      text: t("Profile.covid"),
      icon: icons.Covid,
      action: () => navigate("/profile/covid"),
    },
    {
      text: t("Profile.faq"),
      icon: icons.Faq,
      action: () => navigate("/profile/faq"),
    },
  ];

  const groupFive = [
    {
      text: t("Navbar.switch"),
      icon: icons.Switch,
      action: () => navigate("/switch-account"),
    },
    {
      text: t("Profile.settings"),
      icon: icons.Settings,
      action: () => navigate("/profile/settings"),
    },
    {
      text: t("Profile.logout"),
      icon: icons.Logout,
      action: () => logout(),
    },
  ];

  const guestDrawer = (
    <Box onClick={handleDrawerToggle}>
      <Grid
        container
        alignContent="center"
        onClick={() => navigate("/login")}
        sx={{ cursor: "pointer", marginY: "24px" }}
      >
        <img
          src={icons.UserIconResponsive}
          alt="User Icon"
          style={{ marginInlineEnd: "10px", marginInlineStart: "24px" }}
        />
        <Typography variant="body2" display="inline" color="#f6b119">
          {t("Navbar.sign_in")}
          {" / "}
        </Typography>
        <Typography variant="body2" display="inline" color="#f6b119">
          {t("Navbar.register")}
        </Typography>
      </Grid>
      <Divider />
      <List>
        {groupOne.map((item, index) => (
          <ListItemMolecule key={index} item={item} />
        ))}
      </List>
    </Box>
  );

  const userDrawer = (
    <Box onClick={handleDrawerToggle}>
      <Grid
        sx={{
          padding: "6px",
          backgroundImage:
            profileData?.level?.rank === 3
              ? "linear-gradient(to left, #f3d163 123%, #dfa440 0%)"
              : profileData?.level?.rank === 4
              ? "linear-gradient(to left, #c2e0f0 123%, #9ac5db 0%)"
              : profileData?.level?.rank === 2
              ? "linear-gradient(to left, #c8c8c8 121%, #8a8a8a 0%)"
              : "linear-gradient(to left, #dbb06e 121%, #804a00 0%)",
        }}
      >
        <PointsCard
          t={t}
          profileData={profileData}
          type={profileData?.level?.name}
          navbarMobile={true}
        />
      </Grid>
      <Divider />
      <List>
        {groupOne.map((item, index) => (
          <ListItemMolecule key={index} item={item} />
        ))}
      </List>
      <Divider />
      <List>
        {groupTwo.map((item, index) => (
          <ListItemMolecule key={index} item={item} />
        ))}
      </List>
      <Divider />
      <List>
        {groupThree.map((item, index) => (
          <ListItemMolecule key={index} item={item} />
        ))}
      </List>
      <Divider />
      <List>
        {groupFour.map((item, index) => (
          <ListItemMolecule key={index} item={item} />
        ))}
      </List>
      <Divider />
      <List>
        {groupFive.map((item, index) => (
          <ListItemMolecule key={index} item={item} />
        ))}
      </List>
    </Box>
  );
  const actions = () => {
    return {
      order: "/orders",
      signup_points: `/profile/points`,
      invitation: `/profile/points`,
      order_points: `/profile/points`,
      levels: "/profile/view-profile",
      invitation_points: "/profile/points",
    };
  };
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let accounts = JSON.parse(localStorage.getItem("Accounts"));

  const [ordersArray, setOrdersArray] = useState();
  const [notificationsList, setNotificationsList] = useState([]);

  const [notifCount, setNotifCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const drawerWidth = "80%";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    (notificationsList?.length === 0 || notificationsCount !== 0) && getList(1);
  };

  const handleClose = (data) => {
    setAnchorEl(null);
    if (data?.need_action)
      navigate(actions(data?.data_id)[data?.notification_type]);
  };

  // const getCount = async () => {
  //   const { res, err } = await NotificationsCountAPI();
  //   if (res) setNotificationsCount(res?.data?.notifications?.unseen);
  //   if (err) toast.error(err);
  // };

  const getList = async (page_number) => {
    page_number = page_number ?? Math?.ceil(notificationsList?.length / 10 + 1);
    const { res, err } = await NotificationsListAPI({
      page_number,
    });
    if (res) {
      setNotificationsList([...notificationsList, ...res?.data?.notifications]);
      setNotifCount(res?.extra?.total_count);
    }
    if (err) toast.error(err);
  };

  const handleAccountChange = async (account) => {
    await Firebase.SignoutFirebase();
    localStorage.setItem("firebase_node_path", account.firebase_node_path);
    localStorage.setItem("firebase_auth_token", account.firebase_auth_token);
    localStorage.setItem("ActiveToken", account.token);
    localStorage.setItem("ActiveUserId", account.userId);
    localStorage.setItem("ActiveAvatar", account.avatar);
    localStorage.setItem("ActiveUserType", account.user_type);
    localStorage.setItem("ActiveEmail", account.email);
    localStorage.setItem("ISVERIFIED", account.is_verified);
    window.location.reload();
  };

  const logout = async () => {
    const id = parseInt(localStorage.getItem("ActiveUserId"));
    const newAccounts = accounts.filter((account) => account.userId !== id);
    Logout();
    if (newAccounts.length > 0) {
      localStorage.setItem("Accounts", JSON.stringify(newAccounts));
      handleAccountChange(accounts[0]);
    } else {
      localStorage.clear();
      localStorage.removeItem("firebase_auth_token");
      Cookies.remove("lastFirebaseRequestEpoch");
      localStorage.setItem("USER_TYPE", "guest");
      localStorage.setItem("lang", "en");
      await Firebase.SignoutFirebase();
      setIsAuth(false);
      navigate("/");
    }
  };

  useEffect(() => {
    const array = [];
    orders.forEach((order) => {
      if (order.status !== "cancelled") array.push({});
    });
    setOrdersArray(array);
  }, [orders]);

  return (
    <Grid
      container
      bgcolor="black"
      sx={{
        height: "auto",
        padding: { xs: "5px 10px", md: "20px 90px" },
      }}
    >
      <Grid container item xs={3}>
        <Box
          alignContent={"center"}
        sx={{
        display: {
          xs: "none",
          md: "block",
        },
          }}
        >
          <img
            src={images.Logo}
            alt="logo"
            onClick={() => navigate("/")}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            ml: 1,
            display: { md: "none" },
            backgroundColor: "#424141",
            borderRadius: "4px",
          }}
        >
          <MenuIcon sx={{ color: "white" }} />
        </IconButton>
        {/* <Box
                    component="nav"
                    sx={{ width: drawerWidth, flexShrink: { md: 0 } }}
                    aria-label="mailbox folders"
                > */}
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          // ModalProps={{
          //     keepMounted: true, // Better open performance on mobile.
          // }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {localStorage.getItem("USER_TYPE") === "guest"
            ? guestDrawer
            : userDrawer}
        </Drawer>
        {/* </Box> */}
      </Grid>
      <Grid
        container
        item
        xs={6}
        justifyContent="center"
        alignContent="center"
        sx={{
          display: { md: "none" },
        }}
      >
        <img
          src={images.Logo}
          alt="logo"
          onClick={() => navigate("/")}
          style={{
            cursor: "pointer",
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={6}
        justifyContent="space-around"
        alignContent="center"
        columnSpacing={3}
        sx={{
          display: {
            xs: "none",
            md: "inline-flex",
          },
          flexWrap: "wrap"
        }}
      >
        <NavLink
          to={`/search/dive_trip?page_number=${
            activityType == "dive_trip" ? pageNumber : 1
          }`}
          className={
            path === "/search/dive_trip" ? "selected-nav-link" : "nav-link"
          }
        >
          {t("Navbar.dive_trips")}
        </NavLink>
        <NavLink
          to={`/search/dive_course?page_number=${
            activityType == "dive_course" ? pageNumber : 1
          }`}
          className={
            path === "/search/dive_course" ? "selected-nav-link" : "nav-link"
          }
        >
          {t("Navbar.courses")}
        </NavLink>

        <NavLink
          to={`/search/live_aboard?page_number=${
            activityType == "live_aboard" ? pageNumber : 1
          }`}
          className={
            path === "/search/live_aboard" ? "selected-nav-link" : "nav-link"
          }
        >
          {t("Navbar.liveboards")}
        </NavLink>
        <NavLink
          to="/dive-centers"
          className={
            path === "/dive-centers" ? "selected-nav-link" : "nav-link"
          }
        >
          {t("Navbar.dive_center")}
        </NavLink>
        <NavLink
          to="/dive-experts"
          className={
            path === "/dive-experts" ? "selected-nav-link" : "nav-link"
          }
        >
          {t("Navbar.dive_expert")}
        </NavLink>
        <NavLink
          to="https://blog.scubers.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-link"
        >
          {t("Navbar.blogs")}
        </NavLink>
      </Grid>
      {localStorage.getItem("USER_TYPE") === "guest" ? (
        <Grid
          container
          item
          xs={3}
          justifyContent="flex-end"
          alignContent="center"
        >
          <Language />
          <Stack
            direction="row"
            sx={{
              display: {
                xs: "none",
                md: "inline-flex",
              },
            }}
          >
            <Grid
              container
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              <img
                src={icons.UserIcon}
                alt="User Icon"
                style={{ marginInlineEnd: "10px", marginInlineStart: "24px" }}
              />
              <Typography variant="body2" display="inline" color="#f6b119">
                {t("Navbar.sign_in")}
                {" / "}
                {t("Navbar.register")}
              </Typography>
            </Grid>
          </Stack>
        </Grid>
      ) : (
        // website  signedin
        <Grid
          container
          item
          xs={3}
          justifyContent="flex-end"
          alignContent="center"
        >
          <Box sx={{ display: { md: "none" } }}>
            <Language />
          </Box>
          <AccountActions
            cart={cart}
            notificationsCount={notificationsCount}
            handleClick={handleClick}
            handleClose={handleClose}
            anchorEl={anchorEl}
            ordersArray={ordersArray}
            notificationsList={notificationsList}
            setNotificationsList={setNotificationsList}
            favorites={favorites}
            profileData={profileData}
            open={open}
            getList={getList}
            notifCount={notifCount}
            favoritesCount={favoritesCount}
            ordersCount={ordersCount}
            cartCount={cartCount}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Navbar;
