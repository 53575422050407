import { Grid, Typography } from "@mui/material";

import { icons, images } from "../../../../Assets/AssetHelper.js";
import "./DetailsPanels.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const TripDetails = (props) => {
  const { t, diveDetails, isMd } = props;

  const TripDetails = [
    {
      icon: icons.TripDate,
      text:
        diveDetails?.duration_days +
        t("Details.days") +
        diveDetails?.no_of_dives +
        t("Details.dives"),
    },
    {
      icon: images.PickupIcon,
      text: diveDetails?.pickup_option?.replace(/_/g, " "),
    },
    {
      icon: icons.TripRight,
      text: diveDetails?.need_confirmation === true ? t("Details.manualConfirmation") : t("Details.autoConfirmation"),
    },
    {
      icon: icons.UsersIcon,
      text:
        t("Details.max") +
        diveDetails?.max_no_of_students +
        t("Details.student"),
    },
    {
      icon: icons.CancelTrip,
      text:
        diveDetails?.cancellation_option?.replace("_", " ") +
        " - " +
        diveDetails?.cancellation_notice_period +
        t("Details.notice"),
    },
    {
      icon: icons.TripLang,
      text:
        t("Details.available") +
        diveDetails?.activity_languages?.map(
          (lang) => " " + lang?.language?.name
        ),
    },
    {
      icon: icons.TripTicket,
      text: t("Details.show"),
    },
    {
      icon: icons.DetailsLocation,
      text:
        diveDetails?.diving_location?.area?.name +
        " , " +
        diveDetails?.diving_location?.city?.name,
    },
    {
      icon: icons.PaymentBlack,
      text: diveDetails?.allow_cash
        ? t("Details.online_cash")
        : t("Details.online"),
    }, 
    {
      icon: icons.ChairWheel,
      text: diveDetails?.wheelchair_accessible === true ? t("Details.chair_wheel") : "N/A",
    },
    {
      icon: icons.GuidedDives,
      text: diveDetails?.guided_dives === true ? t("Details.guided_dives") : "N/A",
    }
  ];

  return (
    <Grid
      container
      className={isMd ? "mb-20px pr-pl-20px" : "mb-20px panels-container"}
    >
      {isMd && (
        <Grid container mb={2}>
          <Typography sx={{ fontSize: "18px", fontWeight: "800" }}>
            {t("Details.key")}:
          </Typography>
        </Grid>
      )}
      {TripDetails.map((item, i) => (
        <Grid container item xs={12} md={6} className="mb-10px" key={i}>
          <Grid container item xs={1} alignItems="center">
            <img width={16} src={item.icon} alt="" />
          </Grid>
          <Grid container item xs={11} alignItems="center">
            <Typography
              sx={{
                fontSize: "16px",
                color: "var(--font-color)",
                fontWeight: "500",
              }}
            >
              {item.text}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {diveDetails?.activity_type === "live_aboard" && (
        <Grid container item xs={12} md={6} className="mb-10px">
          <Grid container item xs={1} alignItems="center">
            <img width={16} src={icons.DetailsDirection} alt="" />
          </Grid>
          <Grid container item xs={11} alignItems="center">
            <Typography
              sx={{
                fontSize: "16px",
                color: "var(--font-color)",
                fontWeight: "500",
              }}
            >
              {t("HomePage.from") +
                " " +
                diveDetails?.start_point +
                " " +
                t("HomePage.end") +
                " " +
                diveDetails?.end_point}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TripDetails;
