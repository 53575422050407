import { Grid, Typography } from "@mui/material";

const SocialMedia = ({ t, profileData }) => {
    return (
        <Grid container className="certificate-molecule">
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: 'var(--normal-font)',
                    fontWeight: 'bold',
                    color: 'var(--font-color)'
                }}>
                    {t("Profile.add_social")}
                </Typography>
            </Grid>
            {profileData?.social_media?.length > 0 && profileData?.social_media?.map(item => {
                return (
                    <Grid item xs={6} key={item.id}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#aaa' }}>
                            {item.social_medium.name}:
                        </Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#272726' }}>
                            {item.link}
                        </Typography>
                    </Grid>
                )
            })}
        </Grid>
    );
}

export default SocialMedia;