import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FavoritesListAPI } from "../../../Contexts/APIs/Favorites/FavoritesList";
import FavoritesTemplate from "../../../Templates/Main/Favorites/Favorites.Template";

const FavoritesPage = ({ t, getFavorites }) => {
  const [favorites, setFavorites] = useState([]);
  const [count, setCount] = useState(10);
  const [filterObj, setFilterObj]= useState(null)
  const [loading, setLoading] = useState(false);

  const getFilteredFavorites = async (filter) => {
    setLoading(true);
    const { res, err } = await FavoritesListAPI(filter);

    if (res) {
      setLoading(false);
      setFavorites(res?.data?.favourites);
      setCount(res?.extra?.total_count);
    }
    if (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    getFilteredFavorites({
      favourite_type: "dive_trip",
      page_size: 12,
      ...filterObj
    });
  }, [filterObj]);
  return (
    <FavoritesTemplate
      t={t}
      favorites={favorites}
      getFilteredFavorites={getFilteredFavorites}
      getFavorites={getFavorites}
      loading={loading}
      count={count}
      setFilterObj={setFilterObj}
      filterObj={filterObj}
    />
  );
};

export default FavoritesPage;
