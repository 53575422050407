import { Grid, Typography } from "@mui/material"

import { icons } from "../../../../Assets/AssetHelper.js";
import "./DetailsPanels.css";

const PackageDescription = (props) => {

    const { t, includes, excludes } = props;

    return (
        <Grid container className="mb-20px panels-container">
            <Grid container className="mb-20px">
                <Typography component="h1" style={{ fontSize: "22px", fontWeight: "bold", color: "var(--font-colorv f)" }}>
                    {t("Details.package")}
                </Typography>
            </Grid>
            <Grid container item md={6} className="mb-10px">
                <Grid item sm={12} className="mb-10px">
                    <Typography component="p" style={{ color: "var(--secound-f-color)" }}>
                        {t("Details.include")}:
                    </Typography>
                </Grid>
                {includes?.length > 0 && includes?.map((item) => (
                    <Grid container key={item?.id}>
                        <Grid item sm={12}>
                            <img src={icons.GreenCheck} alt="" />
                            <Typography display='inline' style={{ color: "var(--font-color)", fontSize: "18px", marginInlineStart: '10px' }}>
                                {item?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid container item md={6} className="mb-10px">
                <Grid item sm={12} className="mb-10px">
                    <Typography component="p" style={{ color: "var(--secound-f-color)" }}>
                        {t("Details.not_include")}:
                    </Typography>
                </Grid>
                {excludes?.length > 0 && excludes?.map((item) => (
                    <Grid container key={item?.id}>
                        <Grid item sm={12}>
                            <img src={icons.CrossMark} alt="" />
                            <Typography display='inline' style={{ color: "var(--font-color)", fontSize: "18px",marginInlineStart:'10px' }}>
                                {item?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default PackageDescription