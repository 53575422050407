import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import HelpForms from "../../../Molecules/Profile/HelpAndSupport/HelpForms";

const Support = ({ t, userEmail }) => {
    
    return (
        <Grid container sx={{ maxWidth: '425px' }}>
            <Grid item xs={12} mb={3}>
                <Typography sx={{
                    fontSize: 'var(--xlarge-font)',
                    fontWeight: '800',
                    color: 'var(--font-color)'
                }}>
                    {t("Profile.support")}
                </Typography>
            </Grid>
            <HelpForms targetPage={'CustomerSupport'} userEmail={userEmail} />
        </Grid>
    );
}

export default withTranslation()(Support);