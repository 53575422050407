import { Grid, Skeleton, Typography } from "@mui/material";
import { useState } from "react";

import { icons } from "../../../../Assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import AddEditCertificates from "../../../Organisms/Modals/Profile/AddEditCertificates";
import ConfirmationModal from "../../../Organisms/Modals/Profile/ConfirmationModal";

function CertificatesMolecule({
  t,
  profileData,
  organizations,
  getOrganizationLevels,
  type,
  createCertificate,
  deleteCertificate,
  editCertificate,
  organizationLevels,
  getOrganizations,
}) {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalType, setModalType] = useState("");
  const [id, setId] = useState();
  const [itemSelected, setItemSelected] = useState({});

  // const handleClose = () => setOpen(false)
  const handleCloseConfirm = () => setOpenConfirm(false);

  const addCert = () => {
    setModalType("add");
    setOpen(true);
  };

  const editCert = (item) => {
    setModalType("edit");
    setItemSelected(item);
    setOpen(true);
  };

  return (
    <Grid container className={type === "edit" ? "" : "certificate-molecule"}>
      <Grid item xs={12} mb={2}>
        <Typography
          display={type === "edit" && "inline"}
          sx={{
            fontSize: "var(--normal-font)",
            fontWeight: "bold",
            color: "var(--font-color)",
          }}
        >
          {type === "edit"
            ? t("Profile.diving_certificates")
            : t("Profile.certificates")}
        </Typography>
        {type === "edit" && (
          <img
            src={icons.AddIcon}
            alt="add certificate"
            style={{ marginInlineStart: "18px", cursor: "pointer" }}
            onClick={addCert}
          />
        )}
      </Grid>
      {profileData?.certificates?.length === 0 ? (
        <Grid container item xs={12}>
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "500",
              color: "#686868",
            }}
          >
            {t("Profile.no_cert_added")}
          </Typography>
        </Grid>
      ) : null}
      <Grid container item xs={12}>
        {profileData?.certificates?.map((item, index) => {
          return (
            <Grid
              item
              width={{ xs: "100%", md: "calc(25% - 11.25px)" }}
              sx={{
                marginInlineEnd: (index + 1) % 4 !== 0 ? "15px" : "0px",
              }}
              key={item?.id}
            >
              {item?.image ? (
                <img
                  src={item?.image}
                  alt="certificate"
                  width="100%"
                  height="100px"
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: "160px", height: "100px" }}
                  animation={false}
                />
              )}
              <Typography
                sx={{
                  fontSize: "var(--small-font)",
                  fontWeight: "500",
                  color: "#202428",
                }}
              >
                {item?.organization?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "var(--mini-font)",
                  fontWeight: "500",
                  color: "#686868",
                }}
              >
                {item?.organization_certificate?.name}
              </Typography>
              {type === "edit" && (
                <Grid container>
                  <Grid item xs={6}>
                    <PrimaryButton
                      text={t("Profile.edit")}
                      style={{
                        border: "solid 1px #f0b70d",
                        height: "28px",
                        borderRadius: "14px",
                        textTransform: "capitalize",
                      }}
                      click={() => editCert(item)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PrimaryButton
                      text={t("Profile.delete")}
                      style={{
                        color: "#cb2127",
                        height: "28px",
                        textTransform: "capitalize",
                      }}
                      click={() => {
                        setId(item?.id);
                        setOpenConfirm(true);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
      <AddEditCertificates
        t={t}
        open={open}
        // handleClose={handleClose}
        setOpen={setOpen}
        organizations={organizations}
        getOrganizationLevels={getOrganizationLevels}
        createCertificate={createCertificate}
        editCertificate={editCertificate}
        item={itemSelected}
        modalType={modalType}
        organizationLevels={organizationLevels}
        getOrganizations={getOrganizations}
      />
      <ConfirmationModal
        t={t}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        item={id}
        action={deleteCertificate}
        type="deleteCertificate"
      />
    </Grid>
  );
}

export default CertificatesMolecule;
