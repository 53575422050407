import { axiosFn, handleError } from "../../index"
import * as URL from "../../URLs"

export async function ResetPasswordAPI(data, user_type) {
    return await axiosFn("post", URL.AUTH + `passwords/${user_type}/reset_password`, data)    
        .then((res) => {
            const result = res.data.data
            return {
                res: result,
                err: null
            }
        })
        .catch(handleError)
}
