import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function allContacts(filter) {
    return await axiosFn("get", URL.CONTACTS + makeFilterString(filter))
        .then((res) => {
            return {
                res: res?.data,
                err: null
            }
        })
        .catch(handleError)
}
