/* eslint-disable no-console */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import "firebase/compat/database";
import { OAuthProvider, getAuth, signInWithCustomToken, signInWithPopup, signOut } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onValue } from "firebase/database";
import { toast } from "react-toastify";
import { getMessaging, getToken } from "firebase/messaging";
const config = {
  apiKey: "AIzaSyAWNrxzdaPp11pUgIznSVSTseMlCDtZebU",
  authDomain: "scubers-d670a.firebaseapp.com",
  databaseURL: "https://scubers-d670a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scubers-d670a",
  storageBucket: "scubers-d670a.appspot.com",
  messagingSenderId: "908128774817",
  appId: "1:908128774817:web:c4cd7fd85bf76b1a449ee0",
  measurementId: "G-ZH9FQXN79K",
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(config);
}
export const SignInFirebase = () => {
  signInWithCustomToken(firebase.auth(), localStorage.getItem("FirebaseTOKEN"))
    .then((userCredential) => {
      // Signed in
      const userUID = firebase.auth().currentUser.uid;
      localStorage.setItem("UID", userUID);
      getAnalytics(config);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
};

export const signInWithApplePopup = async () => {
  const appleProvider = new OAuthProvider("apple.com");
  const auth = getAuth();
  let result = await signInWithPopup(auth, appleProvider);
  const user = result.user;
  const accessToken = user.accessToken;
  const uid = user?.providerData?.[0]?.uid;
  return { accessToken, uid };
};

export const signOutFirebase = async () => {
  const auth = getAuth();

  return await signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
      toast.error(error);
    });
};

class _Firebase {
  fcmToken = null;
  isMessagingSupported = true;
  constructor() {
    this.auth = firebase.auth();

    try {
      if (firebase.messaging.isSupported()) {
        this.messaging = firebase.messaging();
      } else {
        this.isMessagingSupported = false;
      }
    } catch (err) {
      console.log("[FIREBASE MESSAGING ERROR]: ", err);
      this.isMessagingSupported = false;
    }
  }

  async registerSW() {
    if (!this.isMessagingSupported) return null;
    const regists = await navigator.serviceWorker.getRegistrations().catch((err) => err);
    if (!Array.isArray(regists)) {
      console.log("[ERROR REGISERING SW]: ", regists);
    }

    if (regists.length > 0) {
      [this.registration] = regists;
      return this.registration;
    }

    const regis = await navigator.serviceWorker.register("/firebase-message-sw.js");

    this.registration = regis;
    return this.registration;
  }

  getFCMToken() {
    if (!this.isMessagingSupported) return "This is a dummy string for FCM token";
    if (this.fcmToken) return this.fcmToken;
    try {
      this.messaging
        .getToken({
          serviceWorkerRegistration: this.registration,
        })
        .then((token) => {
          window.localStorage.setItem("fcm_token", token);
        });

      return this.fcmToken;
    } catch (error) {
      console.error("[FIREBASE ERROR]: ", error);
      this.fcmToken = null;
      return null;
    }
  }

  onMessageListener = () =>
    new Promise((resolve) => {
      this.messaging.onMessage((payload) => {
        resolve(payload);
      });
    });

  SignInFirebase = async (firebaseAuthToken) => {
    // console.log(localStorage.getItem("firebase_auth_token"), "firebase_auth_token");
    // console.log("TEST", localStorage.getItem("firebase_auth_token"));
    signInWithCustomToken(auth, firebaseAuthToken || localStorage.getItem("firebase_auth_token"))
      .then((userCredential) => {
        // Signed in
        const userUID = auth.currentUser.uid;
        localStorage.setItem("UID", userUID);
        getAnalytics(config);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };

  SignoutFirebase = async () => {
    return await signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("signout from firebase");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  listenOnNode = (setUnreadCount) => {
    const userNode = localStorage.getItem("firebase_node_path");
    const notificaiontsCount = ref(database, userNode);
    onValue(notificaiontsCount, (snapshot) => {
      const count = snapshot?.val()?.notification_count;
      setUnreadCount(count);
    });
  };

  requestForFCMToken = async () => {
    const messaging = getMessaging();
    return await getToken(messaging, {
      vapidKey: "BGK9ZQaHcI4Q0TmNlQ87IjfhRNk-Naxpu_b7K4JSw1-mtS2SRTyDroTrWsG5NFb5pZ74FVboAypW-R00Bjf6910",
    })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          console.log("No registration token available. Request permission to generate one.");
          return null;
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        return null;
      });
  };
}
export const auth = getAuth();
export const database = getDatabase();
export const Firebase = new _Firebase();
export const firebaseSDK = firebase;
