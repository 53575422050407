import SearchTemplate from "../../../Templates/Main/Search/Search.Template.jsx";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ListDiveActivitiesAPI } from "../../../Contexts/APIs/Dive-Activities/ListDiveActivities";
import { AreaAPI } from "../../../Contexts/APIs/Lookups/Area";
import { LanguageAPI } from "../../../Contexts/APIs/Lookups/Language";
import { useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader.js";

const SearchPage = ({ t, getFavorites }) => {
  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number");
  const activity_type = searchParams.get("activity_type");
  const type = param.type;
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [locationsCount, setLocationCount] = useState();
  const [languagesCount, setLanguagesCount] = useState();

  const diveActivities = async (filter) => {
    setLoading(true);
    const { res, err } = await ListDiveActivitiesAPI(filter);
    if (res) {
      setLoading(false);
      return res;
    }
    if (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const getCities = async (filter) => {
    const { res, err } = await AreaAPI(filter);
    if (res) {
      setLocationCount(res?.extra?.total_count);
      return res?.data?.areas;
    }
    if (err) toast.error(err);
  };

  const getLanguage = async (filter) => {
    const { res, err } = await LanguageAPI(filter);
    if (res) {
      setLanguagesCount(res?.extra?.total_count);
      return res?.data?.languages;
    }
    if (err) toast.error(err);
  };

  return (
    <>
      <Loader open={isLoading} />
      <SearchTemplate
        t={t}
        type={type}
        diveActivities={diveActivities}
        getCities={getCities}
        isLoading={isLoading}
        getLanguage={getLanguage}
        getFavorites={getFavorites}
        location={location}
        pageNumber={pageNumber}
        activity_type={activity_type}
        languagesCount={languagesCount}
        locationsCount={locationsCount}
      />
    </>
  );
};

export default SearchPage;
