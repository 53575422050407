import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Avatar,
  Typography,
  Stack,
  Grid,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { icons, images } from "../../../Assets/AssetHelper";
import { withTranslation } from "react-i18next";

const ChangeAccountTypeTemplate = ({ selectedIndex, handleListItemClick, t }) => {
  const navigate = useNavigate();
  const types = [
    { title: t("Signup.customer"), value: "customer", avatar: icons?.CustomerIcon },
    { title: t("Signup.dive_club"), value: "dive_club", avatar: icons.DiveClubIcon },
  ];
  return (
    <Grid container>
      <Grid item md={6} sx={{ display: { xs: "none", md: "inherit" } }}>
        <img src={images.LoginBg} alt="Login" width="100%" height="100%" />
      </Grid>
      <Stack width={{ xs: "100%", md: "50%" }} alignItems="center">
        <img
          src={icons.BackArrow}
          alt="Login"
          width="10px"
          style={{
            alignSelf: "flex-start",
            padding: "30px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/login")}
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={2}
          mt={20}
          height="350px"
        >
          <img src={icons.LogoLogin} alt="Login" width="50%" height="100%" />
          <Typography color="#272726" fontSize="22px">
            {t("General.welcome_super_admin")}
          </Typography>
          <Typography color="#272726" fontSize="32px" fontWeight="bold">
            {t("Signup.login_account_as")}
          </Typography>
          {types?.map((account, i) => {
            return (
              <ListItemButton
                key={i}
                sx={{
                  border: "1px solid #f6b119",
                  borderRadius: "10px",
                  width: { xs: "90%", md: "70%" },
                  height: "100%",
                }}
                alignItems="center"
                selected={selectedIndex === i}
                onClick={(event) => handleListItemClick(event, i, account)}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {`${account.title}`}
                    </Typography>
                  }
                />

                <ListItemAvatar>
                  <Avatar
                    alt="Remy Sharp"
                    src={account?.avatar}
                    sx={{
                      width: "100px",
                      height: "100%",
                      marginInlineEnd: "14px",
                      borderRadius: 0,
                    }}
                  />
                </ListItemAvatar>
              </ListItemButton>
            );
          })}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default withTranslation()(ChangeAccountTypeTemplate);
