import { Divider, Fab, Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CustomPagination from "../../Atoms/Pagination/Pagination";
import CarouselCard from "../../Molecules/Carousels/Children/CarouselCard";
import SearchBar from "../../Molecules/Search/SearchBar";
import { icons } from "../../../Assets/AssetHelper";
import FilterModal from "../Modals/Responsive/SearchPage/FilterModal";
import SortModal from "../Modals/Responsive/SearchPage/SortModal"; 

function SearchResponsive(props) {
    return (<Grid container>
        <Grid container item xs={12} justifyContent='center' mt={1}>
            <SearchBar value={props.locationValue} setValue={props.setLocationValue} inputValue={props.locationInputValue} setInputValue={props.setLocationInputValue} items={props.locationArray} text={props.t("Search.location")} // getSearchKeywords={getSearchKeywords}
                // className='location-search'
                startIcon={<img src={icons.LocationOrange} style={{
                    color: '#f6b119'
                }} alt='search by location' />} endIcon={<KeyboardArrowDownIcon htmlColor='#f6b119' />} justifyContent='center' sx={{
                    ".MuiInputBase-formControl": {
                        paddingInlineEnd: '0px'
                    },
                    ".MuiOutlinedInput-root": {
                        paddingTop: '0px',
                        paddingBottom: '0px'
                    },
                    width: '50%'
                }} />
        </Grid>
        {
            /* Activity Switching */
        }
        <Grid container item sm={12} mt={1} mb={2} sx={{
            borderTop: 'solid 1px #edeef0',
            borderBottom: 'solid 1px #edeef0'
        }}>
            {props.activities.map((activity, i) => <Grid container item xs={4} sx={{
                cursor: 'pointer'
            }} key={i} onClick={() => props.handleTypeChange(activity.name, activity.text)}>
                <Grid container item xs={12} justifyContent='center' alignContent='center'>
                    <img src={props.typeSelected === activity.name ? activity.image : activity.image1} alt='activity' width='22px' height='22px' />
                </Grid>
                <Grid container item xs={12} justifyContent='center' alignContent='center'>
                    <Typography sx={{
                        fontSize: {
                            xs: '12px',
                            md: '16px'
                        },
                        fontWeight: 'bold',
                        color: props.typeSelected === activity.name ? '#f6b119' : '#9d9d9d'
                    }}>
                        {activity.text}
                    </Typography>
                </Grid>
            </Grid>)}
        </Grid>
        {
            /* Search Result cards */
        }
        <Grid container mb={2}>
            {props.activityData?.map((card, i) => {
                return <CarouselCard key={i} t={props.t} item={card} getFavorites={props.getFavorites} />;
            })}
        </Grid>
        {
            /* Pagination */
        }
        {props.count > 10 && <Grid container justifyContent='center' mt={2} mb={2}>
            <CustomPagination count={Math.ceil(props.count / 10)} size='small' shape="rounded" page={props.page} handleChange={props.handlePageChange} className='pagination' />
        </Grid>}
        <Grid container justifyContent='center' sx={{
            position: 'fixed',
            bottom: 20
        }}>
            <Fab variant="extended" sx={{
                backgroundColor: 'white',
                border: 'solid 1px #f6b119',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25)',
                width: '70%'
            }} size="medium" aria-label="Fab">
                <Grid container sx={{
                    padding: '10px'
                }} alignItems='center' onClick={() => props.setOpenSort(true)}>
                    <Grid item xs={1}>
                        <img src={icons.Sort} alt="sort" />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography sx={{
                            fontSixe: '14px',
                            fontWeight: '500',
                            color: '#f6b119'
                        }}>
                            {props.t("Search.sort")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" style={{
                    background: '#f6b119'
                }} />
                <Grid container sx={{
                    padding: '10px'
                }} alignItems='center' onClick={() => props.setOpenFilter(true)}>
                    <Grid item xs={1}>
                        <img src={icons.Filter} alt="sort" />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography sx={{
                            fontSixe: '14px',
                            fontWeight: '500',
                            color: '#f6b119',
                            paddingInlineStart: '5px'
                        }}>
                            {props.t("Search.filter")}
                        </Typography>
                    </Grid>
                </Grid>
            </Fab>
        </Grid>
        <FilterModal open={props.openFilter} onClose={() => props.setOpenFilter(false)} filters={props.filters} date={props.date} setDate={props.setDate} t={props.t} from={props.from} setFrom={props.setFrom} to={props.to} setTo={props.setTo} getActivityData={props.getActivityData} customClass={"search-responsive"} />
        <SortModal open={props.openSort} onClose={() => props.setOpenSort(false)} t={props.t} sortItems={props.sortItems} sortValue={props.sortValue} handleSortChange={props.handleSortChange} />
    </Grid>);
}

export default SearchResponsive