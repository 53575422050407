import { Grid, Typography } from "@mui/material";
import { icons } from "../../../../Assets/AssetHelper";

const PriceNotInclude = ({ t, excludes }) => {
    return (
        <Grid container paddingX={2} >
            <Grid item xs={12} className="mb-10px">
                <Typography style={{ color: "var(--secound-f-color)", fontSize: '18px', fontWeight: '800' }}>
                    {t("Details.not")}:
                </Typography>
            </Grid>
            {excludes?.length > 0 && excludes?.map((item) => (
                <Grid container item xs={12} key={item?.id} mb={2} alignItems='center'>
                    <img src={icons.NotInclude} alt="include" />
                    <Typography display='inline' style={{ color: "var(--font-color)", fontSize: "16px", marginInlineStart: '10px', fontWeight: '500' }}>
                        {item?.name}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}

export default PriceNotInclude;