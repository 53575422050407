import { axiosFn, handleError } from "../../index"
import * as URL from "../../URLs"

export async function SendOtpAPI(email , user_type) {
    return await axiosFn("post", URL.AUTH +`passwords/${user_type}/`+ URL.SEND_OTP, email)
        .then((res) => {
            return {
                res: res.data.data,
                err: null
            }
        })
        .catch(handleError)
}