import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Skeleton,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PickersDay } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { icons } from "../../../../Assets/AssetHelper.js";
import "./DetailsPanels.css";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import DateModal from "../../../Organisms/Modals/DateModal";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/BasicDatePicker.jsx";
import ConfirmationModal from "../../../Organisms/Modals/Profile/ConfirmationModal";
import { toast } from "react-toastify";
// import BasicDatePicker from "../../../Atoms/Input/DatePicker copy/DatePicker.js";
import { withTranslation } from "react-i18next";
import { AddEditCartItemAPI } from "../../../../Contexts/APIs/Carts/AddEditCartItem.js";
import ClearIcon from "@mui/icons-material/Clear";

const BookPanel = ({
  t,
  diveDetails,
  addEditCart,
  card,
  onChangeParticipants,
  deleteOrder,
  orderId,
  quantity,
  cart,
  getCart,
  isFromBooking,
  status,
  statusDisplay,
  getRemainingSeats,
  remainingSeats,
  payment,
  startDate,
  endDate,
  startTime,
  paymentLookupkey,
  orderItemId,
  cancellationPolicyMsg,
  payOrder,
  handleClickReview,
  orderData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const [date, setDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [dateValidation, setDateValidation] = useState(false);
  const [open, setOpen] = useState(false);
  const [seats, setSeats] = useState(0);
  const [seatsError, setSeatsError] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const weekDays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const handleCloseConfirm = () => setOpenConfirm(false);
  const submitToCart = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (seatsError === null && date) {
        setDateValidation(false);
        await addEditCart({
          cart_item: {
            dive_activity_id: diveDetails?.id,
            start_date: dayjs(date).format("DD-MM-YYYY"),
            quantity: seats,
          },
          pickup_location: {},
          reservation_participants: [],
        });
      } else if (!date) {
        toast.error(t("General.you_must_choose_date"));
        setDateValidation(true);
      } else if (seatsError) {
        toast.error(t("General.logged_in_to_add_cart"));
        setDateValidation(false);
      }
    } else {
      navigate("/login");
      setDateValidation(false);
    }
  };

  const handleChangeRequest = (newValue) => {
    setDate(dayjs(newValue).format("YYYY/MM/DD"));
    getRemainingSeats(
      diveDetails?.id,
      dayjs(newValue).format("YYYY/MM/DD"),
      setOpen
    );
  };

  /* scheduling_option: { everyday: 0, some_weekdays: 1, some_dates: 2 }
    case some_dates -> object activity_groups
    case some_weekdays, everyday -> object scheduled_days
    */
  // useEffect(() => {
  //   const dates = []; // '2022/9/22','2022/9/25'
  //   if (path === "/edit") {
  //     card?.purchasable?.dive_activity?.activity_groups?.forEach((group) => {
  //       dates.push(dayjs(group.start_date).format("YYYY/MM/DD"));
  //     });
  //   } else {
  //     if (diveDetails?.scheduling_option === "some_dates") {
  //       diveDetails?.activity_groups?.forEach((group) => {
  //         dates.push(dayjs(group.start_date).format("YYYY/MM/DD"));
  //       });
  //     } else if (diveDetails?.scheduling_option === "some_weekdays") {
  //       diveDetails?.scheduled_days?.forEach((day) => {
  //         var dayNoInWeek = weekDays.indexOf(day.weekday);
  //         var dayDate = dayjs().day(dayNoInWeek).format("YYYY/MM/DD");
  //         dates.push(dayDate);
  //         while (dayDate < dayjs(diveDetails?.end_date).format("YYYY/MM/DD")) {
  //           dayNoInWeek += 7;
  //           dayDate = dayjs().day(dayNoInWeek).format("YYYY/MM/DD");
  //           dates.push(dayDate);
  //         }
  //       });
  //     }
  //   }
  //   setDateArray(dates);
  // }, [diveDetails, card]);

  useEffect(() => {
    if (path !== "/edit") {
      if (localStorage.getItem("ActiveUserType") === "customer") setSeats(1);
      else if (localStorage.getItem("ActiveUserType") === "dive_club")
        setSeats(5);
    }
  }, []);

  useEffect(() => {
    if (path === "/edit") setSeats(quantity);
  }, [quantity]);

  // useEffect(() => {
  //     if (path === '/edit') setDate(card?.purchasable?.start_date)
  // }, [card]);

  useEffect(() => {
    // if (path === "/edit") onChangeParticipants(seats);
    if (path === "/edit") {
      onChangeParticipants(seats);
      if (seats != quantity && seats != 0) {
        handelAddQuantity();
      }
    }
    if (localStorage.getItem("ActiveUserType") === "customer") {
      if (seats < 1) setSeatsError(t("Details.customer_error"));
      else if (seats > remainingSeats)
        setSeatsError(
          t("Details.not_exceed") + remainingSeats + t("Details.no_seats")
        );
      else setSeatsError(null);
    } else if (localStorage.getItem("ActiveUserType") === "dive_club") {
      if (seats < 5) setSeatsError(t("Details.diveClub_error"));
      else setSeatsError(null);
    }
  }, [seats]);

  //
  const handelAddQuantity = async () => {
    const { res, err } = await AddEditCartItemAPI({
      cart_item: {
        id: card?.id,
        dive_activity_id: card?.purchasable?.dive_activity_id,
        start_date: card?.purchasable?.start_date,
        quantity: seats,
      },
    });
    if (res) {
    } else {
      toast.error(err);
    }
    getCart();
  };
  // useEffect(() => {
  //   if (path === "/edit" && seats != quantity) {
  //     handelAddQuantity();
  //   }
  // }, [seats]);

  const shouldDisableDate = (date) => {
    if (
      !diveDetails?.next_available_dates ||
      diveDetails?.next_available_dates?.length === 0
    )
      return true;
    const stringifiedDate = dayjs(date).format("YYYY-MM-DD");
    return !diveDetails?.next_available_dates?.includes(stringifiedDate);
  };

  const shouldDisableMonth = (month) => {
    if (
      !diveDetails?.next_available_dates ||
      diveDetails?.next_available_dates?.length === 0
    )
      return true;
    const monthString = dayjs(month).format("YYYY-MM");
    return !diveDetails.next_available_dates.some((date) =>
      date.startsWith(monthString)
    );
  };

  const shouldDisableYear = (year) => {
    if (
      !diveDetails?.next_available_dates ||
      diveDetails?.next_available_dates?.length === 0
    )
      return true;
    const yearString = dayjs(year).format("YYYY");
    return !diveDetails?.next_available_dates?.some((date) =>
      date.startsWith(yearString)
    );
  };

  return (
    <Grid
      container
      className={
        path === "/edit" ? "book-panel" : "mb-30px book-panel pb-pt-20px"
      }
    >
      {path === "/edit" && (
        <Grid container mb={1.5}>
          {card?.purchasable?.dive_activity?.media?.[0]?.url !== "url" ? (
            <img
              src={card?.purchasable?.dive_activity?.media?.[0]?.url}
              alt="media"
              width="100%"
              height="100%"
              style={{
                borderTopLeftRadius: "var(--md-radius)",
                borderTopRightRadius: "var(--md-radius)",
              }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              style={{
                borderTopLeftRadius: "var(--md-radius)",
                borderTopRightRadius: "var(--md-radius)",
              }}
              width="100%"
              height="100%"
              animation={false}
            />
          )}
        </Grid>
      )}
      {isFromBooking ? (
        <Grid container item lg={10} style={{ margin: "auto" }}>
          <Grid container mb={2} mt={2}>
            <Grid container item xs={1}>
              <img
                src={
                  status === "pending" || status === "payment_pending"
                    ? icons.WaitingConfirmed
                    : status === "completed" ||
                      status === "checkin_pending" ||
                      status === "checked_in"
                    ? icons.BookingConfirmed
                    : icons.Cancelled
                }
                alt="status"
              />
            </Grid>
            <Grid container item xs={11} justifyContent="start">
              <Typography
                sx={{ fontSize: 18, fontWeight: "500" }}
                color={statusDisplay?.display_color}
              >
                {statusDisplay?.display_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <img src={icons.CalenderSmall} alt="calender" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              {/* {t("Details.from")}{" "}
              {dayjs(diveDetails?.start_date).format("DD/MM/YYYY")}{" "}
              {t("Details.to")}{" "}
              {dayjs(diveDetails?.end_date).format("DD/MM/YYYY")}  */}
              {t("Details.from")} {dayjs(startDate).format("DD/MM/YYYY")}{" "}
              {t("Details.to")} {dayjs(endDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img src={icons.ClockSmall} alt="calender" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              {/* {diveDetails?.activity_groups?.[0]?.start_time} */}
              {startTime}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img src={icons.Person} alt="calender" />
          </Grid>
          <Grid item xs={11}>
            {quantity}
          </Grid>
          <Grid item xs={1}>
            <img
              src={icons.Payment}
              alt="payment"
              width={"13px"}
              height={"13px"}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              {payment === "Cash" ? t("Booking.cash") : t("Booking.online")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          md={10}
          style={{ margin: "auto" }}
          p={{ xs: 1, md: 0 }}
        >
          <Grid
            className="mb-20px"
            container
            item
            md={12}
            style={{ margin: "auto" }}
          >
            {path === "/edit" && (
              <Grid container mb={2}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "var(--subtitle-font)",
                    color: "var(--font-color)",
                  }}
                >
                  {card?.purchasable?.dive_activity?.name}
                  {/* {card?.purchasable?.duration_days}{' '}{t("Search.days")}{' Package '}{card?.purchasable?.dive_activity?.no_of_dives}{' '}{t("Search.dives")} */}
                </Typography>
              </Grid>
            )}
            <Grid item md={6}>
              <Typography
                component="h1"
                style={{
                  color: "var(--font-color)",
                  fontSize: "35px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {path === "/edit"
                  ? `${
                      card?.purchasable?.currency?.lookup_key == undefined
                        ? ""
                        : card?.purchasable?.currency?.lookup_key
                    } ${
                      card?.purchasable?.price == undefined
                        ? ""
                        : card?.purchasable?.price
                    }`
                  : `${diveDetails?.currency?.lookup_key} ${diveDetails?.price_after_discount}`}
              </Typography>
            </Grid>
            <Grid item md={6} style={{ textAlign: "end" }}>
              {diveDetails?.price !== diveDetails?.price_after_discount && (
                <Typography
                  component="del"
                  style={{
                    fontSize: "20px",
                    display: "block",
                    color: "var(--secound-f-color)",
                    marginTop: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {path === "/edit"
                    ? `${card?.purchasable?.currency?.lookup_key} ${card?.original_amount}`
                    : `${diveDetails?.currency?.lookup_key} ${
                        diveDetails?.price * seats || diveDetails?.price
                      }`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            className="mb-20px"
            container
            item
            md={12}
            style={{ margin: "auto" }}
          >
            {/* <BasicDatePicker
              value={date}
              setValue={setDate}
              placeholder="Select Date"
              // maxDate={dayjs(day).format("YYYY/MM/DD")}
              renderDay={(day, _value, DayComponentProps) => {
                const stringifiedDate = dayjs(day).format("YYYY/MM/DD");
                if (diveDetails?.scheduling_option === "some_dates") {
                  if (dateArray.includes(stringifiedDate)) {
                    return (
                      <PickersDay
                        {...DayComponentProps}
                        // sx={{ backgroundColor: 'orange' }}
                      />
                    );
                  }
                  return <PickersDay {...DayComponentProps} disabled />;
                } else if (diveDetails?.scheduling_option === "everyday") {
                  if (
                    stringifiedDate > dayjs().format("YYYY/MM/DD") &&
                    stringifiedDate <=
                      dayjs(diveDetails?.end_date).format("YYYY/MM/DD")
                  ) {
                    return (
                      <PickersDay
                        {...DayComponentProps}
                        // sx={{ backgroundColor: 'orange' }}
                      />
                    );
                  }
                  return <PickersDay {...DayComponentProps} disabled />;
                }
                if (diveDetails?.scheduling_option === "some_weekdays") {
                  if (
                    dateArray.includes(stringifiedDate) &&
                    stringifiedDate > dayjs().format("YYYY/MM/DD") &&
                    stringifiedDate <
                      dayjs(diveDetails?.end_date).format("YYYY/MM/DD")
                  ) {
                    return (
                      <PickersDay
                        {...DayComponentProps}
                        // sx={{ backgroundColor: 'orange' }}
                      />
                    );
                  }
                  return <PickersDay {...DayComponentProps} disabled />;
                }
              }}
            /> */}
            {path == "/edit" ? (
              <Stack
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                className="border-r-5px basic-date-picker mt-10px book-calender-input"
                p={2}
              >
                <Typography>
                  {dayjs(card?.purchasable?.start_date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}{" "}
                </Typography>
                <img src={icons.Calender} width="20px" />
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                className="basic-date-picker book-calender"
              >
                <BasicDatePicker
                  placeholder={"Select date"}
                  value={date}
                  className="border-r-5px basic-date-picker mt-10px book-calender"
                  errorText={"Date field required"}
                  handelChange={(newValue) => setDate(newValue)}
                  setValue={setDate}
                  maxDate={dayjs(new Date(diveDetails?.end_date))}
                  slotProps={{
                    textField: {
                      readOnly: true,
                    },
                  }}
                  renderDay={(day, _, DayComponentProps) => {
                    if (
                      !diveDetails?.next_available_dates ||
                      diveDetails?.next_available_dates?.length === 0
                    ) {
                      return <PickersDay {...DayComponentProps} disabled />;
                    }
                    const stringifiedDate = dayjs(day).format("YYYY-MM-DD");
                    const isSelected =
                      diveDetails.next_available_dates.includes(
                        stringifiedDate
                      );
                    return (
                      <PickersDay
                        {...DayComponentProps}
                        disabled={!isSelected}
                        selected={isSelected}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        inputProps={{ readOnly: true }}
                      />
                    );
                  }}
                  shouldDisableDate={shouldDisableDate}
                  shouldDisableMonth={shouldDisableMonth}
                  shouldDisableYear={shouldDisableYear}
                  disabled={
                    !diveDetails?.next_available_dates ||
                    diveDetails?.next_available_dates?.length === 0
                  }
                />
              </Stack>
            )}
            {diveDetails?.next_available_dates?.length === 0 && (
              <Typography color={"error"} fontSize="14px" mx={"2px"}>
                There is no available dates
              </Typography>
            )}
          </Grid>

          <Grid
            className="mb-20px book-calender-input"
            container
            item
            md={12}
            style={{ margin: "auto" }}
          >
            <Grid container item md={7} alignContent="center">
              <Typography
                sx={{
                  color: "var(--font-color)",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginInlineStart: "16px",
                }}
              >
                {remainingSeats} {t("Details.seats")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              mt={{ xs: 2, md: 0 }}
              sx={{
                ".MuiOutlinedInput-root": {
                  height: "100%",
                },
              }}
            >
              <TextFieldInput
                EndAdornment={
                  <AddIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setSeats((seat) => seat + 1)}
                  />
                }
                StartAdornment={
                  <RemoveIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      seats - 1 < 0 ? setSeats(0) : setSeats((seat) => seat - 1)
                    }
                  />
                }
                className="seats-input"
                gridClass="grid-seats"
                value={seats}
                onChange={(e) =>
                  e.target.value < 0 ? setSeats(0) : setSeats(e.target.value)
                }
              />
            </Grid>
            {seatsError !== null && (
              <Grid item xs={12} mt={1}>
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginInlineStart: "16px",
                  }}
                >
                  {seatsError}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Divider style={{ width: "100%", margin: "20px 0" }} />
      <Grid
        container
        item
        md={10}
        style={{ margin: "auto" }}
        p={{ xs: 1, md: 0 }}
      >
        <Typography
          className="mb-20px"
          component="h3"
          style={{
            color: "var(--font-color)",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          {t("Details.payment")}
        </Typography>
        <Grid container item md={12} className="mb-10px">
          <Grid item sm={6}>
            <Typography
              component="p"
              style={{ color: "var(--secound-f-color)" }}
            >
              {t("Details.subtotal")}
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "end" }}>
            <Typography
              component="p"
              style={{
                color: "var(--font-color)",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {path === "/edit"
                ? `${cart?.amount} ${card?.purchasable?.currency?.lookup_key}`
                : isFromBooking
                ? `${orderData?.payment_transaction?.amount?.toFixed(2)} ${orderData?.payment_transaction?.currency?.lookup_key}`
                : `${diveDetails?.price_after_discount * seats || 0} ${
                    diveDetails?.currency?.lookup_key
                  }`}
            </Typography>
          </Grid>
        </Grid>
        {/* {cart?.points != 0 && (
          <Grid container my={1}>
            <Grid item xs={6}>
              <Typography
                component="p"
                style={{ color: "var(--secound-f-color)" }}
              >
                {t("Details.points_discount")}
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="end">
              <Typography
                component="p"
                style={{
                  color: "var(--font-color)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {parseFloat(
                  cart?.discount || orderData?.discount || diveDetails?.discount
                )?.toFixed(2)}{" "}
                {cart?.currency?.lookup_key || orderData?.currency?.lookup_key}
              </Typography>
            </Grid>
          </Grid>
        )} */}
        {cart?.vat_value > 0 || diveDetails?.vat_value > 0 ? (
          <Grid container item md={12}>
            <Grid item sm={6}>
              <Typography
                component="p"
                style={{ color: "var(--secound-f-color)" }}
              >
                {t("Details.vat")}
              </Typography>
            </Grid>
            <Grid item sm={6} style={{ textAlign: "end" }}>
              <Typography
                component="p"
                style={{ color: "var(--font-color)", fontWeight: "bold" }}
              >
                {path === "/edit"
                  ? `${cart?.vat_value?.toFixed(2)} ${
                      card?.purchasable?.currency?.lookup_key
                    }`
                  : isFromBooking
                  ? `${orderData?.payment_transaction?.vat_amount?.toFixed(
                      2
                    )} ${orderData?.payment_transaction?.currency?.lookup_key}`
                  : `${diveDetails?.vat_value?.toFixed(2)} ${
                      diveDetails?.currency?.lookup_key
                    }`}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Divider style={{ width: "100%", margin: "20px 0" }} />
        <Grid container item md={12} className="mb-20px">
          <Grid item sm={6}>
            <Typography
              component="p"
              style={{ color: "var(--font-color)", fontWeight: "bold" }}
            >
              {t("Details.total")}
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "end" }}>
            <Typography
              component="p"
              style={{ color: "var(--font-color)", fontWeight: "bold" }}
            >
              {path === "/edit"
                ? `${cart?.price_with_vat} ${card?.purchasable?.currency?.lookup_key} `
                : isFromBooking
                ? ` ${orderData?.payment_transaction?.amount_with_vat?.toFixed(2)} ${orderData?.payment_transaction?.currency?.lookup_key}`
                : ` ${diveDetails?.price_with_vat * seats || 0} ${
                    diveDetails?.currency?.lookup_key
                  }`}
            </Typography>
          </Grid>
        </Grid>
        {path !== "/edit" && !isFromBooking && (
          <Grid container item md={12}>
            <PrimaryButton
              text={t("Reservation.add")}
              disabled={seatsError !== null}
              classBtn={isFromBooking ? "cancel-red-btn" : "book-btn"}
              click={() => submitToCart()}
              // disabled={date||seatsError !== null ?false:true}
            />
          </Grid>
        )}
        {/*{path !== "/edit" &&
          isFromBooking &&
          (status === "pending" ||
            status === "payment_pending" ||
            status === "checkin_pending") && (
            <Grid container mb={2}>
              <PrimaryButton
                text={t("Details.cancel")}
                classBtn="cancel-btn"
                // click={() => deleteCart(item?.id)}
              />
            </Grid>
            )}*/}
        {path !== "/edit" &&
          isFromBooking &&
          (status === "pending" || status === "checkin_pending") && (
            <Grid container mb={2}>
              <PrimaryButton
                text={t("Details.cancel")}
                classBtn="cancel-btn"
                // click={() => deleteCart(item?.id)}
                click={() => setOpenConfirm(true)}
              />
            </Grid>
          )}
        {path !== "/edit" && isFromBooking && status === "payment_pending" && (
          <Grid container mb={2}>
            {paymentLookupkey == "credit_card" && (
              <Grid item xs={6} sx={{ paddingInlineEnd: "3px" }}>
                <PrimaryButton
                  text={t("Booking.pay")}
                  classBtn="pay-btn"
                  click={() => payOrder(orderId)}
                />
              </Grid>
            )}
            <Grid
              item
              xs={paymentLookupkey == "credit_card" ? 6 : 12}
              sx={{ paddingInlineStart: "3px" }}
            >
              <PrimaryButton
                text={t("Details.cancel")}
                classBtn="cancel-btn"
                click={() => setOpenConfirm(true)}
              />
            </Grid>
          </Grid>
        )}
        {/* {path !== '/edit' && isFromBooking && status === "payment_pending" &&
                    <Grid container mb={2}>
                        <PrimaryButton
                            text={t("Booking.pay")}
                            classBtn='pay-btn'
                        // click={() => navigate('/checkout')}
                        />
                    </Grid>
                } */}
        {/* {path !== '/edit' && isFromBooking && status === 'cancelled' &&
                    <Grid container mb={2}>
                        <PrimaryButton
                            text={t("Reservation.add")}
                            classBtn='pay-btn'
                        // click={() => handleClick(item?.id, item?.order_item?.id)}
                        />
                    </Grid>
                } */}
        {path !== "/edit" && isFromBooking && status === "completed" && (
          <Grid container mb={2}>
            <PrimaryButton
              text={t("Review.rate_activity")}
              classBtn="book-btn"
              // click={() => handleClick(item?.id, item?.order_item?.id)}
              click={() => handleClickReview(orderId, orderItemId)}
            />
            {/* <Grid item xs={6} sx={{ paddingInlineEnd: '3px' }}>
                            <PrimaryButton
                                text={t("Review.rate_activity")}
                                classBtn='book-btn'
                                // click={() => handleClick(item?.id, item?.order_item?.id)}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ paddingInlineStart: '3px' }}>
                            <PrimaryButton
                                text={t("Booking.book")}
                                classBtn='points-btn'
                            />
                        </Grid> */}
          </Grid>
        )}
      </Grid>
      <ConfirmationModal
        t={t}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        item={orderId}
        action={deleteOrder}
        type="deleteOrder"
        cancellationPolicy={cancellationPolicyMsg}
      />
      <DateModal
        t={t}
        open={open}
        handleClose={() => setOpen(false)}
        handelConfirm={() => {
          getRemainingSeats(diveDetails?.id, date, setOpen);
        }}
        date={dayjs(date)}
        setDate={setDate}
        // disablePast={true}
        // maxDate={dayjs(new Date(diveDetails?.end_date))}
        openTo="day"
        renderDay={(day, _, value, DayComponentProps) => {
          const stringifiedDate = dayjs(day).format("YYYY/MM/DD");
          if (diveDetails?.scheduling_option === "everyday") {
            if (
              stringifiedDate > dayjs().format("YYYY/MM/DD") &&
              stringifiedDate <=
                dayjs(diveDetails?.end_date).format("YYYY/MM/DD")
            ) {
              return <PickersDay {...DayComponentProps} />;
            }
            return <PickersDay {...DayComponentProps} disabled />;
          }
        }}
      />
    </Grid>
  );
};

export default withTranslation()(BookPanel);
