import { makeFilterString } from "../Helper/Helper"
import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function CreateAuthToken() {
    return await axiosFn("post", URL.AUTH + URL.CREATE_AUTH_TOKEN + 'firebase')
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
