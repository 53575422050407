import { Dialog, Divider, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";
import PassowordCriteria from "../../../Atoms/SharedComponents/PassowordCriteria";

const ChangePassword = ({ t, open, handleClose, changePassword }) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          height: "auto",
          width: "400px",
          padding: "18px",
        },
      }}
    >
      <Grid container mb={1}>
        <Grid item xs={11}>
          <Typography
            sx={{
              fontSize: "var(--medium-font)",
              fontWeight: "bold",
              color: "var(--font-color)",
            }}
          >
            {t("ForgetPassword.change_password")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Grid>
      </Grid>
      <Divider />
      <Grid>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
          }}
          validationSchema={Yup.object().shape({
            currentPassword: Yup.string()
              .required(t("validation.password"))
              .min(8, t("validation.password_short")),
            newPassword: Yup.string()
              .trim()
              .required(t("validation.password"))
              .min(8, t("validation.password_digits")) // Minimum 8 characters
              .matches(/[a-zA-Z]/, t("validation.password_letter")) // At least one letter
              .matches(/[0-9]/, t("validation.password_numbers")),
            repeatPassword: Yup.string().test(
              t("validation.match"),
              t("validation.must_match"),
              function (value) {
                return this.parent.newPassword === value;
              }
            ),
          })}
          onSubmit={async (values) => {
            const data = {
              user: {
                current_password: values.currentPassword,
                password: values.newPassword,
                password_confirmation: values.repeatPassword,
              },
            };
            const action = () => {
              handleClose();
              navigate("/change-password-success");
            };

            await changePassword(data, action);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Field
                as={TextFieldInput}
                type="password"
                name="currentPassword"
                placeholder={t("Profile.current_password")}
                label={t("Profile.current_password")}
                className="login-input"
                gridClass="grid-input"
              />
              <ErrorMessage
                name="currentPassword"
                component="div"
                style={{ color: "#d32f2f" }}
              />
              <Field
                as={TextFieldInput}
                type="password"
                name="newPassword"
                placeholder={t("Profile.new_password")}
                label={t("Profile.new_password")}
                className="login-input"
                gridClass="grid-input"
              />
              <PassowordCriteria />
              <ErrorMessage
                name="newPassword"
                component="div"
                style={{ color: "#d32f2f" }}
              />
              <Field
                as={TextFieldInput}
                type="password"
                name="repeatPassword"
                placeholder={t("Profile.repeat")}
                label={t("Profile.repeat")}
                className="login-input"
                gridClass="grid-input"
              />
              <ErrorMessage
                name="repeatPassword"
                component="div"
                style={{ color: "#d32f2f" }}
              />
              <Grid container justifyContent="center" mb={1}>
                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting}
                  text={t("Profile.save")}
                  classBtn="orange-btn"
                  style={{ width: "200px", height: "51px", marginTop: "32px" }}
                  click={submitForm}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  );
};

export default ChangePassword;
