import MultiCarousel from "../../Atoms/Carousel/Carousel";
import CarouselCard from "./Children/CarouselCard";
import CarouselImage from "./Children/CarouselImage";
import CarouselResponsive from "./Children/CarouselResponsive";
import CarouselSmallCard from "./Children/CarouselSmallCard";

const CarouselMolecule = ({
  t,
  type,
  items,
  getFavorites,
  isBranchDetails,
  action,
  imageOnly,
  autoPlay,
  autoPlaySpeed,
  infinite,
  customRightArrow,
  customLeftArrow,
  itemsToShow,
  maxHeight,
  enableWidth,
  customStyle,
  enableHeight,
  borderRadius
}) => {
  return (
    <MultiCarousel
      swipeable={true}
      draggable={false}
      showDots={false}
      centerMode={false}
      partialVisible={false}
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      responsive={{
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 2400 },
          items: type === "card" ? 5 : 6,
          slidesToSlide: 3,
        },
        largeDesktop: {
          breakpoint: { max: 2400, min: 1800 },
          items: type === "card" ? 4 : 5,
          slidesToSlide: 2,
        },
        desktop: {
          breakpoint: { max: 1800, min: 1200 },
          items: type === "card" ? 3 : itemsToShow || 4, //
          slidesToSlide: 2,
        },
        tablet: {
          breakpoint: { max: 1200, min: 600 },
          items: 2,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1,
          slidesToSlide: 1,
        },
      }}
      keyBoardControl={true}
      autoPlay={autoPlay}
      autoPlaySpeed={autoPlaySpeed}
      infinite={infinite}
      customTransition={"all .5"}
      transitionDuration={500}
      shouldResetAutoplay={false}
      rtl={false}
      customRightArrow={customRightArrow}
      customLeftArrow={customLeftArrow}
      children={items?.map((item, i) =>
        type === "image" ? (
          <CarouselImage
            key={i}
            item={item}
            imageOnly={imageOnly}
            action={action}
            enableWidth={enableWidth}
            maxHeight={maxHeight}
            customStyle={customStyle}
            enableHeight={enableHeight}
            borderRadius={borderRadius}
          />
        ) : type === "card" ? (
          <CarouselCard
            t={t}
            key={i}
            item={item}
            getFavorites={getFavorites}
            isBranchDetails={isBranchDetails}
          />
        ) : type === "responsive" ? (
          <CarouselResponsive key={i} item={item} />
        ) : (
          <CarouselSmallCard
            t={t}
            key={i}
            item={item}
            getFavorites={getFavorites}
          />
        )
      )}
    />
  );
};

export default CarouselMolecule;
