import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { images } from "../../../Assets/AssetHelper"
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"

const BookingStatus = ({ t, status, setStatus }) => {
    const navigate = useNavigate()
    return (
        <Grid container alignContent='center' sx={{ marginY: '110px' }}>
            <Grid container item xs={12} justifyContent='center' mb={4}>
                <img src={status ? images.Success : images.Fail} alt='status' />
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
                <Typography sx={{
                    fontSize: 'var(--subtitle-font)',
                    fontWeight: 'bold',
                    color: 'var(--font-color)',
                    width: status ? '460px' : '340px',
                    textAlign: 'center'
                }}>
                    {status ? t("Booking.paymentSuccess") : t("Booking.fail")}                    
                </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent='center' mt={5}>
                <PrimaryButton
                    text={status ? t("Booking.go") : t("Booking.try")}
                    classBtn='orange-btn'
                    click={() => {
                        if (status) navigate('/orders')
                        else navigate('/checkout')
                        // setStatus(null)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default BookingStatus