import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function SignInAPI(data) {
  return await axiosFn("post", URL.AUTH + URL.LOGIN, data)
    .then((res) => {
      return {
        res: res.data.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function SuperSignInAPI(user_type, user_id) {
  return await axiosFn("post", `${URL.AUTH}${user_type}/${user_id}/super_login`)
    .then((res) => {
      return {
        res: res.data.data,
        err: null,
      };
    })
    .catch(handleError);
}
