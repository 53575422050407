import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import "./Carousel.css"

function MultiCarousel({
  deviceType,
  children,
  swipeable,
  draggable,
  showDots,
  responsive,
  autoPlaySpeed,
  ssr,
  autoPlay,
  infinite,
  keyBoardControl,
  customTransition,
  transitionDuration,
  containerClass,
  removeArrowOnDeviceType,
  dotListClass,
  itemClass,
  customRightArrow,
  customLeftArrow,
  centerMode,
  partialVisible,
  shouldResetAutoplay
}) {
  return (
    <Carousel
      swipeable={swipeable}
      draggable={draggable}
      showDots={showDots}
      responsive={responsive}
      ssr={ssr} // means to render carousel on server-side.
      infinite={infinite}
      autoPlay={autoPlay}   //{deviceType !== "mobile" ? true : false}
      autoPlaySpeed={autoPlaySpeed}   //{1000}
      keyBoardControl={keyBoardControl}
      customTransition={customTransition}
      transitionDuration={transitionDuration}
      containerClass={containerClass}
      removeArrowOnDeviceType={removeArrowOnDeviceType}
      deviceType={deviceType}
      dotListClass={dotListClass}  //"custom-dot-list-style"
      itemClass={itemClass}
      customRightArrow={customRightArrow}
      customLeftArrow={customLeftArrow}
      centerMode={centerMode}
      partialVisible={partialVisible}
      shouldResetAutoplay={shouldResetAutoplay}      
    >
      {children}
    </Carousel>
  )
}

export default MultiCarousel
