import { Grid } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";

export function EmailInput({ t, checkEmailExists, setSearchParams, SendOtp }) {
  const navigate = useNavigate();
  return (
    <Grid container item xs={12} justifyContent="center" mb={2}>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("validation.invalid_email"))
            .required(t("validation.email")),
        })}
        onSubmit={async (values) => {
          const data = {
            user: {
              email: values.email,
            },
          };
          const res = await checkEmailExists(data);
          if (
            res?.user_types?.includes("customer") ||
            res?.user_types?.includes("dive_club")
          ) {
            if (
              res?.user_types?.includes("customer") &&
              res?.user_types?.includes("dive_club")
            ) {
              navigate(`/account-type?email=${values.email}`);
            } else {
              const type = res?.user_types?.includes("customer")
                ? "customer"
                : "dive_club";
              navigate(
                `?email=${values?.email}&user_type=${res?.user_types?.[0]}`
              );

              await SendOtp(values.email, type);
            }
          }
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              as={TextFieldInput}
              type="email"
              name="email"
              placeholder={t("Login.email")}
              label={t("Login.email")}
              className="login-input"
              gridClass="grid-input"
              clickEnter={submitForm}
              sx={{ width: { xs: "90%", md: "100%" }, marginX: "auto" }}
            />
            <ErrorMessage name="email" component="div" style={{ color: '#d32f2f' }} />
            <Grid container justifyContent="center" mt={{ xs: 15, md: 4 }}>
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                text={t("Signup.next")}
                classBtn="orange"
                // style={{
                //     marginTop: '39px'
                // }}
                click={submitForm}
                sx={{
                  fontSize: {
                    xs: "20px",
                    md: "24px",
                  },
                  borderRadius: {
                    xs: "100px",
                    md: "10px",
                  },
                  height: {
                    xs: "60px",
                    md: "72px",
                  },
                  width: {
                    xs: "340px",
                    md: "400px",
                  },
                }}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
