import { icons } from "./../../../Assets/AssetHelper";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAccountRequest } from "../../../services/modules/Auth";
import { ToggleAlertModal } from "../../../services/modules/modals/Actions";
import * as Yup from "yup";
import Loader from "../../../Components/Atoms/Loader/Loader";
import DeleteAccountTemplate from "../../../Templates/Auth/deleteAccount/DeleteAccount.Template";
import AlertModal from "../../../Components/Atoms/Modal/AlertModal";

const emailValidation = Yup.object().shape({
  email: Yup.string().required().email("Invalid Email").label("Email"),
  password: Yup.string().required().label("Password"),
  user_type: Yup.string().required('User type is required'),
});

export default function DeleteAccountPage() {
  const dispatch = useDispatch();
  const load = useSelector((state) => state?.auth?.isLoading);

  const handleDeleteAccount = (values) => {
    const { user_type, email, password } = values;
    const action = () => {
      dispatch(
        ToggleAlertModal({
          isOpen: true,
          icon: icons?.SuccessDelete,
          message: "Your account has been successfully deleted.",
        })
      );
      formik?.resetForm();
    };
    const failAction = (error) => {
      // console.log(error);
      const errorType = error?.response?.data?.message;
      if (errorType === "Invalid Credentials") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.Exclamationmark,
            message: (
              <>
                Oops! There seems to be a mismatch. <br />
                Check your email and password again.
              </>
            ),
          })
        );
      } else if (errorType === "Account Not Found") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.NotFound,
            message: (
              <>
                That account seems to be missing! <br />
                Double-check the email and try again.
              </>
            ),
          })
        );
      }
    };
    const requestData = {
      body: { user_type, account:{ email, password } }
      ,
      action,
      failAction,
    };
    dispatch(DeleteAccountRequest(requestData));
  };

  const formik = useFormik({
    initialValues: { user_type: "", email: "", password: "" },
    validationSchema: emailValidation,
    onSubmit: async (values) => {
      handleDeleteAccount(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Loader open={Boolean(load?.loading)} />
      <AlertModal />
      <DeleteAccountTemplate formik={formik} />
    </>
  );
}
