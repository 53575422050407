import { Grid } from "@mui/material"
import { useNavigate, useSearchParams } from "react-router-dom"

import { images, icons } from '../../Assets/AssetHelper.js'
import UserType from '../../Components/Organisms/Auth/Signup/UserType'
import UserInfo from '../../Components/Organisms/Auth/Signup/UserInfo'
import VerificationCode from '../../Components/Organisms/Auth/Signup/VerificationCode'
import { withTranslation } from "react-i18next"
import CreateAccountOptions from "../../Components/Organisms/Auth/Signup/CreateAccountOptions.jsx"
import { useEffect, useState } from "react"

const SignupTemplate = ({ t, SignupApi, setOtp, verify, verifyOtp, countdown, setCountdown, resendVerification, setVerify }) => {

    const navigate = useNavigate()
    const types = [
        {
            text: t("Signup.customer"),
            image: icons.Customer,
            name: "customer"
        },
        {
            text: t("Signup.dive_club"),
            image: icons.DiveClub,
            name: "dive_club"
        },
        // {
        //     text: t("Signup.freelancer"),
        //     image: icons.Freelancer,
        //     name: "freelancer"
        // }
    ]
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsType, setSearchParamsType] = useState({ userType: null, index: null });
    
    const changeUserType = (type, i) => setSearchParams({ userType: type, chooseAccountOpt: '', index: i })
    const changeChooseAccountOpt = () => setSearchParams({ ...searchParams, chooseAccountOpt: 'email'});

    useEffect(() => {
      searchParams?.get("index") && setSearchParamsType({
        userType: searchParams?.get("userType"), index: searchParams?.get("index")
      });
    }, [searchParams])
    
    return (
        <Grid container>
            <Grid item md={6} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                <img
                    src={images.LoginBg}
                    alt='Login'
                    width='100%'
                    // height='100%'
                    height='100vh'
                    style={{
                        minHeight: '100vh',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container item xs={12} pb={12} pt={10} sx={{ paddingInlineStart: '50px' }}>
                    <img
                        src={icons.BackArrow}
                        alt='BackArrow'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            // searchParams?.get("userType") !== null ?
                            // setSearchParams({usertype:null,index:null}) : 
                            localStorage.removeItem("newAccountEmail");
                            navigate(-1)
                            setVerify(false)
                            if (localStorage.getItem("end_date") != null) localStorage.removeItem("end_date");
                        }}
                    />
                </Grid>
                <Grid container alignContent='center'>
                    <Grid container item xs={12} justifyContent='center' mb={3} sx={{display:{xs:'none',md:'inherit'}}}>
                        <img
                            src={icons.LogoLogin}
                            alt='Logo'
                        />
                    </Grid>
                    {searchParams?.get("userType") === null && searchParams?.get("chooseAccountOpt") === null &&
                        <UserType
                            types={types}
                            changeUserType={changeUserType}
                            t={t}
                        />
                    }    
                    {searchParams?.get("userType") !== null && searchParams?.get("chooseAccountOpt").length == 0 &&
                        <CreateAccountOptions 
                            changeChooseAccountOpt={changeChooseAccountOpt} 
                        />
                    }
                    {searchParams?.get("chooseAccountOpt") === "email" && !verify ?
                            <UserInfo
                                types={types}
                                searchParamsType={searchParamsType}
                                SignupApi={SignupApi}
                                t={t}
                            /> 
                        : searchParams?.get("chooseAccountOpt") === "email" && verify ?
                            <VerificationCode
                                t={t}
                                setOtp={setOtp}
                                verifyOtp={verifyOtp}
                                countdown={countdown}
                                setCountdown={setCountdown}
                                SendOtp={resendVerification}
                                type='new_account'
                            />
                            : null
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

export default withTranslation()(SignupTemplate)