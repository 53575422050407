import { AUTHLoading, DeleteAccountSuccess } from "./Actions";
import { reduxRequest } from "../../../Common/utils/reduxRequest";

export const DeleteAccountRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AUTHLoading,
    successFunction: DeleteAccountSuccess,
    url: "accounts",
    method: "DELETE",
    action: variables?.action,
    failAction: variables?.failAction,
    body: variables?.body,
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
