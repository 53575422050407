import { Grid, Typography } from "@mui/material";

import { icons } from "../../../../Assets/AssetHelper.js";
import "./DetailsPanels.css";

const Requirments = ({ t, diveDetails, isMd }) => {
  const requirment = [
    {
      text: t("Details.certificate"),
      value: diveDetails?.minimum_certificate?.name || "None",
    },
    {
      text: t("Details.min_dives"),
      value: diveDetails?.min_dives || "None",
    },
    {
      text: t("Details.organization"),
      value: diveDetails?.minimum_certificate?.organization?.name || "None",
    },
    {
      text: t("Details.min_age"),
      value: (diveDetails?.min_age && `+${diveDetails?.min_age}`) || "None",
    },
  ];

  return (
    <Grid
      container
      className={isMd ? "mb-20px pr-pl-20px" : "mb-20px panels-container"}
    >
      <Grid container className="mb-20px">
        <Typography
          component="h1"
          style={{
            fontSize: { xs: "18px", md: "22px" },
            fontWeight: "bold",
            color: "var(--font-colorv f)",
          }}
        >
          {t("Details.requirment")}
        </Typography>
      </Grid>
      {requirment?.map((item, i) => (
        <Grid container item xs={12} md={6} className="mb-10px" key={i}>
          <Grid item md={0.5} sx={{ display: { xs: "none", md: "block" } }}>
            <img src={icons.GreenCheck} alt="check" />
          </Grid>
          <Grid item xs={12} md={11.5}>
            <Typography
              display="inline"
              sx={{
                color: "var(--font-color)",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {item?.text}
            </Typography>
            <Typography
              display="inline"
              sx={{
                color: "var(--font-color)",
                fontSize: "18px",
                fontWeight: "500",
                marginInlineStart: "10px",
              }}
            >
              {item?.value}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Requirments;
