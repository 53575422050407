import { Grid, Typography, Divider, List, ListItemAvatar, Avatar, ListItemText, ListItem, ListItemButton, useTheme, useMediaQuery } from "@mui/material"
import { useNavigate } from 'react-router-dom'

import { icons } from "../../../../Assets/AssetHelper"
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import React, { useEffect, useState } from "react"

function LoginStep2({ t, setIsAuth, setStep, resAccounts }) {
    const navigate = useNavigate();
    const googleAccounts = localStorage.getItem("GoogleAccounts");
    // const accounts = JSON.parse(localStorage.getItem('Accounts')).filter(
    //     item => item.email === localStorage.getItem('ActiveEmail')
    // )
    // const accounts = resAccounts;
    const [accounts, setAccounts] = useState([...resAccounts]);    
    useEffect(() => {
        googleAccounts ? setAccounts(JSON.parse(googleAccounts)) : setAccounts(resAccounts);
    }, [googleAccounts])
    

    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const handleListItemClick = (event, index) => {       
        setSelectedIndex(index);
        localStorage.setItem("firebase_node_path", accounts[index]?.firebase_node_path);
        localStorage.setItem("firebase_auth_token", accounts[index]?.firebase_auth_token);
        localStorage.setItem("ActiveToken", accounts[index]?.token)
        localStorage.setItem("ActiveUserId", accounts[index]?.userId)
        localStorage.setItem("ActiveAvatar", accounts[index]?.avatar)
        localStorage.setItem("ActiveUserType", accounts[index]?.user_type)
        localStorage.setItem("ActiveEmail", accounts[index]?.email)
    };
    const handleChoose = () => {
        // let accountsArr = JSON.parse(localStorage.getItem('Accounts'));
        // const selectedId = localStorage.getItem("ActiveUserId");
        // const selectedEmail = localStorage.getItem("ActiveEmail");

        // let newAccounts = [];
        // accountsArr.map((accountItem)=>{
        //     if(selectedEmail == accountItem.email && selectedId == accountItem.userId){
        //         newAccounts.push(accountItem)
        //     }
        //     if(selectedEmail != accountItem.email){
        //         newAccounts.push(accountItem)
        //     }
        // })
                
        // localStorage.setItem("Accounts", JSON.stringify(newAccounts));
        setIsAuth(true)
        navigate('../', { replace: true })
    }
    const LoginStep2Content = () => {
        return(<>
            <Grid container item xs={12} justifyContent='center' mb={3} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                <img src={icons.LogoLogin} alt='Logo' />
            </Grid>
            <Grid container item xs={12} justifyContent='start' mt={2} paddingX={'5%'} sx={{ display: { xs: 'inherit', md: 'none' } }}>
                <Grid item xs={'auto'} onClick={()=>setStep(1)}>
                    <img src={icons.BackArrow} alt='Logo' />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='center' mb={2}>
                <Typography sx={{
                    fontSize: { xs: '24px', md: '28px' },
                    fontWeight: 'bold',
                    color: '#272726'
                }}>
                    {t("Login.welcome_back")}
                </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent='center' margin='0px auto 24px'>
                <Typography sx={{
                    fontSize: { xs: '18px', md: '24px' },
                    fontWeight: '500',
                    color: '#272726',
                    textAlign: 'center'
                }}>
                    {/* {t("Login.which")} */}
                    {t("Login.which1")} <br />
                    {t("Login.which2")}
                </Typography>
            </Grid>
            <Grid container justifyContent='center'>
                <List sx={{
                    // selected and (selected + hover) states
                    '&& .Mui-selected, && .Mui-selected:hover': {
                        bgcolor: '#fdefd1',
                        borderRadius: '8px'
                    },
                    width: '400px',
                    maxWidth: '100%',
                    // hover states
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: '#fdefd1',
                        borderRadius: '8px'
                    }
                }}>
                    {accounts?.length > 0 && accounts?.map((account, i) => {
                        return (
                            <React.Fragment key={i}>
                                <ListItemButton
                                    alignItems="flex-start"
                                    selected={selectedIndex === i}
                                    onClick={(event) => handleListItemClick(event, i)}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={account?.avatar}
                                            sx={{ width: '72px', height: '72px', marginInlineEnd: '14px' }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography sx={{
                                                fontSize: '18px',
                                                fontWeight: 'bold'
                                            }}
                                            >
                                                {`${account.firstname} ${account.lastname ? account.lastname : ''}`}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        color: '#272726',
                                                        overflowWrap: 'anywhere'
                                                    }}
                                                >
                                                    {account.email}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        color: '#f0b70d'
                                                    }}
                                                >
                                                    {account.user_type === "customer" ? "Customer account" : "Dive club account"}
                                                </Typography>
                                            </React.Fragment>} />
                                </ListItemButton>
                                {i !== accounts?.length - 1 &&
                                    <Divider component="li" sx={{ marginY: '28px' }} />}
                            </React.Fragment>
                        )
                    })}
                </List>
            </Grid>
        </>)
    }

    return (
        <Grid container item xs={12} md={6} alignContent={!isMd ? 'center' : null} justifyContent={isMd ? 'center' : null} height={'100vh'}>
            {
                !isMd
                ? <LoginStep2Content />
                : <Grid item xs={12}>
                    <LoginStep2Content />
                </Grid>
            }
            <Grid container justifyContent='center' alignItems='end' mt={{ xs: 15, md: 4 }}>
                <PrimaryButton
                    text={t("Signup.done")}
                    classBtn='orange'
                    // style={{
                    //     marginTop: '32px'
                    // }}
                    click={handleChoose}
                    sx={{
                        fontSize: {
                            xs: '20px',
                            md: '24px'
                        },
                        borderRadius: {
                            xs: '100px',
                            md: '10px'
                        },
                        height: {
                            xs: '60px',
                            md: '72px'
                        },
                        width: {
                            xs: '90%',
                            md: '50%'
                        },
                        maxWidth: '434.69px'
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default LoginStep2