import { Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";

import { icons } from "../../../Assets/AssetHelper";
// import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton"
import NormalTabs from "../../../Components/Atoms/Tabs/NormalTabs";
import CarouselCard from "../../../Components/Molecules/Carousels/Children/CarouselCard";
import ReviewModal from "../../../Components/Organisms/Modals/Reviews/ReviewModal";
import CustomPagination from "../../../Components/Atoms/Pagination/Pagination";

const OrdersTemplate = ({
  t,
  orders,
  getOrders,
  createReview,
  deleteOrder,
  addEditCart,
  payOrder,
  handleFilterScopeChange,
  page,
  count,
  handlePageChange,
}) => {
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState();
  const [itemId, setItemId] = useState();
  const [type, setType] = useState(0);
  // const [filterScope, setFilterScope] = useState("upcoming")
  // const ordersTabOptions = [
  //     {
  //         id: 0,
  //         label: "Diving",
  //         name: "dive_trip"
  //     },
  //     {
  //         id: 1,
  //         label: "Courses",
  //         name: "dive_course"
  //     },
  //     {
  //         id: 2,
  //         label: "Liveaboard",
  //         name: "live_aboard"
  //     }
  // ]

  const ordersTabOptions = [
    {
      id: 0,
      label: t("Booking.all"),
      name: "all",
    },
    {
      id: 1,
      label: t("Booking.waiting"),
      name: "waiting",
    },
    {
      id: 2,
      label: t("Booking.upcoming"),
      name: "upcoming",
    },
    {
      id: 3,
      label: t("Booking.in_progress"),
      name: "in_progress",
    },
    {
      id: 4,
      label: t("Booking.finished"),
      name: "finished",
    },
  ];

  const handleClose = () => setOpen(false);

  const handleClick = (order, item) => {
    setOrderId(order);
    setItemId(item);
    setOpen(true);
  };

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
    // getOrders({
    //   filter_scope: ordersTabOptions.find((item) => item.id === newValue).name,
    //   // "activity_type": ordersTabOptions.find(item => item.id === newValue).name
    // });
    handleFilterScopeChange(
      `${ordersTabOptions.find((item) => item.id === newValue).name}`
    );
  };

  // const handleFilterChange = (value) => {
  //     setFilterScope(value)
  //     getOrders({
  //         "filter_scope": value,
  //         "activity_type": ordersTabOptions.find(item => item.id === type).name
  //     })
  // }

  return (
    <Grid
      container
      mt={6}
      mb={6}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
      }}
    >
      <Grid item xs={12} mb={4} p={{ xs: 1, md: 0 }}>
        <img
          src={icons.BookingLarge}
          alt=""
          style={{ marginInlineEnd: "16px" }}
        />
        <Typography
          display="inline"
          sx={{
            color: "var(--font-color)",
            fontSize: "var(--home-header-font)",
            fontWeight: "bold",
          }}
        >
          {t("Booking.booking")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NormalTabs
          TabsOptions={ordersTabOptions}
          value={type}
          change={handleTypeChange}
          ContainerStyle=""
          TabStyle="type-btn"
          // onclick={() => console.log(type)}
        />
      </Grid>
      <Divider sx={{ width: "100%", marginBottom: "32px" }} />
      {/* <Grid item xs={12} mb={3.5} mt={3}>
                <PrimaryButton
                    text="Upcoming"
                    classBtn={filterScope === 'upcoming' ? 'selected-btn' : 'unselected-btn'}
                    style={{ marginInlineEnd: '16px' }}
                    click={() => handleFilterChange("upcoming")}
                />
                <PrimaryButton
                    text="Finsihed"
                    classBtn={filterScope === 'finished' ? 'selected-btn' : 'unselected-btn'}
                    click={() => handleFilterChange("finished")}
                />
            </Grid> */}
      <Grid container item xs={12}>
        {orders?.length > 0 ? (
          orders?.map((order) => {
            return (
              <Grid item xs={12} md={4} key={order?.id}>
                <CarouselCard
                  t={t}
                  item={order}
                  handleClick={handleClick}
                  deleteOrder={deleteOrder}
                  addEditCart={addEditCart}
                  payOrder={payOrder}
                />
              </Grid>
            );
          })
        ) : (
          <Grid container alignContent="center">
            <Grid container item xs={12} justifyContent="center" mt={10} mb={6}>
              <img src={icons.Empty} alt="empty" />
            </Grid>
            <Grid container item xs={12} justifyContent="center" mb={5}>
              <Typography
                sx={{
                  fontSize: "21.6px",
                  fontWeight: "500",
                  color: "#aaa",
                }}
              >
                {t("Booking.empty")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {count > 12 && (
        <Grid container item xs={12} justifyContent="center">
          <CustomPagination
            count={Math.ceil(count / 12)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
      <ReviewModal
        t={t}
        open={open}
        handleClose={handleClose}
        createReview={createReview}
        orderId={orderId}
        itemId={itemId}
      />
    </Grid>
  );
};

export default OrdersTemplate;
