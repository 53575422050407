import { axiosFn, handleError } from "../index.js";
import * as URL from "../URLs";

export async function addGeneralComplaint(reservation_data) {
  return await axiosFn("post", URL.GENERAL_COMPLAINT, reservation_data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function addAppComplaint(reservation_data) {
  return await axiosFn("post", URL.APP_COMPLAINT, reservation_data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
