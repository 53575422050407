import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function createCertificateAPI(data) {
    return await axiosFn("post", URL.CERTIFICATE, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function editCertificateAPI(id, data) {
    return await axiosFn("put", URL.CERTIFICATE + id, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function deleteCertificateAPI(id) {
    return await axiosFn("delete", URL.CERTIFICATE + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
