import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { DiveExpertsTopRatedAPI } from "../../../Contexts/APIs/Dive-Experts/DiveExperts";
import DiveExpertsTemplate from "../../../Templates/Main/DiveExperts/DiveExperts.Template.jsx";
import { CityAPI } from "../../../Contexts/APIs/Lookups/City";
import Loader from "../../../Components/Atoms/Loader/Loader.js";

const DiveExpertsPage = ({ t, getFavorites }) => {
  const [locationArray, setLocationArray] = useState([]); // array passed to search
  const [diveExperts, setDiveExperts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cityKeyword, setCityKeyword] = useState("");
  const [locationValue, setLocationValue] = useState(""); // for search choosen obj
  const [locationInputValue, setLocationInputValue] = useState("");

  /* pagination states */
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getDiveExperts = async (filter) => {
    setLoader(true);
    const { res, err } = await DiveExpertsTopRatedAPI({
      with_available_dive_activities: true,
      page_size: 12,
      ...filter,
    });
    if (res) {
      setDiveExperts(res?.data?.dive_experts);
      setCount(res?.extra?.total_count);
      setPage(res?.extra?.page_number);
      setLoader(false);
    }
    if (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  const getCities = async (filter) => {
    const { res, err } = await CityAPI(filter);
    if (res) {
      const array = [];
      res?.data?.cities?.forEach((location) => {
        array.push({
          id: location.id,
          mainTitle: location.name,
          subTitle: location.country.name,
        });
      });
      setLocationArray(array);
    }
    if (err) toast.error(err);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      cityKeyword && getCities({ keyword: cityKeyword });
    }, 500);
    return () => clearTimeout(timeId);
  }, [cityKeyword]);

  useEffect(() => {
    const requestPayload = {
      page_number: page,
      ...(locationValue && { city_id: locationValue.id }),
    };
    getDiveExperts({...requestPayload});
  }, [page, locationValue]);
  return (
    <>
      <Loader open={loader} />
      <DiveExpertsTemplate
        t={t}
        diveExperts={diveExperts}
        getDiveExperts={getDiveExperts}
        count={count}
        page={page}
        handlePageChange={handlePageChange}
        getFavorites={getFavorites}
        locationArray={locationArray}
        setCityKeyword={setCityKeyword}
        locationValue={locationValue}
        setLocationValue={setLocationValue}
        locationInputValue={locationInputValue}
        setLocationInputValue={setLocationInputValue}
      />
    </>
  );
};

export default DiveExpertsPage;
