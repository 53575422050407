import { Dialog, Divider, Grid, Rating, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
// import * as Yup from 'yup'
import CloseIcon from "@mui/icons-material/Close";

import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";

const ReviewModal = ({
  t,
  open,
  handleClose,
  createReview,
  orderId,
  itemId,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "auto",
          width: "442px",
          padding: "20px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Grid container mb={1}>
        <Grid item xs={11}>
          <Typography
            sx={{
              fontSize: "var(--medium-font)",
              fontWeight: "bold",
              color: "var(--font-color)",
            }}
          >
            {t("Review.review")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Grid>
      </Grid>
      <Grid container>
        <Formik
          initialValues={{
            rate_activity: "",
            review_activity: "",
            rate_instructor: "",
            review_instructor: "",
            rate_branch: "",
            review_branch: "",
          }}
          // validationSchema={
          //     Yup.object().shape({
          //         currentPassword: Yup.string().required(t("validation.password")).min(8, t("validation.password_short")),
          //         newPassword: Yup.string().required(t("validation.password")).min(8, t("validation.password_short")),
          //         repeatPassword: Yup.string().test(t("validation.match"), t("validation.must_match"), function (value) {
          //             return this.parent.newPassword === value
          //         })
          //     })
          // }
          onSubmit={async (values) => {
            const data = {
              review: {
                activity_rating: values.rate_activity,
                activity_comment: values.review_activity,
                instructor_rating: values.rate_instructor,
                instructor_comment: values.review_instructor,
                activity_branch_rating: values.rate_branch,
                activity_branch_comment: values.review_branch,
              },
            };
            await createReview(orderId, itemId, data);
            handleClose();
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form style={{ width: "100%" }}>
              <Grid item xs={12} mb={1}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#848383",
                  }}
                >
                  {t("Review.rate_activity")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={Rating}
                  precision={1}
                  name="rate_activity"
                  sx={{ fontSize: "62px" }}
                />
                <ErrorMessage name="rate_activity" component="div" style={{ color: '#d32f2f' }}/>
              </Grid>
              <Field
                as={TextFieldInput}
                name="review_activity"
                placeholder={t("Review.comment")}
                label={t("Review.comment")}
                className="login-input"
                gridClass="grid-input"
                multiline={true}
                rows={5}
              />
              <ErrorMessage name="review_activity" component="div" style={{ color: '#d32f2f' }}/>
              <Divider sx={{ marginBottom: "20px", marginTop: "16px" }} />
              <Grid item xs={12} mb={1}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#848383",
                  }}
                >
                  {t("Review.rate_instructor")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={Rating}
                  precision={1}
                  name="rate_instructor"
                  sx={{ fontSize: "62px" }}
                />
                <ErrorMessage name="rate_instructor" component="div" style={{ color: '#d32f2f' }}/>
              </Grid>
              <Field
                as={TextFieldInput}
                name="review_instructor"
                placeholder={t("Review.comment")}
                label={t("Review.comment")}
                className="login-input"
                gridClass="grid-input"
                multiline={true}
                rows={5}
              />
              <ErrorMessage name="review_instructor" component="div" style={{ color: '#d32f2f' }}/>
              <Divider sx={{ marginBottom: "20px", marginTop: "16px" }} />
              <Grid item xs={12} mb={1}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#848383",
                  }}
                >
                  {t("Review.rate_center")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={Rating}
                  precision={1}
                  name="rate_branch"
                  sx={{ fontSize: "62px" }}
                />
                <ErrorMessage name="rate_branch" component="div" style={{ color: '#d32f2f' }}/>
              </Grid>
              <Field
                as={TextFieldInput}
                name="review_branch"
                placeholder={t("Review.comment")}
                label={t("Review.comment")}
                className="login-input"
                gridClass="grid-input"
                multiline={true}
                rows={5}
              />
              <ErrorMessage name="review_branch" component="div" style={{ color: '#d32f2f' }}/>
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                text={t("Review.submit")}
                classBtn="orange-btn"
                style={{ width: "100%", height: "51px", marginTop: "22px" }}
                click={submitForm}
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  );
};

export default ReviewModal;
