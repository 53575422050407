import { toast } from "react-toastify";
import { useEffect } from "react";
import { Grid, Typography } from "@mui/material"
import { useState } from "react"
import { QRCodeCanvas } from 'qrcode.react';

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import InviteMolecule from "../../../Molecules/Profile/InviteYourFriends/InviteMolecule"
import { FetchInvitationLinkAPI } from "../../../../Contexts/APIs/Auth/InvitationLink";
import { sendInvitationEmail } from "../../../../Contexts/APIs/Profile/Profile";

const InviteYourFriends = ({ t }) => {

    const [email, setEmail] = useState('')
    const [shareLink, setShareLink] = useState('')
    const [invitationLink, setInvitationLink] = useState('')
    const emailSubject = 'You’re invited to join Scubers App!'
    const emailBody = `Hello,%0A

Welcome to Scubers, Discover amazing dive destinations, and plan your dive trips and courses. All in a single App and a few taps away.%0A

The following link will take you to the registration page, where you can create an account.%0A


${invitationLink}%0A


Best Regards%0A
Scubers Service Team%0A`
    
    const data = [
        {
            btnText: t("Profile.send"),
            textType: 'email',
            fieldText: t("Profile.invite_email"),
            label: t("Profile.invite_email"),
            value: email,
            getValue: ((e) => {
                setEmail(e.target.value)
            }),
            handleClick: (async() => {
                var mailformat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                if (mailformat.test(email)) {
                    // window.open(`mailto:${email}?subject=${emailSubject}&body=${emailBody}`)
                    const {res, err} = await sendInvitationEmail({email: email});                   
                    if(res){
                        setEmail('');
                        toast.success(t("Profile.email_sent_successfully"))
                    }
                    err && toast.error(err);                    
                }
                else {
                    toast.error(t("General.entered_invalid_email"));
                }
            })
        },
        {
            btnText: t("Profile.share"),
            textType: 'text',
            fieldText: '',
            value: shareLink,
            disabled: true,
            getValue: ((e) => {
                setShareLink(e.target.value)
            }),
            handleClick: (() => {
                navigator.clipboard.writeText(shareLink)
            })
        }
    ]

    const getInvitationLink = async () => {
        const { res, err } = await FetchInvitationLinkAPI()
        if (res) {
            setInvitationLink(res?.invitation_link)
            setShareLink(res?.invitation_link)
        }
        if (err) toast.error(err)
    }

    const downloadQR = () => {
        const canvas = document.getElementById("qrCode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        getInvitationLink()
    }, []);

    return (
        <Grid container>
            <Grid item xs={12} md={8} sx={{ paddingInlineEnd: '16px' }}>
                <Typography sx={{
                    fontSize: 'var(--xlarge-font)',
                    fontWeight: '800',
                    color: 'var(--font-color)',
                    paddingBottom: '16px'
                }}>
                    {t("Profile.invite_friends")}
                </Typography>
                {data.map((item, i) => {
                    return (
                        <InviteMolecule item={item} key={i} />
                    )
                })}
            </Grid>
            <Grid container item xs={12} md={4} alignContent='start' mt={5}>
                <Grid container item xs={12} justifyContent='center'>
                    {invitationLink &&
                        <QRCodeCanvas
                            id='qrCode'
                            value={invitationLink}
                            size={200}
                        />
                    }
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                    <Typography sx={{
                        fontSize: 'var(--normal-font)',
                        fontWeight: '500',
                        color: 'var(--font-color)',
                        paddingTop: '17px'
                    }}>
                        {t("Profile.scan")}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                    <PrimaryButton
                        text={t("Profile.download")}
                        classBtn='white-btn'
                        style={{
                            width: '137px',
                            height: '48px',
                            marginTop: '16px'
                        }}
                        click={downloadQR}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InviteYourFriends