import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material'

import PrimaryButton from '../../../../Atoms/Buttons/Primary/PrimaryButton'
import BasicDatePicker from '../../../../Atoms/Input/DatePicker copy/DatePicker'
import TextFieldInput from '../../../../Atoms/Input/TextField/TextFieldInput'
import FilterItem from '../../../../Molecules/Search/FilterItem'

const FilterModal = ({
    t,
    open,
    onClose,
    filters,
    date,
    setDate,
    from,
    setFrom,
    to,
    setTo,
    getActivityData,
    customClass }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={'dialog'} className={customClass}>
            <DialogTitle id={'dialog'}>
                <Typography>
                    {t("Search.results")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    /* Filter Items */
                }
                {filters.map((filter, i) => {
                    return <FilterItem
                        key={i}
                        items={filter.items}
                        text={filter.text}
                        handleCheckBoxChange={filter.handleCheckBoxChange}
                    />
                })}
                {/* Price filter */}
                <FilterItem
                    t={t}
                    type='price'
                    text={t("Search.price")}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    getActivityData={getActivityData}
                />


                {/* Date Filter */}
                <BasicDatePicker
                    placeholder={t("Search.date")}
                    disablePast={true}
                    value={date}
                    className="border-r-5px location-search mt-10px"
                    errorText={t("General.date_field_required")}
                    handelChange={
                        (newValue) => setDate(newValue)
                    }
                    setValue={() => setDate(null)}
                />
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    text={t("Search.show")}
                    click={onClose}
                    classBtn='date-btn'
                />

            </DialogActions>
        </Dialog>
    );
}

export default FilterModal;