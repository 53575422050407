import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function PointsActionsAPI() {
    return await axiosFn("get", URL.POINTS_ACTIONS)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function PointsCongigAPI() {
    return await axiosFn("get", URL.POINTS_CONFIG)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
