import { Grid, IconButton, Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';

import "./DatePicker.css"
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export default function BasicDatePicker({
  inputFormat = "DD/MM/YYYY",
  value,
  setValue,
  placeholder,
  className,
  name,
  id,
  redBorder,
  errorText,
  renderDay,
  handelChange,
  disableFuture,
  disablePast,
  onClear,
  maxDate = null }) {
  const [selectedDate, setSelectedDate] = useState(value ? value : null);
  useEffect(() => {
    const timeId = setTimeout(() => {
      selectedDate && setValue(dayjs(selectedDate).format("YYYY/MM/DD"));
      selectedDate && handelChange(selectedDate);
    }, 2000);
    return () => clearTimeout(timeId);
    // setSelectedDate(value ? value : null);
  }, [selectedDate]);
  // console.log(value, 'value');
  return (
    <Grid
      className={className}
    >
      <Stack
        style={{
          position: "relative",
          width: "100%",
          height: '100%'
        }}
      >
        <DatePicker
          disablePast={disablePast}
          disableFuture={disableFuture}
          name={name}
          id={id}
          value={selectedDate && dayjs(selectedDate)}
          onChange={(newValue) => {
            setSelectedDate(newValue);
          }}
          label={placeholder}
          format={inputFormat}
          renderDay={renderDay}
          maxDate={maxDate}
          inputProps={{ readOnly: true }}
          openTo={'year'}
          views={['year', 'month', 'day']}
          slotProps={{
            field: {
              readOnly: true
            }
          }}
        />
        {redBorder === 'red-border' && (
          <span
            style={{
              fontSize: "13px",
              color: "#d32f2f",
              textAlign: "left",
              width: "97%",
              margin: "auto"
            }}
          >
            {errorText}
          </span>
        )}
        <Stack
          sx={{
            height: '100%', position: "absolute",
            right: "2rem",
            padding: "0px"
          }}
          justifyContent={'center'}
          alignItems='center'
        >
          {value && value != '' && (
            <IconButton
              onClick={(e) => {
                setValue(null);
                setSelectedDate(null)
                e.stopPropagation();
                e.preventDefault();
                onClear && onClear()
              }}
            >
              <ClearIcon sx={{ color: "rgba(0, 0, 0, 0.4)" }} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Grid>
  )
}