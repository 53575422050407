import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function BranchesAPI(filter) {
    return await axiosFn("get", URL.BRNCHES + makeFilterString(filter))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
} 

export async function BranchDetailsAPI(id) {
    return await axiosFn("get", URL.BRNCHES + `/${id}` )
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}