import { Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import HelpForms from '../../../Molecules/Profile/HelpAndSupport/HelpForms'

const Report = ({t, userEmail}) => {    
  return (
    <Grid container sx={{ maxWidth: '425px' }}>
        <Grid item xs={12} mb={3}>
            <Typography sx={{
                fontSize: 'var(--xlarge-font)',
                fontWeight: '800',
                color: 'var(--font-color)'
            }}>
                {t("Profile.report")}
            </Typography>
        </Grid>
        <HelpForms targetPage={'ReportAppIssuse'} userEmail={userEmail} />
    </Grid>
  )
}

export default withTranslation()(Report)