import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function ReviewCreateAPI(orderId,itemId, data) {
    return await axiosFn("post", URL.REVIEWS + `/${orderId}` + URL.CART_ITEM + itemId, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
