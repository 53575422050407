import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function DeleteOrderAPI(id, data) {
    return await axiosFn("put", URL.ORDERS + '/' + id + URL.UPDATE_PAYMENT, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
