import { useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import PointsMolecule from "../../../../Molecules/Profile/ViewProfile/PointsMolecule";
import BasicInfoMolecule from "../../../../Molecules/Profile/ViewProfile/BasicInfoMolecule.jsx";
import CertificatesMolecule from "../../../../Molecules/Profile/ViewProfile/CertificatesMolecule";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import ChangePassword from "../../../Modals/Profile/ChangePassword";
import SocialMedia from "../../../../Molecules/Profile/ViewProfile/SocialMedia";
// import { withTranslation } from "react-i18next";

const ViewProfile = ({ t, profileData, changePassword, levels, getLevels }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <Grid container gap={1}>
      <PointsMolecule t={t} profileData={profileData} levels={levels}  getLevels={getLevels}/>
      <BasicInfoMolecule t={t} profileData={profileData} />
      {profileData?.user_type === "customer" && (
        <CertificatesMolecule t={t} profileData={profileData} />
      )}
      {profileData?.user_type === "dive_club" && (
        <SocialMedia t={t} profileData={profileData} />
      )}
      <Grid container mt={3} gap={1} justifyContent="space-between">
        <Grid container item xs={12} md={7} gap={1}>
          <PrimaryButton
            text={t("Profile.edit_profile")}
            classBtn="profile-btn"
            style={{ backgroundColor: "var(--primary-color)", color: "white" }}
            sx={{ width: { xs: "100%", md: "45%" } }}
            click={() => navigate("/profile/edit-profile")}
          />
          <PrimaryButton
            text={t("ForgetPassword.change_password")}
            classBtn="profile-btn"
            style={{ border: "solid 1px #faa100" }}
            sx={{ width: { xs: "100%", md: "45%" } }}
            click={() => setOpen(true)}
          />
        </Grid>
        <Grid container item xs={12} md={3} justifyContent="end">
          <PrimaryButton
            style={{ width: "100%" }}
            text={t("Profile.delete_account")}
            classBtn="delete-btn"
            click={() => navigate("/profile/delete-account")}
          />
        </Grid>
      </Grid>
      <ChangePassword
        t={t}
        open={open}
        handleClose={handleClose}
        changePassword={changePassword}
      />
    </Grid>
  );
};

export default ViewProfile;
