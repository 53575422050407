import { Divider, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import HelpForms from '../../../Molecules/Profile/HelpAndSupport/HelpForms'

import "./FooterModals.css";

const ContactModal = ({open, modalHead, handleClose}) => {    
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        container
        className="terms-privacy-modal guest-contact-modal"
        justifyContent="space-evenly"
        alignContent="center"
        position="relative"
      >
        <CloseIcon onClick={handleClose} className="modal-close-icon"/>  
        <Grid container item className="terms-privacy-modal-container" xs={12} justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="dev" className="modal-head">
            {modalHead}            
            <Divider className="terms-privacy-modal-divider"/>
          </Typography>

        <HelpForms targetPage={'GuestFaqs'} handleClose={handleClose} />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ContactModal