import { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Rating,
  Grid,
  Skeleton,
} from "@mui/material";
import { PanoramaFishEye, Circle } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

import { icons } from "../../../../Assets/AssetHelper";
import { AddFavoriteAPI } from "../../../../Contexts/APIs/Favorites/AddFavorite";
import { DeleteFavoriteAPI } from "../../../../Contexts/APIs/Favorites/DeleteFavorite";
import { toast } from "react-toastify";

export default function CarouselSmallCard({
  t,
  item,
  type,
  getFavorites,
  getFilteredFavorites,
  tapOption,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname;
  const [isFavorite, setIsFavorite] = useState(
    item?.is_favourite_by_current_user ||
      item?.favourite?.is_favourite_by_current_user
  );

  const handleClick = () => {
    type === "experts"
      ? window.location.pathname == "/favorites"
        ? navigate(`/dive-experts/${item?.favourite?.id}`)
        : navigate(`/dive-experts/${item?.id}`)
      : window.location.pathname == "/favorites"
      ? navigate(`/dive-centers/${item?.favourite_id}`)
      : navigate(`/dive-centers/${item?.id}`);
  };

  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: path === "/favorites" ? item?.favourite_id : item?.id,
            favourite_type: type === "experts" ? "DiveExpert" : "Branch",
            //item?.user_type === "dive_center" ? "DiveCenter" : "DiveExpert"
          },
        });
        await getFavorites({ page_size: 100 });
        (await getFilteredFavorites) &&
          getFilteredFavorites({ favourite_type: tapOption });
        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: item?.id,
            favourite_type: type === "experts" ? "DiveExpert" : "Branch",
            //item?.user_type === "dive_center" ? "DiveCenter" : "DiveExpert"
          },
        });
        await getFavorites({ page_size: 100 });
        (await getFilteredFavorites) &&
          getFilteredFavorites({ favourite_type: tapOption });
        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };

  return (
    <Card style={{ boxShadow: "none", margin: "0 10px", position: "relative" }}>
      {item?.media?.length > 0 ||
      item?.favourite?.media?.length > 0 ||
      item?.avatar ||
      item?.favourite?.avatar ? (
        <CardMedia
          component="img"
          image={
            item?.media?.[0]?.url ||
            item?.favourite?.media?.[0]?.url ||
            item?.avatar ||
            item?.favourite?.avatar
          }
          height={300}
          alt="Card"
          style={{ borderRadius: "10px", cursor: "pointer" }}
          onClick={handleClick}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          height={300}
          sx={{ bgcolor: "grey", borderRadius: "10px", cursor: "pointer" }}
          animation={false}
          onClick={handleClick}
        />
      )}
      <img
        src={isFavorite ? icons.FavouriteYes : icons.FavouriteNo}
        alt="favourite"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
        onClick={handleFavoriteChange}
      />
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Typography
            sx={{ fontSize: { xs: 14, md: 30 }, fontWeight: "bold" }}
            gutterBottom
          >
            {item?.name || item?.favourite?.name}
          </Typography>
          {/* <Grid container mb={2} mt={2}>
                        <Grid container item xs={1}>
                            <img src={icons.LocationBlackIcon} alt='location' />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography
                                sx={{ fontSize: 18, marginInlineStart: '7px', color:'#212934' }}
                            >
                                {item?.area?.name || item?.area || item?.favourite?.area?.name}
                            </Typography>
                        </Grid>
                    </Grid> */}
          <Grid container alignItems="center">
            <Rating
              readOnly
              value={
                parseFloat(item?.avg_rate) ||
                parseFloat(item?.favourite?.avg_rate)
              }
              sx={{ color: "#3ed181" }}
              size="small"
              precision={0.5}
              icon={<Circle fontSize="inherit" />}
              emptyIcon={
                <PanoramaFishEye fontSize="inherit" sx={{ color: "#3ed181" }} />
              }
            />
            <Typography
              color="#3ed181"
              sx={{ paddingInlineStart: "8px", fontSize: 18 }}
              display="inline"
            >
              {item?.reviews_count || item?.favourite?.reviews_count}
            </Typography>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
