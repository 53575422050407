export const languages = [
  {
    id: 0,
    name: "English",
    abbr: "en",
  },
  {
    id: 1,
    name: "French",
    abbr: "fr",
  },
  {
    id: 2,
    name: "German",
    abbr: "de",
  },
  {
    id: 3,
    name: "Italian",
    abbr: "it",
  },
  {
    id: 4,
    name: "Dutch",
    abbr: "nl",
  },
  {
    id: 5,
    name: "Russian",
    abbr: "ru",
  },
];
