import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function GetProfileAPI() {
  const type = localStorage.getItem("ActiveUserType") == 'super_admin' ? 'admin_user' : localStorage.getItem("ActiveUserType");
  const id = localStorage.getItem("ActiveUserId");
  return await axiosFn("get", URL.PROFILE + `${type}/${id}` + URL.SHOW)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function UpdateProfileAPI(customer_id, data) {
  return await axiosFn(
    "put",
    `${URL.UPDATE_PROFILE}?customer_id=${customer_id}`,
    data
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function DeleteProfileAPI(data) {
  return await axiosFn("delete", URL.DELETE_PROFILE, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function sendInvitationEmail(data) {
  return await axiosFn("post", URL.INVITATION_MAILER, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}