import { useState, useEffect } from "react"
import { toast } from "react-toastify"

import DiveCentersTemplate from "../../../Templates/Main/DiveCenters/DiveCenters.Template.jsx"
import { DiveCentersTopRatedAPI } from "../../../Contexts/APIs/Dive-Centers/DiveCenters"
import { CityAPI } from "../../../Contexts/APIs/Lookups/City"
import { BranchesAPI } from "../../../Contexts/APIs/Branches/branches.js"
import Loader from "../../../Components/Atoms/Loader/Loader.js"

const DiveCentersPage = ({ t, getFavorites }) => {

    const [locationArray, setLocationArray] = useState([])  // array passed to search
    const [diveCenters, setDiveCenters] = useState([])
    /* pagination states */
    const [page, setPage] = useState(1)
    const [count, setCount] = useState()
    const [openLoader, setOpenLoader] = useState(false);

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const getDiveCenters = async (filter) => {
        setOpenLoader(true);
        const { res, err } = await BranchesAPI(filter)
        if (res) {
            setDiveCenters(res?.data?.branches)
            setCount(res?.extra?.total_count)
            setPage(res?.extra?.page_number)
            setOpenLoader(false);
        }
        if (err) toast.error(err)
        setOpenLoader(false);
    }

    const getCities = async (filter) => {
        const { res, err } = await CityAPI(filter)
        if (res) {
            const array = []
            res?.data?.cities?.forEach((location) => {
                array.push({
                    id: location.id,
                    mainTitle: location.name,
                    subTitle: location.country.name
                })
            })
            setLocationArray(array)
        }
        if (err) toast.error(err)
    }

    
    useEffect(() => {
        getDiveCenters({ "page_size": 12, "page_number": page })                
    }, [page])

    return (
        <>
            <Loader open={openLoader} />
            <DiveCentersTemplate
                t={t}
                diveCenters={diveCenters}
                getDiveCenters={getDiveCenters}
                count={count}
                page={page}
                handlePageChange={handlePageChange}
                getFavorites={getFavorites}
                locationArray={locationArray}
                getCities={getCities}
            />
        </>
    )
}

export default DiveCentersPage