import { Divider, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { icons } from "../../../Assets/AssetHelper";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { toast } from "react-toastify";
import { GetSystemConfigurationKey } from "../../../Contexts/APIs/Lookups/SystemConfigurations";

function SummeryOrganism({ t, cart, redeem, getCart }) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [currency, setCurrency] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [canCheckout, setCanCheckout] = useState(false);
  let minRedeem = 0;
  const getSysConfMinRedeem = async () => {
    const { res, err } = await GetSystemConfigurationKey(
      "min_amount_to_redeem"
    );
    if (res) {
      minRedeem = parseInt(res?.data?.system_configuration?.value);
    }
    if (err) toast.error(err);
  };

  const redeemPoints = async () => {
    getSysConfMinRedeem();
    setTimeout(async () => {
      if (cart?.available_points && cart?.available_points >= minRedeem) {
        await redeem({
          points: {
            amount: cart?.available_points,
          },
        });
        getCart();
      }else{
        toast.error(t('Cart.points-warning'));
      }
    }, 500);
  };

  const removePoints = async () => {
    await redeem({
      points: {
        amount: 0,
      },
    });
    getCart();
    minRedeem = 0;
  };

  useEffect(() => {
    setCartItems(cart?.cart_items);
    if (cartItems?.length > 0) {
      setCurrency(cartItems[0]?.purchasable?.currency?.lookup_key);
      const array = [];
      cart?.cart_items?.forEach((item) => {
        // if (item.reservation_participants.length === item.quantity) array.push(true)
        if (item.need_more_info) array.push(true);
        else array.push(false);
      });
      const isAllParticipantsEntered = array.every((i) => {
        return i === false;
      });
      setCanCheckout(isAllParticipantsEntered);
    } else setCanCheckout(false);
  }, [cart, cartItems]);

  return (
    <Grid
      mt={path === "/checkout" ? 0 : 7}
      p={1.5}
      sx={{
        height: "auto",
        borderRadius: "8px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15)",
        border: "solid 1px #ececec",
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "#212121",
            fontSize: "var(--large-font)",
            fontWeight: "600",
            marginInlineStart: "11px",
            marginTop: "17px",
            marginBottom: "23px",
          }}
        >
          {t("Cart.summery")}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} mb={1.5} style={{ position: 'relative' }}>
                <TextFieldInput   // Promo Input
                    placeholder={t("Cart.enter")}
                    className='login-input'
                    value={promo}
                    onChange={(e) => setPromo(e.target.value)}
                /> */}
      {/* TODO:check promo with backend */}
      {/* <PrimaryButton
                    text="Send"
                    style={{
                        position: 'absolute',
                        right: '0px',
                        top: '10px',
                        textTransform: 'capitalize'
                    }}
                />
            </Grid> */}
      {/* <Grid item xs={12} style={{ position: 'relative' }}>
                <TextFieldInput   // points input 
                    placeholder={t("Cart.add")}
                    className='login-input'
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                />
                <PrimaryButton
                    text="Redeem"
                    style={{
                        position: 'absolute',
                        right: '0px',
                        top: '10px',
                        textTransform: 'capitalize'
                    }}
                    // click={redeemPoints}
                />
            </Grid> */}

      {cart?.points == 0 && (
        <Grid
          container
          alignItems="center"
          px="28px"
          bgcolor="#f9f9f9"
          borderRadius="10px"
        >
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 14, fontWeight: "bold", color: "#181918" }}
            >
              {`You have ${cart?.available_points} point`}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="end">
            <PrimaryButton
              text="Redeem"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#f6b119",
                textTransform: "capitalize",
                padding: "0px !important",
                cursor:
                  cart?.available_points && cart?.available_points >= minRedeem
                    ? "pointer"
                    : "default",
              }}
              click={redeemPoints}
            />
          </Grid>
        </Grid>
      )}
      {cart?.points != 0 && (
        <Grid
          container
          alignItems="center"
          px="28px"
          bgcolor="#f9f9f9"
          borderRadius="10px"
        >
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 14, fontWeight: "bold", color: "#181918" }}
            >
              {`${cart?.points} point added`}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="end">
            <PrimaryButton
              text="Remove"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#C21703",
                textTransform: "capitalize",
                padding: "0px !important",
              }}
              click={removePoints}
            />
          </Grid>
        </Grid>
      )}
      <Divider
        sx={{
          marginTop: "16px",
          marginBottom: "25px",
        }}
      />
      <Grid item xs={12} mb={2}>
        <Typography
          sx={{
            color: "var(--font-color)",
            fontSize: "var(--normal-font)",
            fontWeight: "bold",
          }}
        >
          {t("Cart.pay")}
        </Typography>
      </Grid>
      {/* {console.log(cartItems,'cartItems')} */}
      {cartItems?.length > 0 &&
        cartItems?.map((item) => {
          return (
            <React.Fragment key={item?.id}>
              <Grid container mb={0.5} key={item.id}>
                <Grid item xs={6}>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "500", color: "#b4b5b4" }}
                  >
                    {item?.purchasable?.dive_activity?.name}
                  </Typography>
                </Grid>
                <Grid container item xs={6} justifyContent="end">
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#6c6c6c",
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.amount} {currency}
                  </Typography>
                </Grid>
              </Grid>
              {path === "/checkout" &&
                item?.purchasable?.dive_activity?.need_confirmation && (
                  <Grid container mb={0.5}>
                    <img src={icons.Stop} alt="confirmation" />
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "500",
                        marginInlineStart: "10px",
                        color: "#e2574c",
                      }}
                    >
                      {t("Checkout.confirm")}
                    </Typography>
                  </Grid>
                )}
            </React.Fragment>
          );
        })}
      {cart?.points != 0 && (
        <Grid container my={1}>
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "500", color: "#b4b5b4" }}
            >
              {t("Details.points_discount")}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="end">
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#6c6c6c",
                textTransform: "uppercase",
              }}
            >
              - {parseFloat(cart?.discount)?.toFixed(2)} {currency}
            </Typography>
          </Grid>
        </Grid>
      )}
      {cart?.vat_percentage > 0 ? (
        <Grid container>
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "500", color: "#b4b5b4" }}
            >
              {t("Details.vat")}
              {` (${
                cart?.vat_percentage === "undefined" || !cart?.vat_percentage
                  ? "N/A"
                  : cart?.vat_percentage
              }%)`}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="end">
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#6c6c6c",
                textTransform: "uppercase",
              }}
            >
              {cart?.vat_value} {currency}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <Divider
        sx={{
          marginTop: "14px",
          marginBottom: "13px",
        }}
      />
      <Grid container>
        <Grid item xs={6}>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold", color: "#424242" }}
          >
            {t("Details.total")}
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="end">
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              color: "#212121",
              textTransform: "uppercase",
            }}
          >
            {cart?.price_with_vat} {currency}
          </Typography>
        </Grid>
      </Grid>
      {path !== "/checkout" && (
        <Grid container mt={3}>
          <PrimaryButton
            text={t("Cart.checkout")}
            classBtn="book-btn"
            click={() => navigate("/checkout")}
            disabled={!canCheckout}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default SummeryOrganism;
