import { Grid, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper";
import CartCard from "../../../Components/Organisms/Cards/CartCard";
import SummeryOrganism from "../../../Components/Organisms/Reservation/SummeryOrganism";
import EmpytCart from "../../../Components/Organisms/Reservation/EmpytCart";

const CartTemplate = ({
  t,
  cart,
  deleteCart,
  redeem,

  getFavorites,
  getCart,
  favorites,
}) => {
  return cart?.cart_items?.length == 0 ? (
    <EmpytCart />
  ) : (
    <Grid
      container
      mb={8}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
        px: { xs: 2 },
      }}
    >
      <Grid item xs={12} md={8} sx={{ paddingInlineEnd: "22px" }}>
        <Grid container alignContent="center" mb={6} mt={6}>
          <img src={icons.Cart} alt="cart" />
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: "var(--home-header-font)",
              fontWeight: "bold",
              marginInlineStart: "16px",
            }}
          >
            {t("Cart.cart")}
          </Typography>
        </Grid>
        <Grid container>
          {cart?.cart_items?.map((item) => {
            return (
              <CartCard
                key={item?.id}
                t={t}
                card={item}
                deleteCart={deleteCart}
                getFavorites={getFavorites}
                orderId={item?.id}
                cardId={item?.purchasable?.dive_activity_id}
                cart={cart}
                favorites={favorites}
              />
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <SummeryOrganism t={t} cart={cart} redeem={redeem} getCart={getCart} />
      </Grid>
    </Grid>
  );
};

export default CartTemplate;
