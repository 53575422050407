import { Box } from "@mui/material";
import { images } from "../../../Assets/AssetHelper";
import React from "react";

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src={images.Logo}
      sx={{ width: { xs: "65px", md: "120px" }, ...sx }}
    />
  );
}
