import { Grid, Typography } from "@mui/material"
import "./DetailsPanels.css";

const InfoPanel = (props) => {
    const { title, body, isMd } = props;
    return (
        <Grid container className={isMd ? 'mb-20px pr-pl-20px' : "mb-30px panels-container"}>
            <Grid container className="mb-20px">
                <Typography style={{fontWeight: "bold", fontSize: {xs:'18px',md:"22px"}, color: "var(--font-color)"}}>
                    {title}
                </Typography>
            </Grid>
            <Grid item container md={9}>
                <Typography style={{fontWeight: "bold", fontSize: {xs:'16px',md:"18px"}, color: "var(--secound-f-color)"}}>
                    {body}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default InfoPanel