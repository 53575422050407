import { Tabs, Tab } from '@mui/material'
import { withTranslation } from "react-i18next"
import "./NormalTabs.css"

function NormalTabs({
    orientation,
    value,
    change,
    ContainerStyle,
    TabsOptions,
    TabStyle,
    onclick
}) {

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return (
        <Tabs
            orientation={orientation}
            variant="scrollable"
            value={value}
            onChange={change}
            aria-label="Vertical tabs example"
            className={ContainerStyle}
        >
            {TabsOptions.map((option, index) => (
                <Tab
                    key={index}
                    className={TabStyle}
                    icon={option.icon}
                    label={option.label}
                    disabled={option.disabled}
                    {...a11yProps(index)}
                    onClick={() => onclick ? onclick(index) : null}
                />
            ))}
        </Tabs>
    )
}

export default withTranslation()(NormalTabs)