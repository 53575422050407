import { Grid, Typography } from "@mui/material"
import { useState } from "react"
import { icons } from "../../../../Assets/AssetHelper"
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import QrModal from "../../Modals/Details/QrModal"

const QrPanel = ({ t, qrCode, qrCodeMsg, hideQrCode }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false)
    return (
        <Grid container mb={3} sx={{
            padding: '18px',
            borderRadius: '8px',
            border: 'solid 0.5px #979797'
        }}>
            <Grid item xs={9}>
                <Typography sx={{
                    color: "black",
                    fontSize: "var(--large-font)",
                    fontWeight: "bold"
                }}>
                    {t("Details.qr")}
                </Typography>
                <Typography sx={{
                    color: "var(--font-color)",
                    fontSize: "var(--text-font)"
                }}>
                    {t("Details.qr_text")}
                </Typography>
            </Grid>
            <Grid container item xs={2} justifyContent='end' alignContent='center'>
                <img src={icons.QrSmall} alt='' style={{ width: '42px', height: '42px' }} />
            </Grid>
            <Grid container item xs={1} justifyContent='end'>
                <PrimaryButton
                    btnType="icon"
                    startIcon={<img src={icons.GoArrow} alt='' />}
                    click={() => setOpen(true)}
                />
            </Grid>
            <QrModal
                t={t}
                open={open}
                handleClose={handleClose}
                qrCode={qrCode}
                qrCodeMsg={qrCodeMsg}
                hideQrCode={hideQrCode}
            />
        </Grid>
    )
}

export default QrPanel