import { Gallery } from "react-grid-gallery";
import "./Gallery.css";
import { useEffect, useState } from "react";

const GridGallery = (props) => {
  const { slider } = props;
  const [images, setImages] = useState([]);
  useEffect(() => {
    var arr = [];
    slider?.map((slide, index) => {
      if (slide && index < 7 && slide?.media_option == "default") {
        arr.push({
          src: slide?.url,
          thumbnail: slide?.url,
          thumbnailWidth: 320,
          thumbnailHeight: 172,
        });
        setImages(arr);
      }
      if (arr.length == 7) {
        Object.assign(arr[6], {
          tags: [
            {
              title: `${"+"} ${slider.length - 5 + " photo"}`,
              value: `${"+"} ${slider.length - 5 + " photo"}`,
            },
          ],
        });
      }
    });
  }, [slider]);
  return (
    <Gallery
      images={images}
      enableLightbox={true}
      backdropClosesModal
      enableImageSelection={false}
    />
  );
};

export default GridGallery;
