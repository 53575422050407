import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import "./App.css";
import RoutesPage from "./RoutesPage.js";
import { ShowCartAPI } from "./Contexts/APIs/Carts/ShowCart";
import { RedeemPointsAPI } from "./Contexts/APIs/Carts/RedeemPoints";
import { OrdersListAPI } from "./Contexts/APIs/Orders/Orders";
import { FavoritesListAPI } from "./Contexts/APIs/Favorites/FavoritesList";
import { GetSystemConfigurationKey, SystemConfigurationsAPI } from "./Contexts/APIs/Lookups/SystemConfigurations";
import { DeleteItemAPI } from "./Contexts/APIs/Carts/DeleteItem";
import { LogoutAPI } from "./Contexts/APIs/Auth/Logout";
import { GetProfileAPI } from "./Contexts/APIs/Profile/Profile";
import { Firebase, auth, database, signOutFirebase } from "./Contexts/Firebase/Firebase";
import { checkToken } from "./Contexts/APIs/Auth/checkToken";
import { SetDeviceFCMAPI } from "./Contexts/APIs/Auth/SetDeviceFCM.js";
import { languages } from "./languages";
import { ref, onValue, getDatabase, child, get } from "firebase/database";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "./Components/Atoms/Loader/Loader.js";
import { CreateAuthToken } from "./Contexts/APIs/Auth/createAuthToken.js";
import Cookies from "js-cookie";
import { Provider } from 'react-redux';
import { store } from './services/store';

function App(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(0);
  const [cart, setCart] = useState({});
  const [orders, setOrders] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [mapKey, setMapKey] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [minRedeem, setMinRedeem] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState();
  const [ordersPage, setOrdersPage] = useState(1);
  const [ordersCount, setOrdersCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [ordersOpenLoader, setOrdersOpenLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ordersPaginationCount, setOrdersPaginationCount] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const [counter, setCounter] = useState(0);
  const firebaseAuthToken = Cookies.get("firebase_auth_token");

  window.maxFileSize = 1024 * 1024;
  window.fileSize = "* Maximum Upload Size is 1 MB Per File";
  const getProfileData = async () => {
    setOpenLoader(true);
    const { res, err } = await GetProfileAPI();
    if (res) {
      localStorage.setItem("notificationsEnabled", res?.data?.user?.enable_push);
      setProfileData(res?.data?.user);
      setCart([]);
      setTimeout(() => {
        setOpenLoader(false);
      }, 250);
    }
    if (err) {
      toast.error(err);
      setTimeout(() => {
        setOpenLoader(false);
      }, 250);
    }
  };

  const getCart = async () => {
    setOpenLoader(true);
    const { res, err } = await ShowCartAPI();
    if (res) {
      setCart(res?.data?.cart);
      if (res?.data?.cart?.cart_items?.length == 0 && window.location.pathname.includes("checkout")) {
        window.location.assign("/cart");
      }
      setOpenLoader(false);
    }
    if (err) {
      toast.error(err);
      setOpenLoader(false);
    }
  };

  const getOrders = async (filter) => {
    setOrdersOpenLoader(true);
    const { res, err } = await OrdersListAPI(filter);
    if (res) {
      setOrders(res?.data?.orders);
      setOrdersPage(res?.extra?.page_number);
      setOrdersOpenLoader(false);
      setOrdersPaginationCount(res?.extra?.total_count);
    }
    if (err) toast.error(err);
    setOrdersOpenLoader(false);
  };

  const deleteCart = async (id) => {
    const { res, err } = await DeleteItemAPI(id);
    if (res) {
      setCart(res?.data?.cart)
      setCartCount(res?.data?.cart_items_count);
    }
    if (err) toast.error(err);
  };

  const getFavorites = async (filter) => {
    const { res, err } = await FavoritesListAPI(filter);
    if (res) {
      setFavorites(res?.data?.favourites);
      // setFavoritesCount(res?.extra?.total_count);
    }
    if (err) toast.error(err);
  };

  const redeem = async (data) => {
    const { res, err } = await RedeemPointsAPI(data);
    if (res) toast.success(res);
    if (err) toast.error(err);
  };
  const getSystemConfiguration = async () => {
    setMapKey(process.env.REACT_APP_GOOGLE_API_MAP_KEY);
  };

  const onLanguageHandle = (event) => {
    let newLang = event;
    setLanguage(newLang);
    props.i18n.changeLanguage(languages[newLang].abbr);
    localStorage.setItem("lang", languages[newLang].abbr);
  };

  const getData = async () => {
    const { res, err } = await checkToken();
    if (res.message == "success") {
      getProfileData();
      getSystemConfiguration();
    }
  };

  const Logout = async () => {
    const { res, err } = await LogoutAPI();
    if (res) {
      toast.success(res?.message);
      await Firebase.SignoutFirebase();
    }
    if (err) toast.error(err);
  };

  // async function handlePermission() {
  //   try {
  //     const result = await navigator.permissions.query({
  //       name: "notifications",
  //     });
  //     return permissionQuery(result);
  //   } catch (er) {
  //     return er;
  //   }
  // }

  // function permissionQuery(result) {
  //   console.debug({ result });
  //   var newPrompt;

  //   if (result.state == "granted") {
  //     // notifications allowed, go wild
  //     Firebase.getFCMToken();
  //     if (localStorage.getItem("ActiveToken")) {
  //       setTimeout(() => {
  //         SetDeviceFCMAPI({
  //           device: {
  //             fcm_token: localStorage.getItem("fcm_token"),
  //           },
  //         });
  //       }, 5000);
  //     }
  //   }
  //   // else if (result.state == 'prompt') {
  //   //   // we can ask the user
  //   //   newPrompt = Notification.requestPermission();
  //   //   console.log(result.state)
  //   // }
  //   else if (result.state == "denied") {
  //     // notifications were disabled
  //     // localStorage.setItem("fcm_token", window.navigator.userAgent)
  //   }

  //   result.onchange = () => console.debug({ updatedPermission: result });

  //   return newPrompt || result;
  // }

  useEffect(() => {
    setTimeout(() => {
    if ((localStorage.getItem("ActiveToken") && localStorage.getItem("ISVERIFIED")) || localStorage.getItem("ActiveUserType") == 'super_admin') {
      localStorage.setItem("USER_TYPE", "user");
      getData();
    } else {
      localStorage.setItem("USER_TYPE", "guest");
      if (localStorage.getItem("ActiveToken")) localStorage.removeItem("ActiveToken");
      localStorage.removeItem("roles");
      localStorage.removeItem("descendant_user_roles");
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      localStorage.removeItem("firebase_node_path");
      localStorage.removeItem("firebase_auth_token");
    }
    if (localStorage.getItem("lang")) {
      setLanguage(languages.find((item) => item.abbr === localStorage.getItem("lang")).id);
      props.i18n.changeLanguage(localStorage.getItem("lang"));
    } else {
      localStorage.setItem("lang", "en");
      props.i18n.changeLanguage("en");
    }
    }, 800);
  }, [localStorage.getItem("ActiveToken")]);

  // useEffect(() => {
  //   setOpenLoader(true);
  //   Firebase.getFCMToken();
  //   const reg = async () => {
  //     await Firebase.SignInFirebase();
  //     setTimeout(() => { setOpenLoader(false) }, 200);
  //   };
  //   if (localStorage.getItem("ActiveToken")) {
  //     reg();
  //   } else {
  //     setTimeout(() => { setOpenLoader(false) }, 200);
  //   }
  // }, [localStorage.getItem("ActiveToken")]);

  // const handleSignInFirebase = async () => {
  //   Firebase.getFCMToken();
  //   const reg = async () => {
  //     await Firebase.SignInFirebase(firebaseAuthToken || localStorage.getItem('firebase_auth_token'));
  //   };
  //   if (localStorage.getItem("ActiveToken")) {
  //     reg();
  //   }
  // }

  // const createAuthToken = async () => {
  //   const { res, err } = await CreateAuthToken()
  //   if (res) {
  //     Cookies.set("lastFirebaseRequestEpoch", Date.now());
  //     localStorage.setItem('firebase_auth_token', res?.data?.firebase_auth_token);
  //     handleSignInFirebase();
  //   }
  // }

  // useEffect(() => {
  //   let firstTimeFlag = true;
  //   let lastFirebaseRequestEpoch = Date.now();
  //   const lastRequestEpoch = Cookies.get('lastFirebaseRequestEpoch');
  //   const userToken = localStorage.getItem('ActiveToken');
  //   const checkCookie = () => {
  //   if (userToken && (!lastRequestEpoch || (lastFirebaseRequestEpoch <= lastRequestEpoch + 60 * 60 * 1000))) {
  //       createAuthToken()
  //     }
  //   };
  //   if (userToken && firstTimeFlag)
  //   {
  //     createAuthToken();
  //     firstTimeFlag = false;
  //   }
  //   const interval = setInterval(() => {
  //     lastFirebaseRequestEpoch = Date.now();
  //     checkCookie();
  //   }, 60 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   localStorage.getItem("firebase_node_path") && setTimeout(() => {
  //     setOpenLoader(true);
  //     const db = database;
  //     const userNode = localStorage.getItem("firebase_node_path");
  //     const starCountRef = ref(db, `${userNode}`);
  //     setTimeout(() => {
  //       onValue(starCountRef, (snapshot) => {
  //         const data = snapshot.val();
  //         setFavoritesCount(data?.favourites_count);
  //         setNotificationsCount(data?.notification_count);
  //         setOrdersCount(data?.orders_count);
  //         setCartCount(data?.cart_items_count);
  //         setTimeout(() => { setOpenLoader(false) }, 200);
  //       });
  //     }, 200);
  //   }, 800);
  // }, [localStorage.getItem("firebase_node_path")]);

  useEffect(() => {
    // start listening on the node when sign in firebase
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser && localStorage.getItem("firebase_node_path")) {
        const userNode = localStorage.getItem("firebase_node_path");
        const starCountRef = ref(database, userNode);
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          setFavoritesCount(data?.favourites_count);
          setNotificationsCount(data?.notification_count);
          setOrdersCount(data?.orders_count);
          setCartCount(data?.cart_items_count);
        });
      }
    });
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   handlePermission();
  // }, [Notification.permission]);
  // console.log(searchParams.get("data"), "data");

  const permenentLink = searchParams.get("data");
  useEffect(() => {
    if (permenentLink == "invitation") {
      localStorage.setItem("invitationCode", searchParams.get("data_id"));
      navigate("/signup", { replace: true });
    }
    if (permenentLink == "DiveActivity") {
      navigate(`/details-view/${searchParams.get("data_id")}`, {
        replace: true,
      });
    }
    if (permenentLink == "Branch") {
      navigate(`/dive-centers/${searchParams.get("data_id")}`, {
        replace: true,
      });
    }
    if (permenentLink == "DiveExpert") {
      navigate(`/dive-experts/${searchParams.get("data_id")}`, {
        replace: true,
      });
    }
  }, [permenentLink]);

  return (
    <>
      <Loader open={openLoader} />
      <Provider store={store}>
        <RoutesPage
          t={t}
          language={language}
          onLanguageHandle={onLanguageHandle}
          cart={cart}
          getCart={getCart}
          redeem={redeem}
          orders={orders}
          getOrders={getOrders}
          favorites={favorites}
          getFavorites={getFavorites}
          mapKey={mapKey}
          setIsAuth={setIsAuth}
          languages={languages}
          deleteCart={deleteCart}
          Logout={Logout}
          profileData={profileData}
          getProfileData={getProfileData}
          ordersPage={ordersPage}
          setOrdersPage={setOrdersPage}
          ordersCount={ordersCount}
          cartCount={cartCount}
          ordersPaginationCount={ordersPaginationCount}
          ordersOpenLoader={ordersOpenLoader}
          setProfileData={setProfileData}
          favoritesCount={favoritesCount}
          notificationsCount={notificationsCount}
          openLoader={openLoader}
          setOpenLoader={setOpenLoader}
          isAuth={isAuth}
        />
      </Provider>
    </>
  );
}

export default App;
