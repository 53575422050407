import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

// auth/:social_platform/:user_type/register
export async function GoogleRegister(social_platform, user_type, data) {
  return await axiosFn(
    "post",
    `${URL.AUTH}${social_platform}/${user_type}${URL.REGISTER}`,
    data
  )
    .then((res) => {
      // console.log(res);
      return {
        res: res.data.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function GoogleLogin(data, social_platform) {
  return await axiosFn(
    "post",
    `${URL.AUTH}${social_platform}/${URL.LOGIN}`,
    data
  )
    .then((res) => {
      // console.log(res);
      return {
        res: res.data.data,
        err: null,
      };
    })
    .catch(handleError);
}
