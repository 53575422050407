import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DiveCExpertDetailsAPI } from "../../../Contexts/APIs/Dive-Experts/ExpertDetails";
import { ListDiveActivitiesAPI } from "../../../Contexts/APIs/Dive-Activities/ListDiveActivities";
import { ReviewsListAPI } from "../../../Contexts/APIs/Reviews/ReviewsListing";
import { toast } from "react-toastify";
import ExpertDetailsTemplate from "../../../Templates/Main/DiveExperts/ExpertDetails.Template";
import Loader from "../../../Components/Atoms/Loader/Loader";
const ExpertDetailsPage = ({ t, getFavorites }) => {
  const { id } = useParams();
  const [expertDetails, setExpertDetails] = useState({});
  const [diveTrips, setDiveTrips] = useState([]);
  const [diveTripCount, setdiveTripsCount] = useState(0);
  const [courses, setCourses] = useState([]);
  const [coursesCount, setCoursesCount] = useState(0);
  const [liveaboards, setLiveaboards] = useState([]);
  const [liveaboardsCount, setLiveaboardsCount] = useState();
  const [reviews, setReviews] = useState({});
  const [loader, setLoader] = useState(false);

  const getExpertData = async () => {
    setLoader(true);
    const { res, err } = await DiveCExpertDetailsAPI(id);

    if (res) {
      setExpertDetails(res?.data?.user);
      setLoader(false);
    }
    if (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  const diveActivities = async (filter) => {
    const { res, err } = await ListDiveActivitiesAPI(filter);
    if (res)
      return { res: res?.data?.activities, count: res?.extra?.total_count };
    if (err) toast.error(err);
  };

  const getDiveTrips = async (filter) => {
    const { res, count } = await diveActivities({
      activity_type: "dive_trip",
      dive_expert_id: id,
      page_size: 6,
      ...filter,
    });
    if (filter?.page_number == 1) {
      setDiveTrips(res);
    } else {
      setDiveTrips((prev) => [...prev, ...res]);
    }
    setdiveTripsCount(count);
  };

  const getCourses = async () => {
    const { res, count } = await diveActivities({
      activity_type: "dive_course",
      dive_expert_id: id,
    });
    setCourses(res);
    setCoursesCount(count);
  };

  const getLiveaboards = async () => {
    const { res, count } = await diveActivities({
      activity_type: "live_aboard",
      dive_expert_id: id,
    });
    setLiveaboards(res);
    setLiveaboardsCount(count);
  };

  const getReviews = async () => {
    const { res, err } = await ReviewsListAPI(
      {},
      {
        review: {
          reviewee_id: id,
          reviewee_type: "DiveExpert",
          reviewer_type: "Customer",
        },
      }
    );
    if (res) setReviews(res?.data);
    if (err) toast.error(err);
  };
  const CustomRightArrow = ({ onClick,data ,getData,count}) => {
    return (
      <button
        onClick={(_) => {
          if (data?.length < count) {
            let page_number;
            if (Number.isInteger(data?.length / 6)) {
              page_number = Math?.ceil(data?.length / 6 + 1);
            } else {
              page_number = Math?.ceil(data?.length / 6);
            }
            getData({
              page_number,
            });
          }
            onClick(); // Call the carousel's default onClick function
        }}
        className="custom-right-arrow"
      ></button>
    );
  };
  useEffect(() => {
    getExpertData();
    getDiveTrips();
    getCourses();
    getLiveaboards();
    getReviews();
    getLiveaboards();
  }, []);

  return (
    <>
      <Loader open={loader} />
      {!loader && (
        <ExpertDetailsTemplate
          t={t}
          expertDetails={expertDetails}
          diveTrips={diveTrips}
          courses={courses}
          liveaboards={liveaboards}
          reviews={reviews}
          getFavorites={getFavorites}
          liveaboardsCount={liveaboardsCount}
          coursesCount={coursesCount}
          diveTripCount={diveTripCount}
          getDiveTrips={getDiveTrips}
          getCourses={getCourses}
          getLiveaboards={getLiveaboards}
          CustomRightArrow={CustomRightArrow}
        />
      )}
    </>
  );
};

export default ExpertDetailsPage;
