import Types from "./Types";

export const DeleteAccountSuccess = (payload) => ({
  type: Types.DELETE_ACCOUNT_SUCCESS,
  payload,
});

export const AUTHLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});
