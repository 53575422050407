import { Dialog, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeCanvas } from "qrcode.react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";

const QrModal = ({ t, open, handleClose, qrCode, qrCodeMsg, hideQrCode }) => {
  /*const downloadQR = () => {
    const canvas = document.getElementById("code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };*/

  // Wrap Downloaded QrCode with white border
  const downloadQR = () => {
    const canvas = document.getElementById("code");
    const pngUrl = new Image();
    pngUrl.src = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");


    pngUrl.onload = function() {
      canvas.width = pngUrl.naturalWidth;
      canvas.height= pngUrl.naturalHeight;

      const ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(pngUrl, 5, 5, ctx.canvas.width-10, ctx.canvas.height-10);

      ctx.beginPath(); 
      ctx.strokeStyle = '#fff';
      ctx.lineWidth = 10; 
      ctx.strokeRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      
      saveScreenshot(canvas);
    }
    function saveScreenshot(canvas) {
      let downloadLink = document.createElement("a");
      downloadLink.download = "qrCode.png";
      
      canvas.toBlob(function(blob) {
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.click();
      });
    };
  };
  // Wrap Downloaded QrCode with white border

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "auto",
          width: "442px",
          paddingX: "24px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Grid container pt={1} pb={8}>
        <Grid container item xs={12} justifyContent="end">
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Grid>
        {
          hideQrCode != true && <>
            <Grid container item xs={12} justifyContent="center" m={1} mt={7} mb={3}>
              {qrCode && <QRCodeCanvas id="code" value={qrCode} size={200} />}
            </Grid>
            {/* <Grid container item xs={12} mb={1} justifyContent="center">
              <Typography
                sx={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  fontSize: "var(--subtitle-font)",
                  fontWeight: "bold",
                  color: "var(--font-color)",
                }}
              >
                {qrCode}
              </Typography>
            </Grid> */}
          </>
        }
        {
          hideQrCode == true &&
          <Grid container item xs={12} justifyContent="center">
            <Typography
              sx={{
                fontSize: "var(--text-font)",
                color: "#7e8085",
                marginBottom: "14px",
                width: "300px",
                textAlign: "center",
              }}
            >
              {
                qrCodeMsg ? qrCodeMsg : t("Details.show_qr")
              }            
            </Typography>
          </Grid>
        }
        <Grid container item xs={12} justifyContent="center">
          <PrimaryButton
            text={t("Details.download")}
            classBtn="download-qr"
            click={downloadQR}
            disabled={qrCode === null}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default QrModal;
