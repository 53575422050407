import { axiosFn, handleError } from "../index";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function OrdersListAPI(filter) {
  return await axiosFn("get", URL.ORDERS + makeFilterString(filter))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function OrderDetailsAPI(id) {
  return await axiosFn("get", URL.ORDERS + "/" + id)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
