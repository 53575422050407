import { axiosFn, handleError } from "../../index"
import * as URL from "../../URLs"

export async function CheckExistsAPI(data) {
    return await axiosFn("post", URL.AUTH + URL.CHECK_EXISTS, data)
        .then((res) => { 
            return {
                res: res.data.data,
                err: null
            }
        })
        .catch(handleError)
}
