import { useState, useEffect } from "react";
import { Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { images } from "../../../Assets/AssetHelper.js";
import SelectBox from "../../../Components/Atoms/Select/Select";
import CustomPagination from "../../../Components/Atoms/Pagination/Pagination";
import SearchCard from "../../../Components/Organisms/Cards/SearchCard";
import DateModal from "../../../Components/Organisms/Modals/DateModal";
import SearchFilterSection from "../../../Components/Organisms/Search/SearchFilterSection";
import SearchResponsive from "../../../Components/Organisms/Responsive/SearchResponsive.jsx";
import dayjs from "dayjs";
import { withTranslation } from "react-i18next";
import "./Search.css";
import Loader from "../../../Components/Atoms/Loader/Loader.js";

const SearchTemplate = ({
  t,
  type,
  diveActivities,
  getCities,
  getLanguage,
  getFavorites,
  location,
  isLoading,
  pageNumber,
  activity_type,
  languagesCount,
  locationsCount,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const sortItems = [
    {
      id: 0,
      name: t("Search.price_high_low"),
      value: "price_high",
    },
    {
      id: 1,
      name: t("Search.price_low_high"),
      value: "price_low",
    },
    {
      id: 2,
      name: t("Search.rate_high_low"),
      value: "avg_rate",
    },
  ];

  const activities = [
    {
      text: t("HomePage.dive_trips"),
      image: images.Dive,
      image1: images.DiveWB,
      name: "dive_trip",
    },
    {
      text: t("HomePage.courses"),
      image: images.Courses,
      image1: images.CoursesWB,
      name: "dive_course",
    },
    {
      text: t("HomePage.liveboard"),
      image: images.Liveboard,
      image1: images.LiveboardWB,
      name: "live_aboard",
    },
  ];

  /* Activity Type states */
  const [typeSelected, setTypeSelected] = useState(type);
  const [typeTextSelected, setTypeTextSelected] = useState(activities.find((obj) => obj.name === type)?.text);
  /* pagination states */
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  /* Date Filter states */
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);
  /* Activity Data state */
  const [activityData, setActivityData] = useState([]);
  /* checkboxs filter states */
  // const [diveExperience, setDiveExperience] = useState([])
  const [languages, setLanguages] = useState([]);
  const [diveExperienceSelected, setDiveExperienceSelected] = useState([]);
  const [languagesSelected, setLanguagesSelected] = useState([]);
  // const [durationsSelected, setDurationsSelected] = useState([])
  const [reviewsSelected, setReviewsSelected] = useState([]);
  /* Price filter states */
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  /* Sort Select state */
  const routeLocation = useLocation();
  const [sortValue, setSortValue] = useState(sortItems?.[0]?.id);
  const sort = sortItems?.find((item) => item.value === sortValue)?.value;
  /* Search filter states */
  const [value, setValue] = useState(""); // for search choosen obj
  /* Location filter states */
  const [locationArray, setLocationArray] = useState([]); // array passed to search
  const [searchParams, setSearchParams] = useSearchParams();

  const [locationValue, setLocationValue] = useState(
    location?.state?.city
      ? {
          // id: location?.state?.city?.city?.id,
          id: location?.state?.city?.id,
          mainTitle: location?.state?.city?.name,
          subTitle: location?.state?.city?.city?.country?.name,
        }
      : null
  ); // for search choosen obj
  const [locationInputValue, setLocationInputValue] = useState(location?.state?.city ? location?.state?.city?.name : ""); // for search textbox input
  const [openFilter, setOpenFilter] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [isHotOffer, setIsHotOffer] = useState(routeLocation?.state?.searchHotOffers ? true : null);

  const filters = [
    {
      text: t("Search.dive_experience"),
      items: [
        { id: 1, name: t("Search.certified") },
        { id: 2, name: t("Search.non_certified") },
      ],
      handleCheckBoxChange: (event, type) => {
        if (type !== "all") {
          if (event.target.checked) setDiveExperienceSelected([...diveExperienceSelected, event.target.value]);
          if (!event.target.checked)
            setDiveExperienceSelected(diveExperienceSelected?.filter((item) => item !== event.target.value));
        } else {
          if (event.target.checked) setDiveExperienceSelected(event.target.value);
          if (!event.target.checked) setDiveExperienceSelected([]);
        }
      },
    },

    {
      text: t("Search.reviews"),
      items: [
        { id: 4, name: t("Search.4_stars_up") },
        { id: 3, name: t("Search.3_stars_up") },
        { id: 2, name: t("Search.2_stars_up") },
        { id: 1, name: t("Search.1_stars_up") },
      ],
      handleCheckBoxChange: (event, type) => {
        if (type !== "all") {
          if (event.target.checked) setReviewsSelected([...reviewsSelected, event.target.value]);
          if (!event.target.checked) setReviewsSelected(reviewsSelected.filter((item) => item !== event.target.value));
        } else {
          if (event.target.checked) setReviewsSelected(event.target.value);
          if (!event.target.checked) setReviewsSelected([]);
        }
      },
    },
  ];

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({ page_number: value });
  };

  const handleTypeChange = (type, text) => {
    navigate(`/search/${type}?page_number=${type == activity_type ? pageNumber : 1}`);
    setTypeSelected(activity_type || type);
    setTypeTextSelected(text);
    setPage(1);
  };

  const handleSortChange = (event) => {
    setSortValue(event.target.value);
    setPage(1);
  };

  const handleGetHotOffersCheck = () => {
    setIsHotOffer((prev) => !prev);
    setPage(1);
  };

  const getActivityData = async (
    typeValue = typeSelected,
    sort_Value = sort,
    is_hot_offer = isHotOffer,
    page_number = page,
    keyword = value,
    city = locationValue,
    diveExperienceSelected,
    languagesSelected,
    reviewsSelected,
    date
  ) => {
    setActivityData([]);
    const res = await diveActivities({
      activity_type: activity_type || typeValue,
      sort_by: sort_Value,
      is_hot_offer: is_hot_offer,
      page_number: pageNumber || page_number,
      keyword: keyword,
      /*
      area_id is used to store the location 
      which can be changed from filter search, 
      or when clicking on top diving destinations
      when we navigate from top diving destinations, city will be an object with that id
      when we search in filter, the city will be that id
      */
      area_id: city?.id ? city.id : city,
      dive_experience:
        diveExperienceSelected === "1"
          ? "certified"
          : diveExperienceSelected === "2"
          ? "not_certified"
          : diveExperienceSelected === ""
          ? ""
          : "all",
      language_ids: languagesSelected,
      review_stars: reviewsSelected,
      "date[from]": date ? dayjs(date).format("YYYY-MM-DD") : null,
      "price[from]": from,
      "price[to]": to,
    });
    setActivityData(res?.data?.activities);
    setPage(res?.extra?.page_number);
    setCount(res?.extra?.total_count);
  };

  const getDiveLocations = async (filter) => {
    const res = await getCities({
      page_size: 10,
      ...filter,
    });
    if (res) {
      const transformedLocations = res?.map((location) => ({
        id: location?.id,
        name: location?.name,
        countryName: location?.city?.country?.name,
      }));

      if (filter?.page_number === 1) {
        setLocationArray(transformedLocations);
      } else {
        setLocationArray([...locationArray, ...transformedLocations]);
      }
    }
  };

  const getLanguages = async (filter) => {
    const res = await getLanguage({
      page_size: 10,
      ...filter,
    });
    if (res) {
      if (filter?.page_number == 1) {
        setLanguages(res);
      } else {
        setLanguages([...languages, ...res]);
      }
    }
  };

  useEffect(() => {
    setTypeSelected(type);
  }, [type]);
  // console.log(locationValue, "locationValue");
  useEffect(() => {
    const array = languagesSelected?.map((item) => item?.value)?.join(",");
    const delayDebounceFn = setTimeout(() => {
      getActivityData(
        typeSelected,
        sort,
        isHotOffer,
        page,
        value,
        locationValue,
        diveExperienceSelected.toString(),
        array,
        // languagesSelected,
        reviewsSelected.toString(),
        date
      );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    value,
    page,
    typeSelected,
    sortValue,
    isHotOffer,
    locationValue,
    diveExperienceSelected,
    languagesSelected,
    reviewsSelected,
    date,
  ]);

  return isMd ? (
    <SearchResponsive
      sortItems={sortItems}
      activities={activities}
      typeSelected={typeSelected}
      page={page}
      count={count}
      date={date}
      setDate={setDate}
      activityData={activityData}
      from={from}
      setFrom={setFrom}
      to={to}
      setTo={setTo}
      sortValue={sortValue}
      locationArray={locationArray}
      locationValue={locationValue}
      setLocationValue={setLocationValue}
      locationInputValue={locationInputValue}
      setLocationInputValue={setLocationInputValue}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      openSort={openSort}
      setOpenSort={setOpenSort}
      filters={filters}
      handlePageChange={handlePageChange}
      handleTypeChange={handleTypeChange}
      handleSortChange={handleSortChange}
      getActivityData={getActivityData}
      t={t}
      getFavorites={getFavorites}
    />
  ) : (
    <Grid container mx={11.5} my={7.5} sx={{ width: "auto" }}>
      <Grid item xs={3} sx={{ paddingInlineEnd: "24px" }}>
        <SearchFilterSection
          filters={filters}
          setOpen={setOpen}
          date={date}
          setDate={setDate}
          t={t}
          value={value}
          setValue={setValue}
          locationArray={locationArray}
          locationValue={locationValue}
          setLocationValue={setLocationValue}
          locationInputValue={locationInputValue}
          setLocationInputValue={setLocationInputValue}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          getActivityData={getActivityData}
          languages={languages}
          getLanguages={getLanguages}
          onchange={(val) => setLanguagesSelected(val)}
          languagesSelected={languagesSelected}
          handleGetHotOffersCheck={handleGetHotOffersCheck}
          isHotOffer={isHotOffer}
          languagesCount={languagesCount}
          setLanguagesSelected={setLanguagesSelected}
          getDiveLocations={getDiveLocations}
          locationsCount={locationsCount}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          {/* Result Info */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "20px", fontWeight: "500", marginBottom: "9px" }}>
              {count} {typeTextSelected}
            </Typography>
          </Grid>
          {/* Result Sorting */}
          <Grid container item xs={6} justifyContent="end">
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>{t("Search.sort")}:</Typography>
            <SelectBox
              defaultValue={sortItems?.[0]?.id}
              style={{ width: "200px", height: "27px" }}
              className="sort-select"
              items={sortItems}
              value={sortValue}
              onchange={handleSortChange}
            />
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        {/* Activity Switching */}
        <Grid container mt={3} mb={4}>
          {isLoading ? (
            <Loader open={isLoading} />
          ) : (
            activities.map((activity, i) => (
              <Grid
                container
                sx={{
                  width: "207px",
                  height: "70px",
                  border: typeSelected === activity.name ? "2px solid" : "2px solid",
                  borderRadius: "12px",
                  marginInlineEnd: "20px",
                  cursor: "pointer",
                  borderColor: typeSelected === activity.name ? "primary.main" : "#9d9d9d",
                }}
                key={i}
                onClick={() => handleTypeChange(activity.name, activity.text)}
              >
                <Grid container item xs={5} justifyContent="center" alignContent="center">
                  <img
                    src={typeSelected === activity.name ? activity.image : activity.image1}
                    alt="activity"
                    width="44px"
                    height="44px"
                  />
                </Grid>
                <Grid container item xs={7} justifyContent="flex-start" alignContent="center">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: typeSelected === activity.name ? "#f6b119" : "#9d9d9d",
                    }}
                  >
                    {activity.text}
                  </Typography>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
        {/* Search Result cards */}
        <Grid container>
          {activityData?.map((card, i) => {
            return <SearchCard key={i} t={t} card={card} getFavorites={getFavorites} />;
          })}
        </Grid>
        {/* Pagination */}
        {count > 10 && (
          <Grid container justifyContent="center" mt={5}>
            <CustomPagination
              count={Math.ceil(count / 10)}
              size="large"
              shape="rounded"
              page={page}
              handleChange={handlePageChange}
              className="pagination"
            />
          </Grid>
        )}
      </Grid>
      <DateModal
        t={t}
        open={open}
        handelConfirm={() => {
          setOpen(false);
        }}
        handleClose={() => setOpen(false)}
        date={date}
        setDate={setDate}
        disablePast={true}
        openTo="day"
      />
    </Grid>
  );
};

export default withTranslation()(SearchTemplate);
