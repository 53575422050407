import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import CarouselOrganism from "../../../Components/Organisms/Carousels/CarouselOrganism";
import ActionsPanel from "../../../Components/Organisms/Details/DetailsPanels/ActionsPanel";
import GridGallery from "../../../Components/Organisms/Details/Gallery/Gallery";
import ReviewPanel from "../../../Components/Organisms/Details/DetailsPanels/ReviewPanel";
import { icons } from "../../../Assets/AssetHelper";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { DeleteFavoriteAPI } from "../../../Contexts/APIs/Favorites/DeleteFavorite";
import { AddFavoriteAPI } from "../../../Contexts/APIs/Favorites/AddFavorite";
import ShareModal from "../../../Components/Organisms/Modals/Details/ShareModal";

const CenterDetailsTemplate = ({
  t,
  centerDetails,
  diveTrips,
  courses,
  reviews,
  getFavorites,
  liveaoards,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: centerDetails?.id,
            favourite_type: "Branch", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: centerDetails?.id,
            favourite_type: "Branch", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };
  useEffect(() => {
    setIsFavorite(centerDetails?.is_favourite_by_current_user);
  }, [centerDetails]);
  return (
    <>
      {centerDetails?.media?.length > 0 && <div className="mt-30px">
        <GridGallery slider={centerDetails?.media} />
      </div>}
      <Grid
        container
        mt={6}
        mb={6}
        sx={{
          width: {
            xs: "100%",
            md: "90%",
          },
          marginX: "auto",
        }}
      >
        <ActionsPanel
          name={centerDetails?.name}
          isFavorite={isFavorite}
          rating={parseFloat(centerDetails?.avg_rate)}
          number={centerDetails?.reviews_count}
          type="centers"
          handleFavoriteChange={handleFavoriteChange}
          lat={centerDetails?.lat}
          long={centerDetails?.long}
          handleOpen={handleOpen}
        />
        <Grid item xs={12} mb={5} p={{ xs: 1, md: 0 }}>
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: "32px",
              fontWeight: "bold",
            }}
          >
            {t("Dive-Centers.about")}
          </Typography>
          <Typography sx={{ color: "var(--font-color)", fontSize: "18px" }}>
            {centerDetails?.about_us}
          </Typography>
        </Grid>
        {diveTrips?.length > 0 && (
          <CarouselOrganism
            t={t}
            type="card"
            items={diveTrips}
            activity_type="dive_trip"
            heading={t("HomePage.dive_trips")}
            customCarouselClass="expert_dive_trip"
            displaySeeAllBtn={false}
          />
        )}
        {courses?.length > 0 && (
          <CarouselOrganism
            t={t}
            type="card"
            items={courses}
            activity_type="dive_course"
            heading={t("Dive-Centers.diving_courses")}
            customCarouselClass="expert_dive_trip"
            displaySeeAllBtn={false}
          />
        )}
        {liveaoards?.length > 0 && (
          <CarouselOrganism
            t={t}
            type="card"
            items={liveaoards}
            activity_type="live_aboard"
            heading={t("Navbar.liveboards")}
            customCarouselClass="expert_dive_trip"
            displaySeeAllBtn={false}
          />
        )}
        <Grid container item xs={12} mb={1} p={{ xs: 1, md: 0 }}>
          <Typography
            sx={{
              fontSize: 32,
              fontWeight: "bold",
              color: "#272726",
            }}
          >
            {t("Dive-Centers.locations")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          mb={10}
          gap={{ xs: "5px" }}
          px={{ xs: 1, md: 2 }}
        >
          <Grid item xs={0.5} mt={0.5}>
            <img src={icons.LocationBlackIcon} alt="location" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              {centerDetails?.area?.name}, {centerDetails?.city?.name},{" "}
              {centerDetails?.country?.name}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" mt={1.5}>
            <PrimaryButton
              text={t("Edit_Trip.directions")}
              classBtn="location-btn"
              click={() =>
                window.open(
                  "https://maps.google.com?q=" +
                    centerDetails?.lat +
                    "," +
                    centerDetails?.long
                )
              }
            />
          </Grid>
        </Grid>
        <ReviewPanel details={centerDetails} reviews={reviews} />
      </Grid>
      <ShareModal
        t={t}
        open={open}
        handleClose={handleClose}
        link={centerDetails?.invitation_link}
      />
    </>
  );
};

export default CenterDetailsTemplate;
