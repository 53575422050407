import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import DatePickerStatic from "../../Atoms/Input/DatePicker/DatePicker.js";

const DateModal = ({
  t,
  open,
  handelConfirm,
  handleClose,
  date,
  setDate,
  openTo,
  views,
  renderDay,
  disablePast,
  disableFuture,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  maxDate,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "auto",
          width: "442px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
          ".MuiCalendarPicker-viewTransitionContainer": {
            border: "solid 1px #ececec",
            borderRadius: "16px",
            boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fcfcfc",
            // width:'370px'
          },
          ".css-15pf3ye-MuiButtonBase-root-MuiPickersDay-root": {
            borderRadius: "5px !important",
          },
          ".Mui-selected": {
            background: "#f6b119 !important",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {t("Search.date")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DatePickerStatic
          value={date}
          setValue={setDate}
          openTo={openTo}
          views={views}
          renderDay={renderDay}
          disablePast={disablePast}
          disableFuture={disableFuture}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          maxDate={maxDate}
        />
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          justifyContent: "center",
          marginTop: "25px",
          marginBottom: "22px",
        }}
      >
        <PrimaryButton
          btnType="submit"
          text={t("Search.confirm")}
          classBtn="date-btn"
          click={handelConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DateModal;
