import { Grid } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput"
import "./InviteMolecule.css";

function InviteMolecule({ item }) {
    // console.log(item.fieldText)
    return (
        <Grid container mb={3.5}>
            <Grid item xs={12} md={8} sx={{paddingInlineEnd:'10px'}}>
                <TextFieldInput
                    placeholder={item.fieldText}
                    className='login-input inviteMolecule-input'
                    style={{
                        height: '64px'
                    }}
                    type={item.textType}
                    value={item.value}
                    label={item.label ? item.label : null}
                    onChange={item.getValue}
                    disabled={item.disabled}
                />
            </Grid>
            <Grid container item xs={12} md={4}
                justifyContent='end'
            >
                <PrimaryButton
                    text={item.btnText}
                    classBtn='white-btn'
                    style={{
                        width: '100%',
                        height: '63px'
                    }}
                    click={item.handleClick}
                />
            </Grid>
        </Grid>
    )
}

export default InviteMolecule