import { axiosFn } from "../index";
import * as URL from "../URLs";

export async function checkToken() {
    return await axiosFn("patch", URL.AUTHENTICITY )
        .then((res) => {
            return {
                res: {message: "success"},
                err: null,
            };
        })
        .catch(() => {
            localStorage.clear()
            window.location.reload()
        });
}