import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function NotificationsListAPI(filter) {
  return await axiosFn("get", URL.NOTIFICATION_LIST + makeFilterString(filter))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function NotificationsCountAPI() {
  return await axiosFn("get", URL.NOTIFICATION_COUNT)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
