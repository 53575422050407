import { icons } from "../../../Assets/AssetHelper.js";
import RatingResponsive from "../../../Components/Organisms/Responsive/Details/RatingResponsive.jsx";
import PriceInclude from "../../../Components/Organisms/Responsive/Details/PriceInclude.jsx";
import PriceNotInclude from "../../../Components/Organisms/Responsive/Details/PriceNotInclude.jsx";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton.jsx";
import AddToCartModal from "../../../Components/Organisms/Modals/Responsive/DetailsPage/AddToCartModal.jsx";
import ShareModal from "../../../Components/Organisms/Modals/Details/ShareModal.jsx";
import { Divider, Grid, Typography } from "@mui/material";
import InfoPanel from "../Details/DetailsPanels/InfoPanel.jsx";
import Requirments from "../Details/DetailsPanels/Requirments.jsx";
import TripDetails from "../Details/DetailsPanels/TripDetails.jsx";
import BoatPanel from "../Details/DetailsPanels/BoatPanel.jsx";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { PickersDay } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import CarouselOrganism from "../Carousels/CarouselOrganism.jsx";

function DetailsResponsive(props) {
  const location = useLocation();
  const path = location.pathname;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [seats, setSeats] = useState();
  const [seatsError, setSeatsError] = useState(null);
  const weekDays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const submitToCart = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (seatsError === null) {
        await props?.addEditCart({
          cart_item: {
            dive_activity_id: props?.diveDetails?.id,
            start_date: dayjs(date).format("DD/MM/YYYY"),
            quantity: seats,
          },
          pickup_location: {},
          reservation_participants: [],
        });
      }
    } else toast.error(props.t("General.logged_in_to_add_cart"));
  };

  useEffect(() => {
    const dates = []; // '2022/9/22','2022/9/25'
    if (path === "/edit") {
      props?.card?.purchasable?.dive_activity?.activity_groups?.forEach(
        (group) => {
          dates.push(dayjs(group.start_date).format("YYYY/MM/DD"));
        }
      );
    } else {
      if (props?.diveDetails?.scheduling_option === "some_dates") {
        props?.diveDetails?.activity_groups?.forEach((group) => {
          dates.push(dayjs(group.start_date).format("YYYY/MM/DD"));
        });
      } else if (props?.diveDetails?.scheduling_option === "some_weekdays") {
        props?.diveDetails?.scheduled_days?.forEach((day) => {
          var dayNoInWeek = weekDays.indexOf(day.weekday);
          var dayDate = dayjs().day(dayNoInWeek).format("YYYY/MM/DD");
          dates.push(dayDate);
          while (
            dayDate < dayjs(props?.diveDetails?.end_date).format("YYYY/MM/DD")
          ) {
            dayNoInWeek += 7;
            dayDate = dayjs().day(dayNoInWeek).format("YYYY/MM/DD");
            dates.push(dayDate);
          }
        });
      }
    }
    setDateArray(dates);
  }, [props?.diveDetails, props?.card]);

  useEffect(() => {
    if (path !== "/edit") {
      if (localStorage.getItem("ActiveUserType") === "customer") setSeats(1);
      else if (localStorage.getItem("ActiveUserType") === "dive_club")
        setSeats(5);
    }
  }, []);

  useEffect(() => {
    // if (path === '/edit') onChangeParticipants(seats)
    if (localStorage.getItem("ActiveUserType") === "customer") {
      if (seats < 1) setSeatsError(props.t("Details.customer_error"));
      else if (seats > props.remainingSeats)
        setSeatsError(
          props.t("Details.not_exceed") +
            props.remainingSeats +
            props.t("Details.no_seats")
        );
      else setSeatsError(null);
    } else if (localStorage.getItem("ActiveUserType") === "dive_club") {
      if (seats < 5) setSeatsError(props.t("Details.diveClub_error"));
      else setSeatsError(null);
    }
  }, [seats]);

  useEffect(() => {
    if (date) {
      props?.getRemainingSeats(props?.diveDetails?.id, date);
    }
  }, [date]);

  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="flex-end" paddingTop={2}>
        <PrimaryButton
          btnType="icon"
          startIcon={
            <img
              src={icons.Position}
              alt="location"
              style={{
                marginInlineEnd: "26px",
              }}
            />
          }
          click={props.showInMapClicked}
        />
        <PrimaryButton
          btnType="icon"
          startIcon={
            <img
              src={icons.ShareOrange}
              alt="share"
              style={{
                marginInlineEnd: "16px",
              }}
            />
          }
          click={props.handleOpen}
        />
        {props?.diveDetails?.is_booked_by_current_user && (
          <PrimaryButton
            btnType="icon"
            startIcon={
              <img
                src={icons.ReservationComplainResponsive}
                alt="share"
                style={{
                  marginInlineEnd: "16px",
                }}
              />
            }
            click={props.showReservationComplaint}
          />
        )}
      </Grid>
      {props?.diveDetails?.media && (
        <CarouselOrganism
          t={props?.t}
          type="responsive"
          items={props?.diveDetails?.media}
          // heading={t("HomePage.destinations")}
          // description={t("HomePage.destinations_description")}
          width="100%"
        />
      )}
      <Grid container item xs={12} paddingX={2} mb={1.5}>
        <Typography
          style={{
            color: "var(--font-color)",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
          component="h1"
        >
          {props.diveDetails?.name}
        </Typography>
      </Grid>
      <Grid container paddingX={2}>
        <Grid item xs={6}>
          <Typography
            component="h1"
            style={{
              color: "var(--font-color)",
              fontSize: "20px",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {`${props.diveDetails?.currency?.lookup_key} ${props.diveDetails?.price_after_discount}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: "end",
          }}
        >
          {props.diveDetails?.price !==
            props.diveDetails?.price_after_discount && (
            <Typography
              component="del"
              style={{
                fontSize: "14px",
                display: "block",
                color: "var(--secound-f-color)",
                marginTop: "10px",
                textTransform: "uppercase",
              }}
            >
              {`${props.diveDetails?.currency?.lookup_key} ${props.diveDetails?.price}`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginY: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <BoatPanel t={props.t} diveDetails={props.diveDetails} />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <TripDetails
        t={props.t}
        diveDetails={props.diveDetails}
        isMd={props.isMd}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <Requirments
        t={props.t}
        diveDetails={props.diveDetails}
        isMd={props.isMd}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <RatingResponsive
        /* Dive Center Bar */
        t={props.t}
        diveCenter={props.diveDetails?.owner}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <InfoPanel
        /* What to expect */
        title={props.t("Details.desc")}
        body={props.diveDetails?.description}
        isMd={props.isMd}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <PriceInclude
        t={props.t}
        includes={props.diveDetails?.activity_price_includes}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <PriceNotInclude
        t={props.t}
        excludes={props.diveDetails?.activity_price_excludes}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <InfoPanel
        /* Cancelation policy */
        title={props.t("Details.policy")}
        body={props.diveDetails?.cancellation_policy}
        isMd={props.isMd}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <InfoPanel
        /* Confirmation criteria */
        title={props.t("Details.criteria")}
        body={props.diveDetails?.confirmation_text}
        isMd={props.isMd}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <InfoPanel
        /* Pickup info */
        title={props.t("Details.pickup")}
        body={props.diveDetails?.pickup_info}
        isMd={props.isMd}
      />
      <Divider
        sx={{
          marginBottom: "24px",
          width: "90%",
          marginX: "auto",
        }}
      />
      <InfoPanel
        /* client_expectation */
        title={props.t("Details.client")}
        body={props.diveDetails?.client_expectation}
        isMd={props.isMd}
      />
      <Grid
        container
        sx={{
          height: "80px",
          backgroundColor: "#fbfbfb",
          position: "fixed",
          bottom: "0",
        }}
      >
        <Grid container item xs={2} alignItems="center" justifyContent="center">
          <PrimaryButton
            btnType="icon"
            startIcon={
              <img
                src={props.isFavorite ? icons.LikeYes : icons.HeartResposive}
                alt="favorite"
              />
            }
            click={props.handleFavoriteChange}
          />
        </Grid>
        <Grid
          container
          item
          xs={10}
          alignItems="center"
          justifyContent="center"
        >
          <PrimaryButton
            text={props.t("Reservation.add")}
            // disabled={seatsError !== null}
            classBtn={props.isFromBooking ? "cancel-red-btn" : "responsive-btn"}
            click={() => setOpen(true)}
          />
        </Grid>
      </Grid>
      <ShareModal
        t={props.t}
        open={props.open}
        handleClose={props.handleClose}
        link={props.diveDetails?.invitation_link}
      />
      <AddToCartModal
        t={props?.t}
        submitToCart={submitToCart}
        setSeats={setSeats}
        seats={seats}
        seatsError={seatsError}
        remainingSeats={props?.remainingSeats}
        open={open}
        handleClose={() => setOpen(false)}
        date={date}
        setDate={setDate}
        disablePast={true}
        // openTo="day"
        openTo="year"
        views={['year', 'month', 'day']}        
        maxDate={dayjs(new Date(props.diveDetails?.end_date))}
        shouldDisableDate={(date) => {
          // Disable weekends (Saturday and Sunday)
          if (props.diveDetails?.scheduling_option === "some_dates") {              
            const stringifiedDate = (dayjs(date).format("YYYY/MM/DD"));
            return !dateArray.includes(stringifiedDate);
          } else if (props.diveDetails?.scheduling_option === "some_weekdays") {
            const stringifiedDate = (dayjs(date).format("YYYY/MM/DD"));
            return !dateArray.includes(stringifiedDate);
          }
        }}
        shouldDisableMonth={(month) => {
          if (props.diveDetails?.scheduling_option != "everyday") {
            const stringifiedDate = (dayjs(new Date(month)).format("YYYY/MM"));
            const dates = dateArray?.map((date) => {            
              return dayjs(date).format("YYYY/MM");
            });
            return !dates.includes(stringifiedDate);
          }
        }}
        shouldDisableYear={(year) => {
          if (props.diveDetails?.scheduling_option != "everyday") {
            const stringifiedDate = dayjs(new Date(year)).format(
              "YYYY"
            );
            const dates = dateArray?.map((date) => {
              return dayjs(date).format("YYYY");
            });
            return !dates.includes(stringifiedDate);
          }
        }}  
        renderDay={(day, _, value, DayComponentProps) => {
          const stringifiedDate = dayjs(day).format("YYYY/MM/DD");
          if (props.diveDetails?.scheduling_option === "everyday") {
            if (
              stringifiedDate > dayjs().format("YYYY/MM/DD") &&
              stringifiedDate <=
                dayjs(props.diveDetails?.end_date).format("YYYY/MM/DD")
            ) {
              return <PickersDay {...DayComponentProps} />;
            }
            return <PickersDay {...DayComponentProps} disabled />;
          }
        }}
      />
    </Grid>
  );
}

export default DetailsResponsive;
