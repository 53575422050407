import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SendOtpAPI } from "../../Contexts/APIs/Auth/Password/SendOtp";
import AccountTypeTemplate from "../../Templates/Auth/AccountType.Template";

const AccountTypePage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const SendOtp = async (email, type) => {
    const { res, err } = await SendOtpAPI(
      {
        user: {
          email: email,
        },
      },
      type
    );
    if (res) toast.success("The code has been sent to your email");
    if (err) toast.error(err);
  };
  const handleListItemClick = (e, index, data) => {
    const email = window.location.href.split("email=")?.[1]?.split("&");
    SendOtp(email?.[0], data?.value);
    navigate(`/forget-password?email=${email?.[0]}&user_type=${data?.value}`);
  };

  useEffect(() => {
    const url = window.location.href;
    if (url.indexOf("user_type=") != -1) {
      window.location.href = url.slice(0, url.indexOf("user_type="));
    }
  }, []);

  return (
    <AccountTypeTemplate
      selectedIndex={selectedIndex}
      handleListItemClick={handleListItemClick}
    />
  );
};

export default AccountTypePage;
