import { useState } from "react";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import NormalTabs from "../../../Components/Atoms/Tabs/NormalTabs";
import { icons } from "../../../Assets/AssetHelper";
import CarouselCard from "../../../Components/Molecules/Carousels/Children/CarouselCard";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import CarouselSmallCard from "../../../Components/Molecules/Carousels/Children/CarouselSmallCard";
import { withTranslation } from "react-i18next";
import Loader from "../../../Components/Atoms/Loader/Loader";
import CustomPagination from "../../../Components/Atoms/Pagination/Pagination";

const FavoritesTemplate = ({
  t,
  favorites,
  getFavorites,
  getFilteredFavorites,
  loading,
  count,
  setFilterObj
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [type, setType] = useState(0);
  const [tapOption, setTapOption] = useState("dive_trip");
  const handlePageChange = (event, value) => {
    getFilteredFavorites({
      page_size: 12,
      page_number: value,
      favourite_type: tapOption,
    });
    setPage(value);
  };
  const favoritesTabOptions = [
    {
      id: 0,
      label: t("Favorites.diving"),
      name: "dive_trip",
    },
    {
      id: 1,
      label: t("Navbar.courses"),
      name: "dive_course",
    },
    {
      id: 2,
      label: t("HomePage.liveboard"),
      name: "live_aboard",
    },
    {
      id: 3,
      label: t("Navbar.dive_center"),
      name: "Branch",
    },
    {
      id: 4,
      label: t("Navbar.dive_expert"),
      name: "DiveExpert",
    },
  ];

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
    const tapFilter = favoritesTabOptions.find(
      (item) => item.id === newValue
    ).name;
    getFilteredFavorites({
      page_size: 12,
      page_number: 1,
      favourite_type: tapFilter,
    });
    setTapOption(tapFilter);
  };
  return (
    <Grid
      container
      mt={6}
      mb={6}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
      }}
    >
      <Grid container item xs={12} mb={4} p={{ xs: 1, md: 0 }}>
        <img
          src={isSm ? icons.FavoriteResponsive : icons.Heart}
          alt=""
          style={{ marginInlineEnd: "10px" }}
        />
        <Typography
          display="inline"
          sx={{
            color: "var(--font-color)",
            fontSize: { xs: "20px", md: "var(--home-header-font)" },
            fontWeight: "bold",
          }}
        >
          {t("Favorites.my_favorites")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NormalTabs
          TabsOptions={favoritesTabOptions}
          value={type}
          change={handleTypeChange}
          ContainerStyle=""
          TabStyle="type-btn"
          onclick={() => {}}
        />
      </Grid>
      <Divider sx={{ width: "100%" }} />
      {loading ? (
        <Loader open={loading} />
      ) : (
        <Grid container item xs={12} mt={6}>
          {favorites?.length > 0 ? (
            favorites?.map((favorite) => {
              return type === 3 || type === 4 ? (
                <Grid item xs={12} md={3} key={favorite?.id}>
                  <CarouselSmallCard
                    t={t}
                    item={favorite}
                    type={type === 3 ? "centers" : "experts"}
                    getFilteredFavorites={getFilteredFavorites}
                    getFavorites={getFavorites}
                    tapOption={tapOption}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} key={favorite?.id}>
                  <CarouselCard
                    t={t}
                    item={favorite}
                    getFilteredFavorites={getFilteredFavorites}
                    tapOption={tapOption}
                    getFavorites={getFavorites}
                    setFilterObj={setFilterObj}
                    // handleClick={handleClick}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid container alignContent="center">
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                mt={10}
                mb={6}
              >
                <img src={icons.Empty} alt="empty" />
              </Grid>
              <Grid container item xs={12} justifyContent="center" mb={5}>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "21.6px" },
                    fontWeight: "500",
                    color: "#aaa",
                  }}
                >
                  {t("Favorites.empty")}
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <PrimaryButton
                  text={
                    type === 0
                      ? t("Favorites.explore_diving")
                      : type === 1
                      ? t("Favorites.explore_courses")
                      : type === 2
                      ? t("Favorites.explore_liveaboards")
                      : type === 3
                      ? t("Favorites.explore_dive_centers")
                      : t("Favorites.explore_dive_experts")
                  }
                  classBtn="orange"
                  click={() => {
                    type === 0
                      ? navigate("/search/dive_trip")
                      : type === 1
                      ? navigate("/search/dive_course")
                      : type === 2
                      ? navigate("/search/live_aboard")
                      : type === 3
                      ? navigate("dive-centers")
                      : navigate("/dive-experts");
                  }}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      md: "24px",
                    },
                    borderRadius: {
                      xs: "100px",
                      md: "10px",
                    },
                    height: {
                      xs: "60px",
                      md: "72px",
                    },
                    width: {
                      xs: "340px",
                      md: "400px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {count > 12 && (
        <Grid container justifyContent="center" mt={5}>
          <CustomPagination
            count={Math.ceil(count / 12)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(FavoritesTemplate);
