import { Grid, Typography } from "@mui/material"
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton"
import { useNavigate } from "react-router-dom"
import { images } from "../../../Assets/AssetHelper"
import { withTranslation } from "react-i18next"

const PaymentSuccessTemplate = ({t}) => {
    const navigate = useNavigate()
    return (
        <Grid container alignContent='center' sx={{ marginY: '110px' }}>
            <Grid container item xs={12} justifyContent='center' mb={4}>
                <img src={images.Success} alt='success' />
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
                <Typography sx={{
                    fontSize: 'var(--subtitle-font)',
                    fontWeight: 'bold',
                    color: 'var(--font-color)',
                    width: '460px',
                    textAlign: 'center'
                }}>
                    {t("Booking.success")}
                </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent='center' mt={5}>
                <PrimaryButton
                    text={t("Booking.go")}
                    classBtn='orange-btn'
                    click={() => navigate('/orders')}
                />
            </Grid>
        </Grid>
    )
}
 
export default withTranslation()(PaymentSuccessTemplate)