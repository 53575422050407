import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function DeleteItemAPI(id) {
    return await axiosFn("delete", URL.CART + URL.DELETE + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
