import React from "react";
import PropTypes from "prop-types";
import { Button, IconButton } from "@mui/material";
import "./PrimaryButton.css";

function PrimaryButton({
  classBtn,
  text = "",
  click,
  startIcon = null,
  endIcon = null,
  btnType = "",
  disabled,
  style,
  sx,
}) {
  return (
    <>
      {btnType !== "icon" ? (
        <Button
          className={classBtn}
          onClick={click}
          startIcon={startIcon}
          endIcon={endIcon}
          disabled={disabled}
          style={{ ...style, opacity: disabled && "0.5" }}
          sx={sx}
        >
          {text}
        </Button>
      ) : (
        <IconButton
          style={style}
          fullwidth="true"
          className={classBtn}
          onClick={click}
        >
          {startIcon}
        </IconButton>
      )}
    </>
  );
}

PrimaryButton.propTypes = {
  variant: PropTypes.string,
  classBtn: PropTypes.string,
  text: PropTypes.string,
  click: PropTypes.func,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

export default PrimaryButton;
