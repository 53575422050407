import React, { useEffect, useState } from "react";
import NotificationsTemplates from "../../../Templates/Main/Notifications/Notifications.Templates";
import { NotificationsListAPI } from "../../../Contexts/APIs/Notifications/Noftifications";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const NotificationsPage = () => {
  const navigate = useNavigate();
  const [notificationsList, setNotificationsList] = useState([]);
  const [notifCount, setNotifCount] = useState(0);

  const getList = async () => {
    const { res, err } = await NotificationsListAPI({});
    if (res) {
      setNotificationsList([...notificationsList, ...res?.data?.notifications]);
      setNotifCount(res?.extra?.total_count);
    }

    if (err) toast.error(err);
  };

  useEffect(() => {
    if (localStorage.getItem("ActiveToken")) {
      if (localStorage.getItem("notificationsEnabled") === "true") {
        getList();
      }
    }
  }, [localStorage.getItem("notificationsEnabled")]);

  const actions = () => {
    return {
      order: "/orders",
      signup_points: `/profile/points`,
      invitation: `/profile/points`,
      order_points: `/profile/points`,
      levels: "/profile/view-profile",
      invitation_points: "/profile/points",
    };
  };

  const handleClose = (data) => {
    if (data?.need_action) navigate(actions(data?.data_id)[data?.notification_type]);
  };

  return (
    <NotificationsTemplates
      notificationsList={notificationsList}
      setNotificationsList={setNotificationsList}
      handleClose={handleClose}
      getList={getList}
      notifCount={notifCount}
    />
  );
};

export default NotificationsPage;
