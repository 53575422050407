import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import PrimaryButton from '../../../../Atoms/Buttons/Primary/PrimaryButton';
import SelectBox from '../../../../Atoms/Select/Select';

const SortModal = ({ t, open, onClose, sortItems, sortValue, handleSortChange }) => {
    return ( 
        <Dialog open={open} onClose={onClose} aria-labelledby="">
            <DialogTitle id={'dialog'}>
                <Typography>
                    {t("Search.sort")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <SelectBox
                    defaultValue={sortItems?.[0]?.id}
                    style={{ width: '200px', height: '27px' }}
                    className='sort-select'
                    items={sortItems}
                    value={sortValue}
                    onchange={handleSortChange}
                />
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    text={t("Search.show")}
                    click={onClose}
                    classBtn='date-btn'
                />

            </DialogActions>
        </Dialog>
     );
}
 
export default SortModal;