import { useSearchParams } from "react-router-dom";
import BookingTemplate from "../../../Templates/Main/Reservation/Booking.Template";
import { withTranslation } from "react-i18next";

const BookingPage = ({ t }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    
    return (
        <BookingTemplate
            t={t}
            searchParams={searchParams}
        />
    );
}

export default withTranslation()(BookingPage);