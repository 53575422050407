import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../Assets/AssetHelper";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const EmpytCart = ({ t }) => {
  const navigate = useNavigate();
  return (
    <Stack
      width="100%"
      height="60vh"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      <img src={icons.EmptyCart} width="160px" height="160px" />
      <Typography fontSize="21.6px" fontWeight="500" color="#AAAAAA">
        {t("Cart.emptyMsg")}
      </Typography>
      <Box sx={{ width: { sm: "80%", md: "25%" } }}>
        <PrimaryButton
          text={t("Cart.exploreActivites")}
          classBtn="book-btn"
          click={() => navigate("/search/dive_trip")}
        />
      </Box>
    </Stack>
  );
};

export default withTranslation()(EmpytCart);
