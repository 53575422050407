import Pagination from '@mui/material/Pagination'

import './Pagination.css'

export default function CustomPagination({
  count,
  size,
  shape,
  page,
  handleChange,
  className
}) {

  return (
    <Pagination
      count={count}
      size={size}
      className={className}
      sx={{
        ".MuiPaginationItem-previousNext": {
          color: '#f6b119'
        },
        ".MuiPaginationItem-page": {
          bgcolor: '#f2f5f7'
        },
        ".Mui-selected": {
          color: 'white !important',
          bgcolor: '#f6b119 !important'
        }
      }}
      shape={shape}
      page={page}
      onChange={handleChange}
    />
  );
}