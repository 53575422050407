import { Stack, Typography } from "@mui/material";
import React from "react";
import InovaLoga from "../../../Assets/Icons/inovalogo.svg";
import { withTranslation } from "react-i18next";

const DevelopedBy = ({ t }) => {
  return (
    <Stack
      flexDirection="row"
      gap={1}
      alignItems="center"
      justifyContent="center"
      bgcolor={"#272726"}
      p={1}
    >
      <Typography fontSize="14px" color="white" fontWeight='bold'>
        {" "}
        {t("Footer.developedBy")}{" "}
      </Typography>

      <a href="https://inovaeg.com/" target="_blank">
        <img src={InovaLoga} />
      </a>
    </Stack>
  );
};

export default withTranslation()(DevelopedBy);
