import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import modalImg from '../../../Assets/Images/successmodal.png';
import './SuccessModal.css';
import PrimaryButton from '../../Atoms/Buttons/Primary/PrimaryButton';
import { withTranslation } from 'react-i18next';

function SuccessModal({t, formik, open, setOpen, text}) {
    const handleClose = () => { 
        setOpen(false); 
        formik.handleReset();        
    }
  
    return (      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box className='success-modal'>
          <img src={modalImg} alt='modalImg' />
          <Typography id="modal-modal-title" variant="h6" component="h2"> {text} </Typography>
          <PrimaryButton
            text={t("validation.ok")}
            classBtn="primary"
            click={handleClose}
          />
        </Box>
      </Modal>
    );
}

export default withTranslation()(SuccessModal)