import {
  // useEffect,
  useState,
  // useRef
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  GoogleMap,
  LoadScript,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";
// import SearchIcon from "@mui/icons-material/Search"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/AssetHelper";

const DirectionModal = ({
  t,
  open,
  handleClose,
  userPosition,
  destination,
}) => {
  const mapKey = process.env.REACT_APP_GOOGLE_API_MAP_KEY;

  // const mapRef = useRef(null)
  const [response, setResponse] = useState(null);

  // const [libraries] = useState(['places']);
  const containerStyle = {
    width: "100%",
    height: "630px",
  };

  // Store a reference to the google map instance in state
  // const loadHandler = map => mapRef.current = map

  // function handleCenterChanged() {
  //     if (!mapRef.current) return
  //     const newPos = mapRef.current.getCenter().toJSON()
  //     setCenter(newPos)
  // }

  // const onLoad = (autocomplete) => setValue(autocomplete)

  // const onPlaceChanged = () => {
  //     if (value !== null) {
  //         const place = value.getPlace()
  //         const lat = place.geometry.location.lat()
  //         const lng = place.geometry.location.lng()
  //         setCenter({ lat, lng })
  //     } else {
  //         console.log('Autocomplete is not loaded yet!')
  //     }
  // }

  const getDirections = async () => {
    const directionService = new window.google.maps.DirectionsService();
    const result = await directionService.route({
      origin: userPosition,
      destination: destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setResponse(result);
  };

  // useEffect(() => {
  //     getDirections()
  // }, [userPosition, destination]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "auto",
          width: "616px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontSize: "var(--medium-font)",
                fontWeight: "bold",
                color: "var(--font-color)",
              }}
            >
              {t("Checkout.select-location")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container>
        <LoadScript
          id="script-loader"
          googleMapsApiKey={mapKey}

          // libraries={libraries}
        >
          <GoogleMap
            id="direction-example"
            mapContainerStyle={containerStyle}
            center={userPosition}
            zoom={10}
            onLoad={getDirections}
            // onDragEnd={handleCenterChanged}
          >
            {response && (
              <DirectionsRenderer
                // required
                directions={response}
              />
            )}
            <Marker icon={icons.Position} position={userPosition} />
          </GoogleMap>
        </LoadScript>
      </Grid>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginBottom: "14px",
          marginTop: "14px",
        }}
      >
        <PrimaryButton
          text={t("Search.confirm")}
          classBtn="date-btn"
          click={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DirectionModal;
