import React, { useRef, useState } from "react";
import { CircularProgress, Grid, Typography, Box, Stack } from "@mui/material";
import * as yup from "yup";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";

import "./HelpForms.css";
import SuccessModal from "../../SuccessModal/SuccessModal";
import { useFormik } from "formik";
import {
  addAppComplaint,
  addGeneralComplaint,
} from "../../../../Contexts/APIs/Complaints/helpAndSupport";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from "../../../Atoms/Loader/Loader";

import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../../Contexts/APIs/Uploads/Upload";
import { icons } from "../../../../Assets/AssetHelper";
import ImageInput from "../../../Atoms/Input/ImageInput/ImageInput";

function HelpForms({ t, targetPage, userEmail, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [imageURL, setImageURL] = useState(null);

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardItems = event?.clipboardData?.items;
    for (const item of clipboardItems) {
      if (item.type.indexOf("image") === 0) {
        // this converts the clipboard item into a File object using getAsFile().
        // getAsFile() function is part of the Clipboard API in the browser. It allows you to retrieve files (such as images) from the clipboard when the user pastes content into a web page
        const file = item?.getAsFile();
        uploadImage(file, true); // Upload the pasted image directly to S3
        break;
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);
  const btnSX = {
    width: "80px",
    height: "80px",
    border: "1px solid #BBBBBB",
    borderRadius: "10px",
  };
  const validationSchemaObject = yup.object({
    name: yup.string(),
    sender_email: yup
      .string()
      .trim()
      .required(t("validation.email"))
      .email(t("validation.invalid_email")),
    description: yup
      .string()
      .trim()
      .min(11, t("validation.description_short"))
      .required(t("validation.description")),
  });

  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      sender_email: userEmail
        ? userEmail
        : localStorage.getItem("ActiveEmail") || "",
      description: "",
      image_url: null,
    },
    validationSchema: validationSchemaObject,
    onSubmit: async (values) => {
      setIsLoading(true);
      const { res, err } =
        targetPage === "GuestFaqs" || targetPage === "CustomerSupport"
          ? await addGeneralComplaint(
              { complaint: { ...values, image_url: imageURL } },
              setOpen
            )
          : await addAppComplaint(
              { complaint: { ...values, image_url: imageURL } },
              setOpen
            );
      if (res) {
        setIsLoading(false);
        handleClose && handleClose();
        setOpen(true);
        formik?.resetForm();
        setImageURL(null);
      }
      if (err) toast.error(err);
      setIsLoading(false);
    },
  });

  // upload Image
  const uploadImage = async (file, isScreenShot) => {
    if (!isScreenShot) {
      file = file.target.files[0];
    }
    const fileSize = file.size / (1024 * 1024); // Convert to MB
    if (fileSize > 1) {
      toast.warning("Maximum Upload Size is 1 MB Per File");
    } else {
      setOpenLoader(true);
      const res = await getPresignedLinkAPI([{ extension: "png" }]); // Adjust the payload as per your API requirements
      if (res?.res?.data?.length) {
      const presignedUrl = res?.res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        setImageURL(res?.res?.data[0].file_url); // Use the file URL returned by the backend
      }} else {
        toast.error('Failed to get presigned URL');
      }
      setOpenLoader(false);
    }
  };

  return (
    <>
      <Grid container item md={12} style={{ margin: "auto" }}>
        <Grid
          style={{ margin: "auto", width: "100%" }}
          className="form-element"
        >
          {targetPage === "GuestFaqs" && (
            <TextFieldInput
              name="name"
              id="name"
              placeholder={t("validation.full_name_placeholder")}
              label={t("validation.full_name_placeholder")}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              clickEnter={formik.handleSubmit}
              sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px" }}
            />
          )}
          <TextFieldInput
            name="sender_email"
            id="sender_email"
            placeholder={t("validation.email_placeholder")}
            label={t("validation.email_placeholder")}
            type="email"
            onChange={formik.handleChange}
            value={formik.values.sender_email}
            error={
              formik.touched.sender_email && Boolean(formik.errors.sender_email)
            }
            helperText={
              formik.touched.sender_email && formik.errors.sender_email
            }
            clickEnter={formik.handleSubmit}
            sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px" }}
          />
          <TextFieldInput
            name="description"
            id="description"
            placeholder={t("validation.description_placeholder")}
            label={t("validation.description_placeholder")}
            type="text"
            multiline={true}
            rows={4}
            onChange={formik.handleChange}
            value={formik.values.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            clickEnter={() => {}}
            sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px" }}
          />
          {/* image upload */}
          <Loader open={openLoader} />
          {imageURL === null ? (
            <Grid container>
              <Grid item xs={2} sx={{ position: "relative" }}>
                <ImageInput
                  change={(e) => uploadImage(e)}
                  icon={icons.Cross}
                  btnSX={btnSX} //this should match the image itself too
                />
              </Grid>
              <Grid
                container
                item
                xs={10}
                alignContent="center"
                sx={{ paddingInlineStart: "19px" }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#848383",
                  }}
                >
                  {t("Profile.upload")}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography sx={{ color: "black" }}>
                  {window.fileSize}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Stack flexDirection={"row"} sx={{ position: "relative" }}>
              <a href={imageURL} download>
                <Box
                  component={"img"}
                  src={imageURL}
                  alt="img"
                  style={{ cursor: "pointer", ...btnSX }}
                />
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }} // Hide the default input
                  accept="image/*"
                />
              </a>
              <Box
                component={"img"}
                src={icons.DeleteWhiteCrosshair}
                alt="delete"
                sx={{
                  position: "relative",
                  top: "-39px",
                  left: "-13px",
                  cursor: "pointer",
                }}
                onClick={() => setImageURL(null)}
              />
            </Stack>
          )}
          {/* image upload */}

          <PrimaryButton
            text={
              isLoading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "23px !important",
                    height: "23px !important",
                  }}
                />
              ) : (
                t("validation.submit")
              )
            }
            classBtn={`primary ${
              targetPage === "GuestFaqs" && "guest-contact"
            }`}
            style={{ padding: "5px 50px", margin: "auto" }}
            click={formik.handleSubmit}
          />
        </Grid>
      </Grid>
      <SuccessModal
        formik={formik}
        open={open}
        setOpen={setOpen}
        text={t("Profile.successModal")}
      />
    </>
  );
}

export default withTranslation()(HelpForms);
