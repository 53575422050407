import axios from "axios";
import { BaseURL } from "../../services/modules/utils/constant";
import handleErrors from "../../services/modules/utils/handel_error";
// console.log(BaseURL);
export const reduxRequest = async ({
  dispatch,
  isLoading,
  url,
  method = "post",
  loadingType = "list",
  body,
  action,
  failAction,
  successFunction,
}) => {
  dispatch(
    isLoading({
      type: loadingType,
      loading: true,
    })
  );
  try {
    const { data } = await axios({
      url: url,
      baseURL: BaseURL,
      method: method,
      data: body,
    });
    dispatch(successFunction(data));
    action && action();
  } catch (error) {
    failAction ? failAction(error) : handleErrors(error);
  } finally {
    dispatch(
      isLoading({
        type: "",
        loading: false,
      })
    );
  }
};
