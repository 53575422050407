import React from 'react'
import { LogoutAPI } from '../../../Contexts/APIs/Auth/Logout';
import SwitchAccountTemplate from '../../../Templates/Main/SwitchAccount/SwitchAccount.template';
import { BulkLogoutAPI } from '../../../Contexts/APIs/Auth/BulkLogout';
import { toast } from 'react-toastify';

const SwitchAccountPage = ({setIsAuth}) => {
    const Logout = async () => {
        const { res, err } = await LogoutAPI();
        if (res) toast.success(res?.message);
        if (err) toast.error(err);
    };

    const BulkLogout = async (data) => {
        const { res, err } = await BulkLogoutAPI(data);
        // if (res) toast.success(res?.message)
        if (err) toast.error(err);
    };
  return (
    <SwitchAccountTemplate Logout={Logout} setIsAuth={setIsAuth} BulkLogout={BulkLogout} />
  )
}

export default SwitchAccountPage