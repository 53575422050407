import { createStore, applyMiddleware } from "redux";
import { thunk as thunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "./combined-reducers";
/* 
  imported composeWithDevTools from "@redux-devtools/extension", 
  because redux-devtools-extension deprecated & moved to @redux-devtools/extension.
*/

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const store = createStore(rootReducer, bindMiddleware([thunkMiddleware]));

store.subscribe(() => {
  // console.log('store', store.getState())
});