import { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import SearchIcon from "@mui/icons-material/Search";
import Geocode from "react-geocode";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/AssetHelper";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";

const LocationModal = ({
  t,
  open,
  handleClose,
  center,
  setCenter,
  setAddress,
}) => {
  const mapKey = process.env.REACT_APP_GOOGLE_API_MAP_KEY;
  Geocode.setApiKey(mapKey);
  // Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  // process.env.REACT_APP_GOOGLE_API_MAP_KEY
  const mapRef = useRef(null);
  const [value, setValue] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [libraries] = useState(["places"]);
  const containerStyle = {
    width: "100%",
    height: "630px",
  };

  // Store a reference to the google map instance in state
  const loadHandler = (map) => (mapRef.current = map);

  const getAddress = (lat, lng) => {
    // Get address from latitude & longitude.
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  function handleCenterChanged() {
    // console.log(mapRef.current, "hii");
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setCenter(newPos);
    getAddress(newPos.lat, newPos.lng);
  }

  const onLoad = (autocomplete) => setValue(autocomplete);

  const onPlaceChanged = () => {
    if (value !== null) {
      const place = value.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setCenter({ lat, lng });
      getAddress(lat, lng);
      setInputValue(place?.name);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  const handelMarker = (t) => {
    const { latLng } = t;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    getAddress(lat, lng);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "auto",
          width: "616px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontSize: "var(--medium-font)",
                fontWeight: "bold",
                color: "var(--font-color)",
              }}
            >
              {t("Checkout.select-location")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container>
        <LoadScript
          id="script-loader"
          googleMapsApiKey={mapKey}
          libraries={libraries}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={loadHandler}
            onDragEnd={handleCenterChanged}
            // onDrag={handleCenterChanged}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <Box mt="60px">
              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <TextFieldInput
                  placeholder={t("Edit_Trip.search")}
                  className="map-input"
                  justifyContent="end"
                  gridClass="map-input-grid"
                  onChange={(e) => setInputValue(e?.target?.value)}
                  value={inputValue}
                  StartAdornment={
                    <SearchIcon
                      htmlColor="#f6b119"
                      sx={{
                        marginInlineStart: "10px",
                      }}
                    />
                  }
                />
              </Autocomplete>
              <Marker
                icon={icons.Position}
                position={center}
                draggable
                onDragEnd={handelMarker}
              />
            </Box>
          </GoogleMap>
        </LoadScript>
      </Grid>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginBottom: "14px",
          marginTop: "14px",
        }}
      >
        <PrimaryButton
          text={t("Search.confirm")}
          classBtn="date-btn"
          click={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LocationModal;
