import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HomeTemplate from "../../../Templates/Main/Home/Home.Template.jsx";
import { ListDiveActivitiesAPI } from "../../../Contexts/APIs/Dive-Activities/ListDiveActivities";
import { SearchAPI } from "../../../Contexts/APIs/General/Search";
import { PopularDestinationsAPI } from "../../../Contexts/APIs/Lookups/PopularDestinations.js";
import { useEffect } from "react";
import { BranchesAPI } from "../../../Contexts/APIs/Branches/branches.js";
import { Firebase } from "../../../Contexts/Firebase/Firebase.js";
import { getSponsors } from "../../../Contexts/APIs/Lookups/Sponsors.js";

const HomePage = ({ t, getFavorites }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const diveActivities = async (filter) => {
    const { res, err } = await ListDiveActivitiesAPI(filter);
    if (res) return res?.data?.activities;
    if (err) toast.error(err);
  };

  const getBranches = async (filter) => {
    const { res, err } = await BranchesAPI(filter);
    if (res) return res?.data?.branches;
    if (err) toast.error(err);
  };

  const getLocations = async (filter) => {
    const { res, err } = await PopularDestinationsAPI(filter);
    if (res) return res?.data?.areas;
    if (err) toast.error(err);
  };

  const getHotOffers = async () => {
    const { res, err } = await ListDiveActivitiesAPI({ is_hot_offer: true });
    if (res) return res?.data?.activities;
    if (err) toast.error(err);
  };

  const search = async (filter) => {
    const { res, err } = await SearchAPI(filter);
    if (res) return res?.data;
    if (err) toast.error(err);
  };

  const getSponsersList = async (area_id = "") => {
    const { res, err } = await getSponsors({
      page_number: "1",
      page_size: "100",
    });
    if (res) return res?.data?.sponsors;
    if (err) toast.error(err);
  };

  // useEffect(() => {
  //   Firebase.getFCMToken();
  // }, []);

  return (
    <HomeTemplate
      diveActivities={diveActivities}
      getBranches={getBranches}
      getLocations={getLocations}
      getHotOffers={getHotOffers}
      getSponsersList={getSponsersList}
      search={search}
      getFavorites={getFavorites}
    />
  );
};

export default HomePage;
