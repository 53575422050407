import { Grid, Typography } from "@mui/material"

import { icons } from "../../../../Assets/AssetHelper.js"
import PhoneInput from "react-phone-input-2"

function BasicInfoMolecule({ t, profileData }) {

// console.log(`${profileData?.country_code} ${profileData?.phone_number}`,'profileData');
    const basicInfo = [
        {
            icon: icons.Phone,
            text: t("Profile.mobile"),
            data: `${profileData?.country_code} ${profileData?.phone_number}`,
            isNull: profileData?.phone_number === null
        },
        {
            icon: icons.Email,
            text: t("Profile.email"),
            data: profileData?.email
        },
        {
            icon: icons.Birth,
            text: t("Profile.birth"),
            data: profileData?.birthdate,
            isNull: profileData?.birthdate === null
        },
        {
            icon: icons.LocationIcon,
            text: t("Profile.location"),
            data: `${profileData?.area?.name},\u00A0${profileData?.city?.name},\u00A0${profileData?.country?.name}`,
            isNull: profileData?.country?.name === null || profileData?.country?.name === undefined
        },
        {
            icon: icons.Gender,
            text: t("Profile.gender"),
            data: profileData?.gender,
            isNull: profileData?.gender === null
        },
        {
            icon: icons.PrimaryLanguage,
            text: t("Profile.primary_language"),
            data: profileData?.languages?.[0]?.language?.name,
            isNull: profileData?.languages?.length === 0
        }
    ]

    const diveInfo = [
        {
            icon: icons.Phone,
            text: t("Profile.mobile"),
            data: `${profileData?.country_code} ${profileData?.phone_number}`,
            isNull: profileData?.phone_number === null
        },
        {
            icon: icons.Email,
            text: t("Profile.email"),
            data: profileData?.email
        },
        {
            icon: icons.Birth,
            text: t("Profile.contact"),
            data: profileData?.contact_person_name,
            isNull: profileData?.contact_person_name === null
        },
        {
            icon: icons.LocationIcon,
            text: t("Profile.location"),
            data: `${profileData?.area?.name},\u00A0${profileData?.city?.name},\u00A0${profileData?.country?.name}`,
            isNull: profileData?.country?.name === null || profileData?.country?.name === undefined
        },
        {
            icon: icons.Birth,
            text: t("Profile.foundation"),
            data: profileData?.birthdate,
            isNull: profileData?.birthdate === null
        },
        {
            icon: icons.PrimaryLanguage,
            text: t("Profile.primary_language"),
            data: profileData?.primary_language?.name,
            isNull: profileData?.primary_language?.name === null
        },
        {
            icon: icons.Website,
            text: t("Profile.website"),
            data: profileData?.website,
            isNull: profileData?.website === null
        }
    ]

    return (
        <Grid container sx={{
            border: 'solid 1px #ececec',
            borderRadius: '12px',
            padding: '18px'
        }} mb={3}>
            <Grid item xs={12} mb={3}>
                <Typography sx={{
                    fontSize: 'var(--normal-font)',
                    fontWeight: 'bold',
                    color: 'var(--font-color)'
                }}>
                    {t("Profile.info")}
                </Typography>
            </Grid>
            {profileData?.user_type === 'customer' && basicInfo.map((item, i) => {
                return <Grid container item xs={12} md={6} key={i} mb={i !== 5 && 5}>
                    <Grid container item width={'54px'} alignContent='center'>
                        <img src={item.icon} alt='' />
                    </Grid>
                    <Grid container item width={'calc(100% - 54px)'} alignContent='center'>
                        <Grid item xs={12}>
                            <Typography sx={{
                                fontSize: 'var(--font-font)',
                                fontWeight: '600',
                                color: '#aaa'
                            }}>
                                {item.text}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>                            
                        {
                            i === 0 && item.isNull ? <Typography sx={{
                                fontSize: 'var(--medium-font)',
                                fontWeight: 'bold',
                                color: item.isNull ? '#c21703' : 'var(--font-color)',
                                wordBreak: 'break-word'
                            }}>Not added</Typography>
                            : i === 0 && !item.isNull ? <PhoneInput
                                inputStyle={{
                                    border: "none",
                                    width: "100%",
                                    padding: "0px",
                                    direction: "ltr",
                                    fontSize: 'var(--medium-font)',
                                    fontWeight: 'bold',
                                    color: item.isNull ? '#c21703' : 'var(--font-color)',
                                    backgroundColor: 'white',
                                    wordBreak: 'break-word'
                                }}
                                // defaultMask={profileData?.country_code == '20' ? '... ... ....' : ''}
                                value={item.data}
                                disabled={true}
                                disableDropdown={true}
                                specialLabel=""
                                placeholder=""
                            /> : <Typography sx={{
                                fontSize: 'var(--medium-font)',
                                fontWeight: 'bold',
                                color: item.isNull ? '#c21703' : 'var(--font-color)',
                                wordBreak: 'break-word'
                            }}>{item.isNull ? 'Not added' : item.data}</Typography>
                        }                            
                        </Grid>
                    </Grid>
                </Grid>
            })}
            {profileData?.user_type === 'dive_club' && diveInfo.map((item, i) => {
                return <Grid container item xs={12} md={6} key={i} mb={i !== 6 && 4}>
                    <Grid container item width={'54px'} alignContent='center'>
                        <img src={item.icon} alt='' />
                    </Grid>
                    <Grid container item width={'calc(100% - 54px)'} alignContent='center'>
                        <Grid item xs={12}>
                            <Typography sx={{
                                fontSize: 'var(--font-font)',
                                fontWeight: '600',
                                color: '#aaa'
                            }}>
                                {item.text}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        {
                            i === 0 && item.isNull ? <Typography sx={{
                                fontSize: 'var(--medium-font)',
                                fontWeight: 'bold',
                                color: item.isNull ? '#c21703' : 'var(--font-color)',
                                wordBreak: 'break-word'
                            }}>Not added</Typography>
                            : i === 0 && !item.isNull ? <PhoneInput
                                inputStyle={{
                                    border: "none",
                                    width: "100%",
                                    padding: "0px",
                                    direction: "ltr",
                                    fontSize: 'var(--medium-font)',
                                    fontWeight: 'bold',
                                    color: item.isNull ? '#c21703' : 'var(--font-color)',
                                    backgroundColor: 'white',
                                    wordBreak: 'break-word'
                                }}
                                // defaultMask={profileData?.country_code == '20' ? '... ... ....' : ''}
                                value={item.data}
                                disabled={true}
                                disableDropdown={true}
                                specialLabel=""
                                placeholder=""
                            /> : <Typography sx={{
                                fontSize: 'var(--medium-font)',
                                fontWeight: 'bold',
                                color: item.isNull ? '#c21703' : 'var(--font-color)',
                                wordBreak: 'break-word'
                            }}>{item.isNull ? 'Not added' : item.data}</Typography>
                        }  
                        </Grid>
                    </Grid>
                </Grid>
            })}
        </Grid>
    )
}

export default BasicInfoMolecule