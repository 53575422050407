import React, { useRef } from "react";
import { Box } from "@mui/material";

function ImageInput({ 
    btnSX,
    icon,
    change,
}) {
    const fileInputRef = useRef(null);

    return (
        <label
            style={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#F4F4F4',
                width: "80px",
                height: "80px",
                border: "1px solid #BBBBBB",
                borderRadius: "10px",
                cursor: 'pointer',
                ...btnSX
            }}
        >
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={change}
            />
            <Box component="img" src={icon} sx={{ width: 24, height: 24 }} />
        </label>
    );
}

export default ImageInput;
